import { ColumnDef } from "@tanstack/react-table";
import { Link } from "react-router-dom";
import { Badge } from "components/ui/badge";
// import { Checkbox } from "components/ui/checkbox";
import { AspectRatio } from "components/ui/aspect-ratio";

import { DataTableColumnHeader } from "./data-table-column-header";
import { formatDateTime, toStartCase } from "utils/helper-methods";
import { Post } from "types";

export const columns: ColumnDef<Post>[] = [
  // {
  //   id: "select",
  //   header: ({ table }) => (
  //     <Checkbox
  //       checked={
  //         table.getIsAllPageRowsSelected() ||
  //         (table.getIsSomePageRowsSelected() && "indeterminate")
  //       }
  //       onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
  //       aria-label="Select all"
  //       className="translate-y-[2px]"
  //     />
  //   ),
  //   cell: ({ row }) => (
  //     <Checkbox
  //       checked={row.getIsSelected()}
  //       onCheckedChange={(value) => row.toggleSelected(!!value)}
  //       aria-label="Select row"
  //       className="translate-y-[2px]"
  //     />
  //   ),
  //   enableSorting: false,
  //   enableHiding: false,
  // },
  {
    accessorKey: "post_id",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="ID" />
    ),
    cell: ({ row }) => (
      <div className="w-[80px] text-xs sm:text-sm">
        {row.getValue("post_id")}
      </div>
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "image",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Image" />
    ),
    cell: ({ row }) => {
      return (
        <div className="flex space-x-2 max-w-20 text-xs sm:text-sm">
          <AspectRatio
            ratio={16 / 10}
            className="object-cover rounded-md bg-muted"
          >
            <img
              alt={row.original.article_title}
              src={row.original.article_image_url}
              className="w-full h-full rounded"
            />
          </AspectRatio>
        </div>
      );
    },
  },
  {
    accessorKey: "title",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Title" />
    ),
    cell: ({ row }) => {
      return (
        <div className="flex space-x-2 text-xs sm:text-sm">
          <Link
            className="max-w-[300px] truncate hover:underline cursor-pointer"
            to={`/posts/${row.original.article_id}`}
          >
            <span title={row.original.article_title} className="font-medium">
              {row.original.article_title}
            </span>
          </Link>
        </div>
      );
    },
    getUniqueValues: (row) => {
      return [row.article_title];
    },
    filterFn: (row, id, value) => {
      return row.original.article_title
        .toLowerCase()
        .includes(value.toLowerCase());
    },
    enableColumnFilter: true,
  },

  {
    accessorKey: "schedule_name",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Schedule" />
    ),
    cell: ({ row }) => {
      return (
        <div className="flex space-x-2 text-xs sm:text-sm">
          <span className="max-w-[150px] truncate font-medium">
            {row.getValue("schedule_name") || "-"}
          </span>
        </div>
      );
    },
    filterFn: (row, id, value) => {
      return (row.original.schedule_name || "")!
        .toLowerCase()
        .includes(value.toLowerCase());
    },
    enableColumnFilter: true,
  },
  {
    accessorKey: "date",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Date posted" />
    ),
    cell: ({ row }) => {
      return (
        <div className="flex space-x-2 text-xs sm:text-sm">
          <span className="text-sm truncate font-medium">
            {formatDateTime(
              new Date(row.original.when_posted_by_user * 1000),
              false
            )}
          </span>
        </div>
      );
    },
  },
  {
    accessorKey: "channel",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Channel" />
    ),
    cell: ({ row }) => {
      return (
        <div className="flex items-center text-xs sm:text-sm">
          <Badge variant="outline">
            {toStartCase(row.original.social_venue)}
          </Badge>
        </div>
      );
    },
    getUniqueValues: (row) => {
      return [row.social_venue.toLowerCase()];
    },
  },
  {
    accessorKey: "connection_name",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Connection name" />
    ),
    cell: ({ row }) => {
      return (
        <div className="flex items-center text-xs sm:text-sm">
          <span className="ml-2 max-w-[160px] truncate text-muted-foreground">
            {row.original.channel_name}
          </span>
        </div>
      );
    },
    filterFn: (row, id, value) => {
      return row.original.channel_name
        .toLowerCase()
        .includes(value.toLowerCase());
    },
  },
  {
    accessorKey: "post_type",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Type" />
    ),
    cell: ({ row }) => {
      return (
        <div className="flex items-center text-xs sm:text-sm">
          <span>{toStartCase(row.original.post_type)}</span>
        </div>
      );
    },
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
  },
];
