import { Account, Agency, Client, CampaignList, HintSubject, QuestionSubject, Group } from "types"
import { get, set } from "utils/storage"

export interface AdminState {
    client: Client | undefined
    agency: Agency | undefined
    account: Account | undefined
    campaign: CampaignList | undefined
    hint_subject: HintSubject | undefined
    question_subject: QuestionSubject | undefined
    group: Group | undefined
    account_token: string
    refresh_accounts: boolean


}

let existingState = get("ADMIN_STATE")

existingState = existingState ? JSON.parse(existingState) : null


export const initialAdminState: AdminState = existingState ?? {
    client: undefined,
    agency: undefined,
    account: undefined,
    campaign: undefined,
    question_subject: undefined,
    hint_subject: undefined,
    refresh_accounts: false,
    group: undefined,
    account_token: "",

}

export type AdminAction =
    | { type: "SET_ADMIN_CLIENT", payload: Client | undefined }
    | { type: "SET_ADMIN_AGENCY", payload: Agency | undefined }
    | { type: "SET_ADMIN_ACCOUNT", payload: Account | undefined }
    | { type: "SET_ADMIN_CAMPAIGN", payload: CampaignList | undefined }
    | { type: "SET_ADMIN_HINT_SUBJECT", payload: HintSubject | undefined }
    | { type: "SET_ADMIN_QUESTION_SUBJECT", payload: QuestionSubject | undefined }
    | { type: "SET_ADMIN_GROUP", payload: Group | undefined }
    | { type: "SET_ADMIN_ACCOUNT_TOKEN", payload: string }
    | { type: "SET_ADMIN_ACCOUNT_REFRESH", payload: boolean }



const adminReducer = (state: AdminState, action: AdminAction): AdminState => {
    let newState = state;
    switch (action.type) {
        case 'SET_ADMIN_CLIENT':
            newState = { ...newState, client: action.payload };
            set("ADMIN_STATE", JSON.stringify(newState))
            return newState
        case 'SET_ADMIN_AGENCY':
            newState = { ...newState, agency: action.payload };
            set("ADMIN_STATE", JSON.stringify(newState))
            return newState
        case 'SET_ADMIN_ACCOUNT':

            if (!action.payload) {
                newState = { ...newState, account: action.payload, account_token: "" };
            } else {
                newState = { ...newState, account: action.payload };
            }

            set("ADMIN_STATE", JSON.stringify(newState))
            return newState

        case 'SET_ADMIN_CAMPAIGN':
            newState = { ...newState, campaign: action.payload };
            set("ADMIN_STATE", JSON.stringify(newState))
            return newState
        case 'SET_ADMIN_HINT_SUBJECT':
            newState = { ...newState, hint_subject: action.payload };
            set("ADMIN_STATE", JSON.stringify(newState))
            return newState
        case 'SET_ADMIN_QUESTION_SUBJECT':
            newState = { ...newState, question_subject: action.payload };
            set("ADMIN_STATE", JSON.stringify(newState))
            return newState

        case 'SET_ADMIN_GROUP':
            newState = { ...newState, group: action.payload };
            set("ADMIN_STATE", JSON.stringify(newState))
            return newState
        case 'SET_ADMIN_ACCOUNT_TOKEN':
            newState = { ...newState, account_token: action.payload };
            set("ADMIN_STATE", JSON.stringify(newState))
            return newState

        case 'SET_ADMIN_ACCOUNT_REFRESH':
            newState = { ...newState, refresh_accounts: action.payload };
            set("ADMIN_STATE", JSON.stringify(newState))
            return newState

        default:
            return newState;
    }

}

export default adminReducer