import { THEME_MODE } from "constants/constants"
import { get, set } from "utils/storage"

const themeMode = get(THEME_MODE);

export interface SettingsState {
  isDarkMode: boolean,
  appLogoUrl: string
}


export const initialSettingsState: SettingsState = {
  isDarkMode: themeMode === 'dark' ?? false,
  appLogoUrl: ""
}

export type SettingsAction =
  { type: "SET_DARK_MODE", payload: boolean }
  | { type: "SET_LOGO_URL", payload: string }



const settingsReducer = (state: SettingsState, action: SettingsAction): SettingsState => {
  switch (action.type) {
    case "SET_DARK_MODE":
      set(THEME_MODE, action.payload ? "dark" : "light")
      return { ...state, isDarkMode: action.payload }
    case "SET_LOGO_URL":
      return { ...state, appLogoUrl: action.payload }
    default:
      return state
  }

}

export default settingsReducer