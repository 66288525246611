import * as React from "react";
import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "components/ui/table";
import { ScrollArea, ScrollBar } from "components/ui/scroll-area";

import FacebookPagesDialog from "components/Dialogs/FacebookPagesDialog";
import { DataTablePagination } from "../Shared/data-table-pagination";
import { DataTableToolbar } from "./data-table-toolbar";
import { Connection } from "types";

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  loading: boolean;
}

export function DataTable<TData, TValue>({
  columns,
  data,
  loading,
}: DataTableProps<TData, TValue>) {
  const [rowSelection, setRowSelection] = React.useState({});
  const [columnVisibility, setColumnVisibility] =
    React.useState<VisibilityState>({});
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [connectionData, setConnectionData] = React.useState<Connection | null>(
    null
  );

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      columnVisibility,
      rowSelection,
      columnFilters,
    },
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
  });

  return (
    <div className="space-y-4">
      <DataTableToolbar table={table} />
      <div className="rounded-md border">
        <ScrollArea className="h-[65vh]">
          <Table>
            <TableHeader>
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <TableHead key={header.id} colSpan={header.colSpan}>
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                      </TableHead>
                    );
                  })}
                </TableRow>
              ))}
            </TableHeader>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell
                    colSpan={columns.length}
                    className="h-[60vh] text-center"
                  >
                    <div className="min-h-60 flex items-center justify-center">
                      <div className="flex flex-col justify-center items-center">
                        <div className="size-8 border-2 border-t-2 border-muted border-t-primary rounded-full animate-spin"></div>
                        <span className="text-sm text-center mt-4 text-primary">
                          Loading connections...
                        </span>
                      </div>
                    </div>
                  </TableCell>
                </TableRow>
              ) : table.getRowModel().rows?.length > 0 ? (
                table.getRowModel().rows.map((row) => (
                  <TableRow
                    key={row.id}
                    data-state={row.getIsSelected() && "selected"}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <TableCell
                        key={cell.id}
                        onClick={() => {
                          const connection: Connection | any = row.original;
                          if (
                            cell.column.id === "pages" &&
                            connection?.channel === "facebook"
                          ) {
                            setConnectionData(connection);
                            setOpenDialog(true);
                          }
                        }}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={columns.length}
                    className="h-[60vh] text-center"
                  >
                    No results.
                  </TableCell>
                </TableRow>
              )}
              {/* {table.getRowModel().rows?.length ? (
                table.getRowModel().rows.map((row) => (
                  <TableRow
                    key={row.id}
                    data-state={row.getIsSelected() && "selected"}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <TableCell
                        key={cell.id}
                        onClick={() => {
                          const connection: Connection | any = row.original;
                          if (
                            cell.column.id === "pages" &&
                            connection?.channel === "facebook"
                          ) {
                            setConnectionData(connection);
                            setOpenDialog(true);
                          }
                        }}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={columns.length}
                    className="h-24 text-center"
                  >
                    {loading ? (
                      <div className="min-h-60 flex items-center justify-center">
                        <div className="flex flex-col justify-center items-center">
                          <div className="size-8 border-2 border-t-2 border-muted border-t-primary rounded-full animate-spin"></div>
                          <span className="text-sm text-center mt-4 text-primary">
                            Loading connections...
                          </span>
                        </div>
                      </div>
                    ) : (
                      data.length === 0 && "No results."
                    )}
                  </TableCell>
                </TableRow>
              )} */}
            </TableBody>
          </Table>
          <ScrollBar orientation="horizontal" />
        </ScrollArea>
      </div>
      <DataTablePagination table={table} />

      <FacebookPagesDialog
        edit={true}
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        connectionData={connectionData}
      />
    </div>
  );
}
