import { useEffect, useState } from "react";
import { RefreshCw } from "lucide-react";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "components/ui/dialog";
import { Label } from "components/ui/label";
import { Button } from "components/ui/button";
import { Input } from "components/ui/input";
import { useAppState } from "context/AppContext";
import CustomSelect from "components/Shared/CustomSelect";
import { ManualNewsType, Target, Poster, Actor, AccountCampaign } from "types";
import apiClient from "api";

type Props = {
  open: boolean;
  editData?: ManualNewsType | null;
  fetchData: () => void;
  onOpenChange: (value: boolean) => void;
};

const AddManualNewsDialog = ({
  open,
  editData,
  fetchData,
  onOpenChange,
}: Props) => {
  const { state } = useAppState();
  const { account } = state.admin;
  const [targetTypes, setTargetTypes] = useState<Target[]>([]);
  const [targetType, setTargetType] = useState<Target | null>(null);
  const [posterTypes, setPosterTypes] = useState<Poster[]>([]);
  const [posterType, setPosterType] = useState<Poster | null>(null);
  const [actors, setActors] = useState<Actor[]>([]);
  const [posterList, setPosterList] = useState<Actor[]>([]);
  const [targetList, setTargetList] = useState<Actor[]>([]);
  const [campaigns, setCampaigns] = useState<AccountCampaign[]>([]);
  const [poster, setPoster] = useState<Actor | null>(null);
  const [target, setTarget] = useState<Actor | null>(null);
  const [submitting, setSubmitting] = useState(false);
  const [hoursBetween, setHoursBetween] = useState(0);
  const [selectedCampaigns, setSelectedCampaigns] = useState<AccountCampaign[]>(
    []
  );

  useEffect(() => {
    if (!editData) return;
    setHoursBetween(editData.hours_between);
    setTargetType(
      targetTypes.find((c) => c.target_id === editData.page_target_id) || null
    );
    setPosterType(
      posterTypes.find((p) => p.content_type === editData.poster_page) || null
    );

    // eslint-disable-next-line
  }, [editData, posterTypes, targetTypes, actors]);

  useEffect(() => {
    if (!editData) return;
    const targets = actors.filter(
      (item) =>
        item.actor_type === targetType?.target_id &&
        item.creative_id === account?.account_id
    );
    setTargetList(targets);
    const posters = actors.filter(
      (item) =>
        item.actor_type === posterType?.content_type &&
        item.creative_id === account?.account_id
    );
    setPosterList(posters);
    // eslint-disable-next-line
  }, [posterType, targetType]);

  useEffect(() => {
    if (!editData) return;
    setTarget(
      targetList.find(
        (a) =>
          a.actor_type === editData.page_target_id &&
          a.creative_id === account?.account_id
      ) || null
    );
    setPoster(
      posterList.find(
        (a) =>
          a.actor_type === editData.poster_page &&
          a.creative_id === account?.account_id
      ) || null
    );
    // eslint-disable-next-line
  }, [posterList, targetList]);

  useEffect(() => {
    if (!open) {
      setHoursBetween(0);
      setTargetType(null);
      setTarget(null);
      setPoster(null);
      setPosterType(null);
      return;
    }

    fetchPosterTypes();
    fetchTargetTypes();
    getActorsList();
    getCampaigns();

    // eslint-disable-next-line
  }, [open]);

  const getCampaigns = async () => {
    try {
      const { data } = await apiClient.post(
        "/admin_creative_attach_newsguru",
        {},
        {
          headers: {
            request: JSON.stringify({
              creative_account_id: account?.account_id,
              newsguru_email: account?.newsguru_email,
            }),
          },
        }
      );
      const campaignsData: AccountCampaign[] = data?.message || [];
      setCampaigns(campaignsData);
    } catch (error) {
      console.log("error:", error);
    } finally {
    }
  };
  const getActorsList = async () => {
    try {
      const { data } = await apiClient.get("/admin_creative_get_actors");
      const list: Actor[] = data?.message || [];
      setActors(list);
    } catch (error) {
      console.log("error:", error);
    } finally {
    }
  };

  const fetchTargetTypes = async () => {
    try {
      const { data } = await apiClient.get("/admin_creative_get_target_types");
      const content: Target[] = data?.message || [];
      setTargetTypes(content);
    } catch (error) {
      console.error("Error:", error);
    } finally {
    }
  };

  const fetchPosterTypes = async () => {
    try {
      const { data } = await apiClient.get("/admin_creative_get_poster_types");
      const content: Poster[] = data?.message || [];
      setPosterTypes(content);
    } catch (error) {
      console.error("Error:", error);
    } finally {
    }
  };

  const addManualNews = async (evt: React.FormEvent) => {
    evt.preventDefault();
    setSubmitting(true);
    if (Boolean(editData)) {
      // try {
      //   await apiClient.post(
      //     "/admin_creative_update_manual_news",
      //     {},
      //     {
      //       headers: {
      //         request: JSON.stringify({
      //           manual_news_id: editData?.manual_news_id,
      //           poster_type: posterType?.content_type,
      //           poster_id: poster?.actor_id || 0,
      //           target_type: targetType?.target_id,
      //           target_id: target?.actor_id || 0,
      //           hours_between: hoursBetween,
      //         }),
      //       },
      //     }
      //   );
      //   onOpenChange(false);
      //   fetchData();
      // } catch (error) {
      //   console.log("Error:", error);
      // } finally {
      //   setSubmitting(false);
      // }
    } else {
      if (selectedCampaigns.length === 0) return;

      try {
        for (const campaign of selectedCampaigns) {
          await apiClient.post(
            "/admin_creative_add_manual_news",
            {},
            {
              headers: {
                request: JSON.stringify({
                  creative_id: account?.account_id,
                  poster_id: poster?.actor_id,
                  poster_type: poster?.actor_type,
                  target_type: targetType?.target_id,
                  target_id: target?.actor_id,
                  hours_between: hoursBetween,
                  campaign_id: campaign?.campaign_id,
                }),
              },
            }
          );
        }
        onOpenChange(false);
        fetchData();
      } catch (error) {
        console.log("Error:", error);
      } finally {
        setSubmitting(false);
      }
    }
  };

  return (
    <>
      <Dialog open={open} onOpenChange={onOpenChange}>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>{!!editData ? "Edit" : "Add"} manual news</DialogTitle>
          </DialogHeader>

          <form onSubmit={addManualNews} className="w-full grid gap-4">
            <div className="grid gap-2 w-full">
              <Label htmlFor="campaign">Campaign</Label>
              <CustomSelect
                multiple={true}
                value={
                  selectedCampaigns.length
                    ? selectedCampaigns.map((c) => String(c.campaign_id))
                    : []
                }
                onSelect={(value) => {
                  if (value.length === 0) {
                    setSelectedCampaigns([]);
                    return;
                  }
                  const _campaigns = campaigns.filter((c) =>
                    value.includes(String(c.campaign_id))
                  );
                  setSelectedCampaigns(_campaigns);
                }}
                data={campaigns.map((c) => ({
                  value: String(c.campaign_id),
                  label: c.campaign_name,
                }))}
                label="campaign"
              />
            </div>

            <div className="grid gap-2 w-full">
              <Label htmlFor="poster-type">Poster type</Label>
              <CustomSelect
                value={posterType ? String(posterType?.content_type) : ""}
                onSelect={(value) => {
                  if (value === "") {
                    setPosterType(null);
                    setPoster(null);
                    setPosterList([]);
                    return;
                  }
                  const selectedPoster = posterTypes.filter(
                    (p) => p.content_type === parseInt(String(value))
                  );
                  setPosterType(selectedPoster[0] || null);
                  setPoster(null);
                  const posters = actors.filter(
                    (item) =>
                      item.actor_type === selectedPoster[0]?.content_type &&
                      item.creative_id === account?.account_id
                  );
                  setPosterList(posters);
                }}
                data={posterTypes.map((t) => ({
                  value: String(t.content_type),
                  label: t.description,
                }))}
                label="poster type"
              />
            </div>

            <div className="grid gap-2 w-full">
              <Label htmlFor="poster">Poster</Label>
              <CustomSelect
                disabled={!Boolean(posterType)}
                value={poster ? String(poster?.actor_id) : ""}
                onSelect={(value) => {
                  if (value === "") {
                    setPoster(null);
                    return;
                  }
                  const _poster = posterList.filter(
                    (d) => d.actor_id === parseInt(String(value))
                  );
                  setPoster(_poster[0] || null);
                }}
                data={posterList.map((t) => ({
                  value: String(t.actor_id),
                  label: t.identified_name,
                }))}
                label="poster"
              />
            </div>

            <div className="grid gap-2 w-full">
              <Label htmlFor="target-type">Target type</Label>
              <CustomSelect
                value={targetType ? String(targetType?.target_id) : ""}
                onSelect={(value) => {
                  if (value === "") {
                    setTargetType(null);
                    setTarget(null);
                    setTargetList([]);
                    return;
                  }
                  const target = targetTypes.filter(
                    (t) => t.target_id === parseInt(String(value))
                  );
                  setTargetType(target[0] || null);
                  setTarget(null);

                  const posters = actors.filter(
                    (item) =>
                      item.actor_type === target[0]?.target_id &&
                      item.creative_id === account?.account_id
                  );
                  setTargetList(posters);
                }}
                data={targetTypes.map((t) => ({
                  value: String(t.target_id),
                  label: t.description,
                }))}
                label="target type"
              />
            </div>

            <div className="grid gap-2 w-full">
              <Label htmlFor="target">Target</Label>
              <CustomSelect
                disabled={!Boolean(targetType)}
                value={target ? String(target?.actor_id) : ""}
                onSelect={(value) => {
                  if (value === "") {
                    setTarget(null);
                    return;
                  }
                  const _target = targetList.filter(
                    (d) => d.actor_id === parseInt(String(value))
                  );
                  setTarget(_target[0] || null);
                }}
                data={targetList.map((t) => ({
                  value: String(t.actor_id),
                  label: t.identified_name,
                }))}
                label="target"
              />
            </div>

            <div className="grid gap-2">
              <Label htmlFor="hours">Hours between</Label>
              <Input
                required
                type="number"
                id="hours"
                value={hoursBetween || ""}
                onChange={(evt) => {
                  const val = evt.target.value;
                  if (parseInt(val) < 0) return;
                  setHoursBetween(parseInt(val));
                }}
              />
            </div>

            <DialogFooter>
              <DialogClose asChild>
                <Button
                  disabled={submitting}
                  type="button"
                  size="sm"
                  variant="outline"
                >
                  Cancel
                </Button>
              </DialogClose>
              <Button disabled={submitting} type="submit" size="sm">
                {submitting && (
                  <RefreshCw className="size-4 animate-spin mr-2" />
                )}{" "}
                Save
              </Button>
            </DialogFooter>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddManualNewsDialog;
