import axios, { AxiosInstance, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { TOKEN_KEY } from 'constants/constants';

// Create an instance of axios
const apiClient: AxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_DEMO_KEY || process.env.REACT_APP_API_KEY, // Replace with your API base URL
});

// Add a request interceptor to set the Authorization header if a token is available
apiClient.interceptors.request.use(
    (config: InternalAxiosRequestConfig) => {
        const token = localStorage.getItem(TOKEN_KEY); // Or use any other method to get the token

        if (config.headers) {

            if (!config.headers.token) {
                config.headers.token = token && JSON.parse(token)
            }

            config.headers['Content-Type'] = "application/json; charset=utf-8"

        }
        return config;
    },
    (error) => {
        if (error?.response?.status === 401) {
            window.location.href = '/login'

        }
        return Promise.reject(error);
    }
);

// Optionally, you can also add a response interceptor
apiClient.interceptors.response.use(
    (response: AxiosResponse) => response,
    (error) => {
        if (error?.response?.status === 401) {
            window.location.href = '/login'

        }
        return Promise.reject(error);
    }
);

export default apiClient;
