import { useEffect, useState } from "react";
import { CheckIcon } from "lucide-react";
import { CaretSortIcon } from "@radix-ui/react-icons";
import { Button } from "components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "components/ui/command";
import { Popover, PopoverContent, PopoverTrigger } from "components/ui/popover";
import { cn } from "lib/utils";

type SelectProps = {
  label?: string;
  loading?: boolean;
  disabled?: boolean;
  multiple?: boolean;
  showInput?: boolean;
  value: string | string[];
  customTrigger?: React.ReactNode;
  onSelect: (val: string | string[]) => void;
  data: { value: string; label: string }[];
};

const CustomSelect = ({
  data,
  label,
  value,
  loading,
  disabled,
  showInput,
  customTrigger,
  multiple = false,
  onSelect,
}: SelectProps) => {
  const [open, setOpen] = useState(false);
  const [selectedValues, setSelectedValues] = useState<string[]>([]);

  useEffect(() => {
    if (multiple && Array.isArray(value)) {
      setSelectedValues(value);
    }
    // eslint-disable-next-line
  }, [value]);

  const handleSelect = (currentValue: string) => {
    if (multiple) {
      let updatedValues;
      if (selectedValues.includes(currentValue)) {
        updatedValues = selectedValues.filter((val) => val !== currentValue);
      } else {
        updatedValues = [...selectedValues, currentValue];
      }
      setSelectedValues(updatedValues);
      onSelect(updatedValues);
    } else {
      onSelect(currentValue === value ? "" : currentValue);
      setOpen(false);
    }
  };

  const handleSelectAll = () => {
    let updatedValues: string[];
    if (selectedValues.length === data.length) {
      updatedValues = [];
    } else {
      updatedValues = data.map((n) => n.value);
    }
    setSelectedValues(updatedValues);
    onSelect(updatedValues);
  };

  return (
    <Popover modal={true} open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        {customTrigger ? (
          customTrigger
        ) : (
          <Button
            disabled={Boolean(disabled)}
            variant="outline"
            role="combobox"
            aria-expanded={open}
            className="w-full justify-between"
          >
            {loading ? (
              "Loading..."
            ) : multiple ? (
              <span className="max-w-[300px] truncate">
                {selectedValues.length > 0
                  ? selectedValues
                      .map((val) => data.find((d) => d.value === val)?.label)
                      .join(", ")
                  : `Select ${label}...`}
              </span>
            ) : value ? (
              <span className="max-w-[300px] truncate">
                {data.find((d) => d.value === value)?.label}
              </span>
            ) : (
              `Select ${label}...`
            )}
            <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
          </Button>
        )}
      </PopoverTrigger>
      <PopoverContent
        className={`${customTrigger ? "w-[200px]" : "w-[380px]"} p-0`}
      >
        <Command>
          {showInput && (
            <CommandInput placeholder={`Select ${label}...`} className="h-9" />
          )}
          <CommandList>
            <CommandEmpty>No data.</CommandEmpty>
            <CommandGroup>
              {multiple && (
                <CommandItem onSelect={() => handleSelectAll()}>
                  Select All
                </CommandItem>
              )}
              {data.map((d) => (
                <CommandItem
                  key={d.value}
                  value={d.value}
                  onSelect={() => handleSelect(d.value)}
                >
                  {d.label}
                  <CheckIcon
                    className={cn(
                      "ml-auto h-4 w-4",
                      multiple
                        ? selectedValues.includes(d.value)
                          ? "opacity-100"
                          : "opacity-0"
                        : value === d.value
                        ? "opacity-100"
                        : "opacity-0"
                    )}
                  />
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
};
export default CustomSelect;
