import { Cross2Icon } from "@radix-ui/react-icons";
import { Table } from "@tanstack/react-table";

import { Button } from "components/ui/button";
import { Input } from "components/ui/input";
import { DataTableViewOptions } from "./data-table-view-options";

import { statuses, channel } from "./data";
import { DataTableFacetedFilter } from "./data-table-faceted-filter";

interface DataTableToolbarProps<TData> {
  table: Table<TData>;
}

export function DataTableToolbar<TData>({
  table,
}: DataTableToolbarProps<TData>) {
  const isFiltered = table.getState().columnFilters.length > 0;

  return (
    <div className="flex items-center justify-between">
      <div className="flex flex-col w-full sm:flex-row sm:flex-1 sm:items-center space-y-2 sm:space-y-0 sm:space-x-2">
        <Input
          placeholder="Filter connections..."
          value={
            (table.getColumn("connection_name")?.getFilterValue() as string) ??
            ""
          }
          onChange={(event) =>
            table
              .getColumn("connection_name")
              ?.setFilterValue(event.target.value)
          }
          className="h-8 w-full sm:w-[250px]"
        />

        <div className="flex space-x-2">
          <DataTableFacetedFilter
            column={table.getColumn("channel")}
            title="Channel"
            options={channel}
          />

          <DataTableFacetedFilter
            column={table.getColumn("status")}
            title="Status"
            options={statuses}
          />

          {isFiltered && (
            <Button
              variant="ghost"
              onClick={() => table.resetColumnFilters()}
              className="h-8 sm:px-2 lg:px-3"
            >
              Reset
              <Cross2Icon className="ml-2 h-4 w-4" />
            </Button>
          )}
        </div>
      </div>
      <DataTableViewOptions table={table} />
    </div>
  );
}
