import { Fragment, useEffect, useRef, useState } from "react";
import { Check, ChevronRight, Newspaper } from "lucide-react";
import { buttonVariants } from "components/ui/button";
import { ScrollArea } from "components/ui/scroll-area";
import {
  Select,
  SelectTrigger,
  SelectContent,
  SelectGroup,
  SelectLabel,
  SelectValue,
  SelectItem,
} from "components/ui/select";
import { Separator } from "components/ui/separator";

import { Channel, ScheduleSet } from "types";
import { cn } from "lib/utils";
import apiClient from "api";
import { useAppState } from "context/AppContext";

interface ChannelsProps {
  reset?: boolean;
  channels?: any;
  selectedData: ScheduleSet;
  setSelectedData: React.Dispatch<React.SetStateAction<ScheduleSet>>;
}

type GroupType = { channelName: string; list: Channel[] };

const channelsGroup: any = {
  all: "All channels",
  // client_channels: "Client channels",
  private_channels: "Private channels",
  public_channels: "Public channels",
};

export const Channels: React.FC<ChannelsProps> = ({
  reset,
  selectedData,
  setSelectedData,
}) => {
  const { state } = useAppState();
  const { account_token } = state.user;

  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState<Channel | null>(null);
  const [selectedGroup, setSelectedGroup] = useState<string>("all");

  // const [clientChannels, setClientChannels] = useState<Channel[]>([]);
  const [currentList, setCurrentList] = useState<Channel[]>([]);
  const [allChannels, setAllChannels] = useState<GroupType[]>([]);
  const [privateChannels, setPrivateChannels] = useState<Channel[]>([]);
  const [publicChannels, setPublicChannels] = useState<Channel[]>([]);

  useEffect(() => {
    if (selectedData?.selectedChannel && !selected) {
      setSelected(selectedData.selectedChannel);
    }
    // eslint-disable-next-line
  }, [selectedData?.selectedChannel]);

  useEffect(() => {
    if (reset) {
      setSelected(null);
    }
  }, [reset]);

  useEffect(() => {
    if (!account_token) return;
    const timeout = setTimeout(fetchChannels, 200);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line
  }, [account_token]);

  const fetchChannels = async () => {
    setLoading(true);
    try {
      const {
        data: { message },
      }: {
        data: {
          message: { private_news: Channel[]; public_news: Channel[] };
        };
      } = await apiClient.get("/creative_sources", {
        headers: {
          token: account_token,
        },
      });
      setAllChannels([
        { channelName: "private_channels", list: message.private_news },
        { channelName: "public_channels", list: message.public_news },
      ]);
      setPublicChannels(message.public_news);
      setPrivateChannels(message.private_news);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("error", error);
    }
  };

  const onSelect = (channel: Channel) => {
    let newSelected: Channel | null = null;
    if (channel?.channel_id) {
      newSelected = selected
        ? selected.channel_id === channel.channel_id
          ? null
          : channel
        : channel;
    } else {
      newSelected = selected
        ? selected.campaign_id === channel.campaign_id
          ? null
          : channel
        : channel;
    }

    setSelected(newSelected);
    setSelectedData((ps) => ({ ...ps, selectedChannel: newSelected }));
  };

  return (
    <Fragment>
      <div
        className={cn(
          "flex h-[52px] items-center px-4 transition-all top-0 sticky"
        )}
      >
        <Newspaper size={18} className={cn("text-primary me-2")} />
        <h1 className="inline-flex items-center font-bold">Channels</h1>
      </div>
      <Separator />
      <div className={cn("flex h-[52px] items-center justify-center px-2")}>
        <Select
          value={selectedGroup}
          onValueChange={(value) => {
            // if (value === "client_channels") setCurrentList(clientChannels);
            if (value === "private_channels") setCurrentList(privateChannels);
            if (value === "public_channels") setCurrentList(publicChannels);
            setSelectedGroup(value);
          }}
        >
          <SelectTrigger className="w-full">
            <SelectValue placeholder="Select a channel" />
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              <SelectLabel>Channels</SelectLabel>
              {Object.keys(channelsGroup).map((k: string) => (
                <SelectItem key={k} value={k}>
                  {channelsGroup[k]}
                </SelectItem>
              ))}
            </SelectGroup>
          </SelectContent>
        </Select>
      </div>
      <ScrollArea className="h-[calc(100vh-212px)] lg:h-[calc(100vh-164px)] p-2">
        <div className="group flex flex-col gap-4">
          <nav className="grid gap-1 w-full">
            {loading ? (
              <div className="flex items-center justify-center">
                <div className="flex flex-col justify-center items-center">
                  <div className="size-8 border-2 border-t-2 border-muted border-t-primary rounded-full animate-spin"></div>
                  <span className="text-sm text-center mt-4 text-primary">
                    Please wait, Loading...
                  </span>
                </div>
              </div>
            ) : selectedGroup === "all" ? (
              allChannels.map((group) => (
                <GroupList
                  group={group}
                  selected={selected}
                  onSelect={onSelect}
                  key={group.channelName}
                />
              ))
            ) : currentList.length > 0 ? (
              currentList.map((channel: Channel, index: number) => (
                <List
                  key={index}
                  item={channel}
                  selected={selected}
                  onSelect={onSelect}
                  selectParent={channel.selectable === 1}
                />
              ))
            ) : (
              <div className="flex text-sm justify-center items-center">
                No campaigns
              </div>
            )}
          </nav>
        </div>
      </ScrollArea>
    </Fragment>
  );
};

type GroupListProp = {
  group: GroupType;
  selected: Channel | null;
  onSelect: (item: Channel) => void;
};

const GroupList = ({ group, selected, onSelect }: GroupListProp) => {
  const [expand, setExpand] = useState(true);
  const contentRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <button
        onClick={() => setExpand(!expand)}
        className={cn(
          buttonVariants({
            variant: expand ? "secondary" : "outline",
            size: "sm",
          }),
          "justify-start h-10"
        )}
      >
        <div className="truncate">{channelsGroup[group.channelName]}</div>

        <span
          className={cn(
            "ml-auto",
            "text-primary transition-all",
            expand && "rotate-90"
          )}
        >
          <ChevronRight size={18} />
        </span>
      </button>

      {/* <Separator /> */}

      <div
        className={cn(
          "flex flex-col overflow-hidden",
          `transition-all duration-500 ease-in-out ${
            expand
              ? `max-h-auto opacity-100 visible`
              : "max-h-0 opacity-0 invisible"
          }`
        )}
        ref={contentRef}
      >
        {group.list.length === 0 && group.channelName === "private_channels" ? (
          <span className="text-sm text-center py-2 text-primary">
            To setup your own bespoke news categories and channels to post other
            social media network please setup a{" "}
            <a
              href="https://app.newsguru.ai"
              target="_blank"
              rel="noreferrer"
              className="hover:underline text-blue-500"
            >
              NewsGuru AI account.
            </a>
          </span>
        ) : (
          group.list.map((ch, index) => (
            <List
              selectParent={group.channelName !== "public_channels"}
              selected={selected}
              item={ch}
              key={index}
              onSelect={onSelect}
            />
          ))
        )}
      </div>
    </>
  );
};

type ListProps = {
  item: Channel;
  selectParent?: boolean;
  selected: Channel | null;
  onSelect: (item: Channel) => void;
};

const List = ({ item, selectParent, selected, onSelect }: ListProps) => {
  const [expand, setExpand] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  return selectParent ? (
    <ListItem
      selectParent={true}
      selected={selected}
      item={item}
      onSelect={onSelect}
    />
  ) : (
    <>
      <button
        onClick={() => setExpand(!expand)}
        className={cn(
          buttonVariants({
            variant:
              selected?.channel_id === item?.channel_id ? "default" : "ghost",
            size: "sm",
          }),
          "justify-start h-10"
        )}
      >
        <div className="truncate">{item?.channel_name}</div>
        {selected && selected.channel_id === item?.channel_id && (
          <span
            className={cn(
              "ml-auto",
              "text-background dark:default transition-all"
            )}
          >
            <Check size={18} />
          </span>
        )}
        {item.selectable === 0 && (
          <span
            className={cn(
              "ml-auto",
              "text-primary transition-all",
              expand && "rotate-90"
            )}
          >
            <ChevronRight size={18} />
          </span>
        )}
      </button>

      <div
        className={cn(
          "flex flex-col gap-1 overflow-hidden",
          `transition-all duration-500 ease-in-out ${
            expand
              ? `max-h-screen opacity-100 visible`
              : "max-h-0 opacity-0 invisible"
          }`
        )}
        ref={contentRef}
      >
        {item?.selectable === 0 &&
          item.children.map((ch, index) => (
            <ListItem
              selected={selected}
              item={ch}
              key={index}
              onSelect={onSelect}
            />
          ))}
      </div>
    </>
  );
};

const ListItem = ({ item, selected, selectParent, onSelect }: ListProps) => {
  const isSelected = selectParent
    ? selected?.campaign_id === item.campaign_id
    : selected?.channel_id === item?.channel_id;

  return (
    <button
      onClick={() => onSelect(item!!)}
      className={cn(
        buttonVariants({
          variant: isSelected ? "default" : "ghost",
          size: "sm",
        }),
        "justify-start transition-all h-10",
        !selectParent && " ps-5"
      )}
    >
      <div className="truncate">{item?.channel_name}</div>

      {selected && selected.channel_id === item?.channel_id && (
        <span
          className={cn(
            "ml-auto",
            "text-background dark:default transition-all"
          )}
        >
          <Check size={18} />
        </span>
      )}
    </button>
  );
};
