export const frequency = [
  {
    value: "daily",
    label: "Daily",
  },
  {
    value: "weekly",
    label: "Weekly",
  },
  {
    value: "monthly",
    label: "Monthly",
  },
  {
    value: "one_time",
    label: "One Time",
  },
];

export const channel = [
  {
    label: "LinkedIn",
    value: "linkedin",
  },
  {
    label: "Facebook",
    value: "facebook",
  },
  {
    label: "X (Twitter)",
    value: "twitter",
  },
];
