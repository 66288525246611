import { ColumnDef } from "@tanstack/react-table";

import { Badge } from "components/ui/badge";
// import { Checkbox } from "components/ui/checkbox";

import { DataTableColumnHeader } from "./data-table-column-header";
import { DataTableRowActions } from "./data-table-row-actions";
import { toStartCase } from "utils/helper-methods";
import { Connection } from "types";

export const columns: ColumnDef<Connection>[] = [
  // {
  //   id: "select",
  //   header: ({ table }) => (
  //     <Checkbox
  //       checked={
  //         table.getIsAllPageRowsSelected() ||
  //         (table.getIsSomePageRowsSelected() && "indeterminate")
  //       }
  //       onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
  //       aria-label="Select all"
  //       className="translate-y-[2px]"
  //     />
  //   ),
  //   cell: ({ row }) => (
  //     <Checkbox
  //       checked={row.getIsSelected()}
  //       onCheckedChange={(value) => row.toggleSelected(!!value)}
  //       aria-label="Select row"
  //       className="translate-y-[2px]"
  //     />
  //   ),
  //   enableSorting: false,
  //   enableHiding: false,
  // },
  {
    accessorKey: "id",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="ID" />
    ),
    cell: ({ row }) => (
      <div className="w-[80px] text-xs sm:text-sm">{row.getValue("id")}</div>
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "connection_name",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Connection name" />
    ),
    cell: ({ row }) => {
      return (
        <div className="flex space-x-2 text-xs sm:text-sm">
          <span className="max-w-[300px] truncate font-medium">
            {!row.original?.connection_name
              ? row.original.connection_status === 0
                ? "Incomplete connection"
                : "Unnamed connection"
              : row.original.connection_name}
          </span>
        </div>
      );
    },
  },
  {
    accessorKey: "channel",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Channel" />
    ),
    cell: ({ row }) => {
      return (
        <div className="flex items-center text-xs sm:text-sm">
          <Badge variant="outline">{toStartCase(row.original.channel)}</Badge>
        </div>
      );
    },
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
    getUniqueValues: (row) => {
      return [row.channel.toLowerCase()];
    },
  },
  {
    accessorKey: "status",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Status" />
    ),
    cell: ({ row }) => {
      return (
        <div className="flex items-center text-xs sm:text-sm">
          <span
            className={`text-xs px-2 py-0.5 rounded
                ${
                  row.original.connection_status === 1
                    ? "text-green-900 bg-green-100 dark:bg-green-900 dark:text-green-200"
                    : "text-red-900 bg-red-100 dark:bg-red-900 dark:text-red-200"
                }`}
          >
            {row.original.connection_status === 1
              ? "Connected"
              : "Not Connected"}
          </span>
        </div>
      );
    },
    getUniqueValues: (row) => {
      return [row.connection_status.toString()];
    },
    filterFn: (row, id, value) => {
      return value.includes(row.original.connection_status.toString());
    },
  },
  {
    accessorKey: "pages",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Pages" />
    ),
    cell: ({ row }) => {
      return (
        <div className="flex items-center text-xs sm:text-sm">
          {row.original.channel.toLowerCase() === "facebook" ? (
            <span className="underline text-muted-foreground cursor-pointer">
              View pages
            </span>
          ) : (
            ""
          )}
        </div>
      );
    },
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
  },
  {
    id: "actions",
    cell: ({ row }) => <DataTableRowActions row={row} />,
  },
];
