import apiClient from "api";
import { Button } from "components/ui/button";
import { Checkbox } from "components/ui/checkbox";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "components/ui/dialog";
import { ScrollArea } from "components/ui/scroll-area";
import { useToast } from "components/ui/use-toast";
import { useAppState } from "context/AppContext";
import { useConnection } from "context/ConnectionContext";
import { useEffect, useState } from "react";
import { Connection, FBPage } from "types";

type Props = {
  edit?: boolean;
  openDialog: boolean;
  connectionData: Connection | null;
  setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
  setSocialData?: React.Dispatch<React.SetStateAction<Connection | undefined>>;
};

const FacebookPagesDialog = ({
  edit,
  openDialog,
  connectionData,
  setOpenDialog,
  setSocialData,
}: Props) => {
  const { state } = useAppState();
  const { account_token } = state.user;
  const { setRefreshConnection } = useConnection();
  const { toast } = useToast();
  const [pages, setPages] = useState<FBPage[]>([]);
  const [selectedPages, setSelectedPages] = useState<number[]>([]);

  useEffect(() => {
    if (!connectionData) return;

    if (edit) {
      setSelectedPages(
        connectionData!.pages.filter((p) => p.selected === 1).map((_d) => _d.id)
      );
    }
    setPages(connectionData?.pages);
  }, [connectionData, edit]);

  const savePagesGroups = async (selectedData: any) => {
    if (!account_token) return;
    try {
      const { data } = await apiClient.post(
        "/select_facebook_object",
        {},
        {
          headers: {
            token: account_token,
            request: JSON.stringify(selectedData),
          },
        }
      );

      setSocialData && setSocialData(data?.message);
      setPages(data?.message?.pages);
    } catch (error) {
      console.error("Error", error);
    }
  };
  const unsavePagesGroups = async (selectedData: any) => {
    if (!account_token) return;
    try {
      const { data } = await apiClient.post(
        "/unselect_facebook_object",
        {},
        {
          headers: {
            token: account_token,
            request: JSON.stringify(selectedData),
          },
        }
      );
      setSocialData && setSocialData(data?.message);
      setPages(data?.message?.pages);
    } catch (error) {
      console.error("Error", error);
    }
  };

  const handleSave = async (checked: boolean, page: FBPage) => {
    if (checked) {
      setSelectedPages((ps) => [...ps, page.id]);
      savePagesGroups({
        connection_id: connectionData?.id,
        object_id: page.id,
      });
    } else {
      setSelectedPages((ps) => ps.filter((ps) => ps !== page.id));
      unsavePagesGroups({
        connection_id: connectionData?.id,
        object_id: page.id,
      });
    }
  };

  const handleChange = (page: FBPage) => {
    setSelectedPages((state) => {
      let newState = state;
      if (state.includes(page.id)) {
        if (newState.length === 1) {
          toast({
            title: "Action cannot be performed",
            description: "At least one selection is required!",
          });
        } else {
          newState = newState.filter((s) => s !== page.id);
          handleSave(false, page);
        }
      } else {
        newState = [...newState, page.id];
        handleSave(true, page);
      }
      return newState;
    });
  };

  return (
    <Dialog open={openDialog} onOpenChange={setOpenDialog}>
      <DialogContent className="sm:max-w-xl">
        <DialogHeader>
          <DialogTitle>Facebook pages</DialogTitle>
          <DialogDescription>
            Choose the pages on which you wish to post from this connection.
          </DialogDescription>
        </DialogHeader>
        <ScrollArea className="p-2.5 border rounded-lg h-60">
          <div className="flex flex-col gap-2.5">
            {pages?.map((page) => (
              <div
                key={page.facebook_id}
                className="items-top rounded-md flex space-x-2 bg-muted hover:bg-opacity-80 p-2.5"
                onClick={() => handleChange(page)}
              >
                <Checkbox
                  checked={selectedPages.includes(page.id)}
                  id={`checkbox-${page.facebook_name}`}
                />
                <div className="grid gap-1.5 leading-none">
                  <label
                    htmlFor="terms1"
                    className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                  >
                    {page.facebook_name}
                  </label>
                </div>
              </div>
            ))}
          </div>
        </ScrollArea>
        <DialogFooter>
          <DialogClose asChild>
            <Button onClick={() => setRefreshConnection(true)} type="submit">
              Save
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default FacebookPagesDialog;
