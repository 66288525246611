import React, { useState } from "react";
import { RefreshCw } from "lucide-react";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "components/ui/dialog";
import { Button } from "components/ui/button";
import CustomSelect from "components/Shared/CustomSelect";
import { useAppState } from "context/AppContext";
import { Profile } from "types";
import apiClient from "api";
import { useToast } from "components/ui/use-toast";

type Props = {
  open: boolean;
  profiles: Profile[];
  editData?: Profile | null;
  fetchData: () => void;
  onOpenChange: (value: boolean) => void;
};

const AddProfileDialog = ({
  open,
  profiles,
  editData,
  fetchData,
  onOpenChange,
}: Props) => {
  const { state } = useAppState();
  const { account } = state.admin;
  const { toast } = useToast();
  const [submitting, setSubmitting] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState<{
    [key: number]: Profile | null;
  }>({ 1: null, 2: null, 3: null, 4: null, 5: null });

  const addProfile = async (evt: React.FormEvent) => {
    evt.preventDefault();
    setSubmitting(true);

    try {
      // Create a Set to keep track of unique profiles
      const uniqueProfiles = Array.from(
        new Set(
          Object.values(selectedProfile).filter((profile) => profile !== null)
        )
      );

      // Iterate through uniqueProfiles and call the API for each non-null profile
      for (const profile of uniqueProfiles) {
        if (profile) {
          await apiClient.post(
            "/admin_creative_update_profile",
            {},
            {
              headers: {
                request: JSON.stringify({
                  creative_id: account?.account_id,
                  profile_id: profile.profile_id,
                  profile_name: profile.profile_name,
                }),
              },
            }
          );
        }
      }
      onOpenChange(false);
      fetchData();
    } catch (error) {
      console.log("Error:", error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <Dialog open={open} onOpenChange={onOpenChange}>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>{!!editData ? "Edit" : "Add"} profile</DialogTitle>
            <DialogDescription>Select up to 5 profiles</DialogDescription>
          </DialogHeader>

          <form className="grid gap-4" onSubmit={addProfile}>
            {Object.keys(selectedProfile).map((key) => (
              <div key={key} className="grid gap-2">
                <CustomSelect
                  label={`profile`}
                  onSelect={(value) => {
                    if (!value) {
                      setSelectedProfile((prev) => ({
                        ...prev,
                        [key]: null,
                      }));
                      return;
                    }

                    if (
                      Object.values(selectedProfile).some(
                        (selected) => selected?.profile_id === parseInt(String(value))
                      )
                    ) {
                      toast({
                        description: "Profile already selected",
                        variant: "destructive",
                      });
                      return;
                    }

                    const profile = profiles.find(
                      (p) => p.profile_id === parseInt(String(value))
                    );
                    setSelectedProfile((prev) => ({
                      ...prev,
                      [key]: profile || null,
                    }));
                  }}
                  value={
                    selectedProfile[parseInt(key)]
                      ? String(selectedProfile[parseInt(key)]?.profile_id)
                      : ""
                  }
                  data={profiles
                    .filter((p) => !p.base_creative_id)
                    .map((p) => ({
                      label: p.profile_name,
                      value: String(p.profile_id),
                    }))}
                />
              </div>
            ))}

            <DialogFooter>
              <DialogClose asChild>
                <Button
                  disabled={submitting}
                  type="button"
                  size="sm"
                  variant="outline"
                >
                  Cancel
                </Button>
              </DialogClose>
              <Button disabled={submitting} type="submit" size="sm">
                {submitting && (
                  <RefreshCw className="size-4 animate-spin mr-2" />
                )}{" "}
                Save
              </Button>
            </DialogFooter>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddProfileDialog;
