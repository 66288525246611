import { useEffect, useState } from "react";
import { columns } from "components/Tables/wordpress/columns";
import { DataTable } from "components/Tables/wordpress/data-table";
import { useAppState } from "context/AppContext";
import { useConnection } from "context/ConnectionContext";
import ScreenWithTitles from "components/Shared/ScreenWithTitles";
import { WordpressConnection } from "types";
import apiClient from "api";

const Wordpress = () => {
  const { state, dispatch } = useAppState();
  const { setIsOpen } = useConnection();
  const { account, refresh } = state.user;
  const [connections, setConnections] = useState<WordpressConnection[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (refresh !== "wordpress") return;
    const timeout = setTimeout(fetchWpConnections, 100);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line
  }, [refresh]);

  useEffect(() => {
    if (!account) return;
    const timeout = setTimeout(fetchWpConnections, 100);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line
  }, [account]);

  const fetchWpConnections = async () => {
    try {
      const { data } = await apiClient.get(
        "/creative_get_wordpress_connections",
        {
          headers: {
            request: JSON.stringify({ creative_id: account?.account_id }),
          },
        }
      );

      const connections: WordpressConnection[] = data?.message || [];
      setConnections(connections);
      if (refresh === "wordpress") {
        dispatch({ type: "SET_REFRESH", payload: "" });
      }
    } catch (error) {
      console.error("Error fetching connections:", error);
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line
  };

  return (
    <div className="flex flex-col space-y-8 md:py-4">
      <ScreenWithTitles
        title="Websites"
        btnTitle="Add website"
        handleClick={() => setIsOpen(true)}
        description={"Here's a list of your website."}
      />

      <DataTable loading={loading} data={connections} columns={columns} />
    </div>
  );
};

export default Wordpress;
