import { useEffect, useState } from "react";
import { RefreshCw } from "lucide-react";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "components/ui/dialog";
import { Input } from "components/ui/input";
import { Label } from "components/ui/label";
import { Button } from "components/ui/button";
import { useToast } from "components/ui/use-toast";
import { useAppState } from "context/AppContext";
import { Avatar, Connection, SocialChannelType } from "types";
import { Textarea } from "components/ui/textarea";
import { sanitizeString } from "utils/helper-methods";
import CustomSelect from "components/Shared/CustomSelect";
import apiClient from "api";

type Props = {
  open: boolean;
  editData?: Avatar | null;
  fetchData: () => void;
  onOpenChange: (value: boolean) => void;
};

// const channels = [
//   {
//     value: 1,
//     label: "Twitter",
//   },
//   {
//     value: 2,
//     label: "Facebook",
//   },
//   {
//     value: 3,
//     label: "LinkedIn",
//   },
// ];

const AddAvatarDialog = ({
  open,
  editData,
  fetchData,
  onOpenChange,
}: Props) => {
  const { state } = useAppState();
  const { account } = state.admin;
  const { toast } = useToast();

  const [bio, setBio] = useState("");
  const [pagingId, setPagingId] = useState(0);
  const [jobTitle, setJobTitle] = useState("");
  const [avatarUrl, setAvatarUrl] = useState("");
  const [avatarName, setAvatarName] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [channels, setChannels] = useState<SocialChannelType[]>([]);
  const [connections, setConnections] = useState<Connection[]>([]);
  const [channel, setChannel] = useState<SocialChannelType | null>(null);
  const [connection, setConnection] = useState<Connection | null>(null);
  const [loadingConnections, setLoadingConnections] = useState(false);

  useEffect(() => {
    if (!editData) return;
    setBio(editData.bio || "");
    setAvatarName(editData.avatar_name || "");
    setJobTitle(editData?.job_title || "");
    setAvatarUrl(editData.avatar_url || "");
    const _conn = connections.find((_d) => _d.id === editData.connection_id);
    setConnection(_conn || null);
    const _channel = channels.find(
      (_d) => _d.channel_id === editData.channel_id
    );
    setChannel(_channel || null);
    setPagingId(editData.paging_id);

    // eslint-disable-next-line
  }, [editData, channels, connections]);

  useEffect(() => {
    if (!open) return;

    const getChannels = async () => {
      try {
        const { data } = await apiClient.get(
          "/admin_creative_get_social_channels",
          {
            headers: {
              request: JSON.stringify({ creative_id: account?.account_id }),
            },
          }
        );

        const _channels: SocialChannelType[] = data?.message || [];
        setChannels(_channels);
      } catch (error) {
        console.log("error:", error);
      } finally {
      }
    };

    const getConnections = async () => {
      setLoadingConnections(true);
      try {
        const { data } = await apiClient.get("/creative_get_social_dashboard", {
          headers: {
            request: JSON.stringify({ creative_id: account?.account_id }),
          },
        });

        const connections: Connection[] = data?.message || [];
        setConnections(connections);
      } catch (error) {
        console.log("error:", error);
      } finally {
        setLoadingConnections(false);
      }
    };
    getChannels();
    getConnections();
    // eslint-disable-next-line
  }, [open]);

  const addAvatar = async (evt: React.FormEvent) => {
    evt.preventDefault();

    setSubmitting(true);
    if (Boolean(editData)) {
      try {
        const { data } = await apiClient.post(
          "/admin_creative_update_avatar",
          {},
          {
            headers: {
              request: JSON.stringify({
                creative_id: account?.account_id,
                avatar_id: editData?.avatar_id,
                channel_id: channel?.channel_id,
                paging_id: pagingId,
                job_title: jobTitle,
                avatar_url: avatarUrl,
                avatar_name: avatarName,
                connection_id: connection?.id || 0,
                bio: sanitizeString(bio),
                author_id: 0,
              }),
            },
          }
        );
        if (data?.message[0].result === -1) {
          toast({
            title: "Duplicate content",
            description: data?.message[0].message,
          });
        } else {
          onOpenChange(false);
          fetchData();
        }
      } catch (error) {
        console.log("Error:", error);
      } finally {
        setSubmitting(false);
      }
    } else {
      try {
        const { data } = await apiClient.post(
          "/admin_creative_add_avatar",
          {},
          {
            headers: {
              request: JSON.stringify({
                author_id: 0,
                paging_id: pagingId,
                channel_id: channel?.channel_id,
                job_title: jobTitle,
                avatar_url: avatarUrl,
                avatar_name: avatarName,
                creative_id: account?.account_id,
                connection_id: connection?.id || 0,
                bio: sanitizeString(bio),
              }),
            },
          }
        );
        if (data?.message[0].result === -1) {
          toast({
            title: "Duplicate content",
            description: data?.message[0].message,
          });
        } else {
          onOpenChange(false);
          fetchData();
        }
      } catch (error) {
        console.log("Error:", error);
      } finally {
        setSubmitting(false);
      }
    }
  };

  return (
    <>
      <Dialog open={open} onOpenChange={onOpenChange}>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>{!!editData ? "Edit" : "Add"} avatar</DialogTitle>
          </DialogHeader>

          <form onSubmit={addAvatar} className="grid gap-4">
            <div className="grid gap-2">
              <Label htmlFor="name">Avatar name</Label>
              <Input
                type="text"
                id="name"
                required
                value={avatarName}
                onChange={(evt) => {
                  const val = evt.target.value;
                  setAvatarName(val);
                }}
              />
            </div>

            <div className="grid gap-2">
              <Label htmlFor="url">Avatar url</Label>
              <Input
                type="url"
                id="url"
                required
                value={avatarUrl}
                onChange={(evt) => {
                  const val = evt.target.value;
                  setAvatarUrl(val);
                }}
              />
            </div>

            <div className="grid gap-2">
              <Label htmlFor="job-title">Job title</Label>
              <Input
                id="job-title"
                required
                value={jobTitle}
                onChange={(evt) => {
                  const val = evt.target.value;
                  setJobTitle(val);
                }}
              />
            </div>

            <div className="grid gap-2">
              <Label htmlFor="paging-id">Paging ID</Label>
              <div className="relative">
                <Input
                  id="paging-id"
                  type="number"
                  required
                  value={pagingId}
                  onChange={(evt) => {
                    const val = parseInt(evt.target.value);
                    if (val < 0) return;

                    setPagingId(val);
                  }}
                  className="resize-none"
                />
              </div>
            </div>

            <div className="grid gap-2">
              <Label htmlFor="bio">Bio</Label>
              <Textarea
                id="bio"
                rows={4}
                value={bio}
                onChange={(evt) => {
                  const val = evt.target.value;
                  setBio(val);
                }}
                className="resize-none"
              />
            </div>
            <div className="grid gap-2 w-full">
              <Label htmlFor="channel">Channel</Label>
              <CustomSelect
                value={channel ? String(channel.channel_id) : ""}
                onSelect={(value) => {
                  if (value === "") {
                    setChannel(null);
                    return;
                  }
                  const _channel = channels.find(
                    (_d) => _d.channel_id === parseInt(String(value))
                  );
                  setChannel(_channel || null);
                }}
                data={channels.map((c) => ({
                  value: String(c.channel_id),
                  label: c.description,
                }))}
                label="channel"
              />
            </div>

            <div className="grid gap-2 w-full">
              <Label htmlFor="connection">Connection</Label>
              <CustomSelect
                disabled={loadingConnections}
                value={connection ? String(connection.id) : ""}
                onSelect={(value) => {
                  if (value === "") {
                    setConnection(null);
                    return;
                  }
                  const _connection = connections.find(
                    (_d) => _d.id === parseInt(String(value))
                  );
                  setConnection(_connection || null);
                }}
                data={connections
                  .filter((c) => {
                    if (
                      channel?.description.toLowerCase().includes("x") ||
                      channel?.description.toLowerCase().includes("twitter")
                    ) {
                      return (
                        c.channel.toLowerCase().includes("x") ||
                        c.channel.toLowerCase().includes("twitter")
                      );
                    } else {
                      return channel?.description
                        .toLowerCase()
                        .includes(c.channel.toLowerCase());
                    }
                  })
                  .map((c) => ({
                    value: String(c.id),
                    label: c.connection_name,
                  }))}
                label="connection"
              />
            </div>

            <DialogFooter>
              <DialogClose asChild>
                <Button
                  disabled={submitting}
                  type="button"
                  size="sm"
                  variant="outline"
                >
                  Cancel
                </Button>
              </DialogClose>
              <Button disabled={submitting} type="submit" size="sm">
                {submitting && (
                  <RefreshCw className="size-4 animate-spin mr-2" />
                )}{" "}
                Save
              </Button>
            </DialogFooter>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddAvatarDialog;
