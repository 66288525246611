import { useState } from "react";
// import apiClient from "api";
import { useAppState } from "context/AppContext";
// import { buttonVariants } from "components/ui/button";
// import { cn } from "lib/utils";
import AccountSelect from "./AccountSelect";
import { EyeClosedIcon, EyeOpenIcon } from "@radix-ui/react-icons";
import Breadcrumbs from "components/Shared/Breadcrumbs";

const Summary = () => {
  const { state } = useAppState();
  const { agency, client, account } = state.admin;
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className="pb-8">
      <Breadcrumbs
        // icon={
        //   <Button size="icon" variant="secondary" className="mr-2">
        //     <PanelRightClose className="size-4" />
        //   </Button>
        // }
        routes={[
          { title: agency?.agency_name || "", path: "/admin/agencies" },
          { title: client?.customer_name || "", path: "/admin/clients" },
          { title: account?.account_name || "", path: "/admin/accounts" },
          { title: "Summary", path: "" },
        ]}
      />

      <div className="space-y-2 mt-8">
        <div className="flex justify-between items-center">
          <h3 className="scroll-m-20 text-2xl font-bold tracking-tight">
            Summary
          </h3>
          <AccountSelect />
        </div>
        <p className="text-base text-muted-foreground">
          A glance at what's happenning with the account.
        </p>
      </div>

      <div className="mt-4">
        <div className="mb-4 flex items-center justify-between">
          <p className="font-lg font-medium">Newsguru account</p>
        </div>
        <div className="grid gap-4 md:grid-cols-3">
          <div className="shadow border rounded-md">
            <div className="p-4 pb-2">
              <p className="text-sm font-medium truncate">
                {account?.newsguru_email || "No account attached"}
              </p>
            </div>
            <div className="p-4 pt-0">
              <p className="text-sm font-bold">Account email</p>
            </div>
          </div>
        </div>

        <div className="mb-4 mt-4 flex items-center justify-between">
          <p className="font-lg font-medium">Newsportal config</p>
        </div>
        <div className="grid gap-4 md:grid-cols-3">
          <div className="shadow border rounded-md">
            <div className="p-4 pb-2">
              <p
                className="text-sm font-medium truncate"
                title={account?.primary_url}
              >
                {account?.primary_url || "No url setup"}
              </p>
            </div>
            <div className="p-4 pt-0">
              <p className="text-sm font-bold">Site URL</p>
            </div>
          </div>
          <div className="shadow border rounded-md">
            <div className="p-4 pb-2">
              <p
                className="text-sm font-medium truncate"
                title={account?.wordpress_username}
              >
                {account?.wordpress_username || "No username setup"}
              </p>
            </div>
            <div className="p-4 pt-0">
              <p className="text-sm font-bold">Username</p>
            </div>
          </div>
          <div className="shadow border rounded-md">
            <div className="p-4 pb-2">
              <p className="text-sm font-medium truncate">
                {showPassword
                  ? account?.application_password || ""
                  : account?.application_password
                  ? "••••••••••"
                  : "-"}
              </p>
            </div>
            <div className="p-4 pt-0">
              <p className="text-sm font-bold flex justify-between items-center">
                App password{" "}
                {!showPassword ? (
                  <span
                    title="Show password"
                    onClick={() => setShowPassword(true)}
                  >
                    <EyeOpenIcon className="size-4" />
                  </span>
                ) : (
                  <span
                    title="Hide password"
                    onClick={() => setShowPassword(false)}
                  >
                    <EyeClosedIcon className="size-4" />
                  </span>
                )}
              </p>
            </div>
          </div>
        </div>

        <div className="mb-4 mt-4 flex items-center justify-between">
          <p className="font-lg font-medium">LinkedIn company</p>
        </div>
        <div className="grid gap-4 md:grid-cols-3">
          <div className="shadow border rounded-md">
            <div className="p-4 pb-2">
              <p
                className="text-sm font-medium truncate"
                title={account?.linkedin_company_name || "No company setup"}
              >
                {account?.linkedin_company_name || "No company setup"}
              </p>
            </div>
            <div className="p-4 pt-0">
              <p className="text-sm font-bold">Company name</p>
            </div>
          </div>
          <div className="shadow border rounded-md">
            <div className="p-4 pb-2">
              <p
                className="text-sm font-medium truncate"
                title={account?.linkedin_company_id || "No company setup"}
              >
                {account?.linkedin_company_id || "No company setup"}
              </p>
            </div>
            <div className="p-4 pt-0">
              <p className="text-sm font-bold">Company ID</p>
            </div>
          </div>
        </div>
        {/* <div className="my-2 flex items-center justify-between">
          <p className="font-lg font-medium">Campaigns</p>
          <Link
            to="/admin/account/campaigns"
            className={cn(buttonVariants({ variant: "link" }))}
          >
            View
          </Link>
        </div>
        <div className="grid gap-4 md:grid-cols-3">
          <div className="shadow border rounded-md">
            <div className="p-4 pb-2">
              <p className="text-sm font-medium">Total campaigns</p>
            </div>
            <div className="p-4 pt-0">
              <p className="text-xl font-bold">65</p>
            </div>
          </div>
        </div>
        <div className="my-2 flex items-center justify-between">
          <p className="font-lg font-medium">Connections</p>
          <Link
            to="/admin/account/posts"
            className={cn(buttonVariants({ variant: "link" }))}
          >
            View
          </Link>
        </div>
        <div className="grid gap-4 md:grid-cols-3">
          <div className="shadow border rounded-md">
            <div className="p-4 pb-2">
              <p className="text-sm font-medium">Total connections</p>
            </div>
            <div className="p-4 pt-0">
              <p className="text-xl font-bold">85</p>
            </div>
          </div>
          <div className="shadow border rounded-md">
            <div className="p-4 pb-2">
              <p className="text-sm font-medium">Bad connections</p>
            </div>
            <div className="p-4 pt-0">
              <p className="text-xl font-bold">15</p>
            </div>
          </div>
          <div className="shadow border rounded-md">
            <div className="p-4 pb-2">
              <p className="text-sm font-medium">Good connections</p>
            </div>
            <div className="p-4 pt-0">
              <p className="text-xl font-bold">70</p>
            </div>
          </div>
        </div>
        <div className="my-2 flex items-center justify-between">
          <p className="font-lg font-medium">Social channels</p>
          <Link
            to="/admin/account/posts"
            className={cn(buttonVariants({ variant: "link" }))}
          >
            View
          </Link>
        </div>
        <div className="grid gap-4 md:grid-cols-3">
          <div className="shadow border rounded-md">
            <div className="p-4 pb-2">
              <p className="text-sm font-medium">Total pages</p>
            </div>
            <div className="p-4 pt-0">
              <p className="text-xl font-bold">3</p>
            </div>
          </div>
          <div className="shadow border rounded-md">
            <div className="p-4 pb-2">
              <p className="text-sm font-medium">Total groups</p>
            </div>
            <div className="p-4 pt-0">
              <p className="text-xl font-bold">2</p>
            </div>
          </div>
          <div className="shadow border rounded-md">
            <div className="p-4 pb-2">
              <p className="text-sm font-medium">Total avatars</p>
            </div>
            <div className="p-4 pt-0">
              <p className="text-xl font-bold">2</p>
            </div>
          </div>
        </div>
        <div className="my-2 flex items-center justify-between">
          <p className="font-lg font-medium">Clickfarm</p>
          <Link
            to="/admin/account/posts"
            className={cn(buttonVariants({ variant: "link" }))}
          >
            View
          </Link>
        </div>
        <div className="grid gap-4 md:grid-cols-3">
          <div className="shadow border rounded-md">
            <div className="p-4 pb-2">
              <p className="text-sm font-medium">Total tasks</p>
            </div>
            <div className="p-4 pt-0">
              <p className="text-xl font-bold">450</p>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Summary;
