import { Fragment, useEffect, useRef, useState } from "react";
import { Button, buttonVariants } from "components/ui/button";
import {
  Check,
  ChevronRight,
  PlusCircle,
  RefreshCw,
  Trash,
  Unplug,
} from "lucide-react";
import { ScrollArea } from "components/ui/scroll-area";
import {
  Select,
  SelectTrigger,
  SelectContent,
  SelectGroup,
  SelectLabel,
  SelectValue,
  SelectItem,
} from "components/ui/select";
import { Separator } from "components/ui/separator";
import { useToast } from "components/ui/use-toast";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "components/ui/alert-dialog";
import { ToastAction } from "components/ui/toast";
import { Tooltip, TooltipContent, TooltipTrigger } from "components/ui/tooltip";

import { ConnectionType } from "../../../routes/private/Schedules/SetupSchedule";
import { useConnection } from "context/ConnectionContext";
import { Connection, Schedule, ScheduleSet } from "types";
import { getDeleteEndpoint } from "utils/helper-methods";
import { useAppState } from "context/AppContext";
import { cn } from "lib/utils";
import apiClient from "api";

interface GroupType {
  name: string;
  list: ConnectionType[];
}

interface ConnectionsProps {
  reset?: boolean;
  channels?: any;
  multiple?: boolean;
  selectedData: ScheduleSet;
  setSelectedData: React.Dispatch<React.SetStateAction<ScheduleSet>>;
}

const connectionsGroup: any = {
  all: "All connections",
  facebook: "Facebook",
  linkedin: "LinkedIn",
  twitter: "X (Twitter)",
  // wordpress: "Word Press",
  // wix: "Wix",
};

export const Connections: React.FC<ConnectionsProps> = ({
  reset,
  selectedData,
  setSelectedData,
  multiple = false,
}) => {
  const { state, dispatch } = useAppState();
  const { account_token, refresh } = state.user;
  const { setIsOpen } = useConnection();

  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState<ConnectionType[]>([]);
  const [selectedGroup, setSelectedGroup] = useState<string>("all");
  const [currentList, setCurrentList] = useState<ConnectionType[]>([]);
  const [allConnections, setAllConnections] = useState<GroupType[]>([]);
  const [facebook, setFacebook] = useState<ConnectionType[]>([]);
  const [linkedin, setLinkedin] = useState<ConnectionType[]>([]);
  const [twitter, setTwitter] = useState<ConnectionType[]>([]);
  // const [wix, setWix] = useState<ConnectionType[]>([]);
  // const [wordpress, setWordpress] = useState<ConnectionType[]>([]);

  useEffect(() => {
    if (selectedData.selectedConnection.length > 0 && selected.length === 0) {
      setSelected(selectedData.selectedConnection);
    }
    // eslint-disable-next-line
  }, [selectedData.selectedConnection]);

  useEffect(() => {
    if (refresh !== "connections") return;
    const timeout = setTimeout(fetchConnections, 100);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line
  }, [refresh]);

  useEffect(() => {
    if (reset) {
      setSelected([]);
    }
  }, [reset]);

  useEffect(() => {
    if (!account_token) return;
    const timeout = setTimeout(fetchConnections, 200);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line
  }, [account_token]);

  const fetchConnections = async () => {
    setLoading(true);
    try {
      const {
        data: { message },
      }: {
        data: {
          message: ConnectionType[];
        };
      } = await apiClient.get("/get_social_dashboard", {
        headers: {
          token: account_token,
        },
      });

      const fb = message.filter((_c) => _c.channel === "facebook");
      const li = message.filter((_c) => _c.channel === "linkedin");
      const tw = message.filter((_c) => _c.channel === "twitter");
      // const wix = connections.filter((_c) => _c.channel === "wix");
      // const wp = connections.filter((_c) => _c.channel === "wordpress");

      setAllConnections([
        {
          name: "linkedin",
          list: li,
        },
        {
          name: "facebook",
          list: fb,
        },
        {
          name: "twitter",
          list: tw,
        },
        // {
        //   name: "wordpress",
        //   list: wp,
        // },
        // {
        //   name: "wix",
        //   list: wix,
        // },
      ]);

      setFacebook(fb);
      setLinkedin(li);
      setTwitter(tw);
      // setWix(wix);
      // setWordpress(wp);
      setLoading(false);
      if (refresh === "connections")
        dispatch({ type: "SET_REFRESH", payload: "" });
    } catch (error) {
      setLoading(false);
      console.log("error", error);
    }
  };

  const onSelect = (connection: ConnectionType) => {
    if (multiple) {
      const selectionMap = new Map<number, ConnectionType>();
      selected.forEach((c) => {
        selectionMap.set(c.id, c);
      });

      let newSelected: ConnectionType[] = [];
      if (selectionMap.has(connection.id)) {
        newSelected = selected.filter((c) => c.id !== connection.id);
      } else {
        newSelected = [...selected, connection];
      }
      setSelected(newSelected);
      setSelectedData((ps) => ({ ...ps, selectedConnection: newSelected }));
    } else {
      const newSelected = selected[0]
        ? selected[0].id === connection.id
          ? []
          : [connection]
        : [connection];
      setSelected(newSelected);
      setSelectedData((ps) => ({ ...ps, selectedConnection: newSelected }));
    }
  };

  return (
    <Fragment>
      <div className={cn("flex h-[52px] items-center px-4")}>
        <div className="flex items-center">
          <Unplug size={18} className={cn("text-primary me-2")} />
          <h1 className="inline-flex items-center font-bold">Connections</h1>
        </div>
        <Button
          onClick={() => setIsOpen(true)}
          variant="ghost"
          size="icon"
          className="ml-auto"
        >
          <PlusCircle size={22} className="text-muted-foreground" />
        </Button>
      </div>
      <Separator />
      <div className={cn("flex h-[52px] items-center justify-center px-2")}>
        <Select
          value={selectedGroup}
          onValueChange={(value) => {
            if (value === "facebook") setCurrentList(facebook);
            if (value === "linkedin") setCurrentList(linkedin);
            if (value === "twitter") setCurrentList(twitter);
            // if (value === "wordpress") setCurrentList(wordpress);
            // if (value === "wix") setCurrentList(wix);
            // if (value === "podcast") { }
            setSelectedGroup(value);
          }}
        >
          <SelectTrigger className="w-full">
            <SelectValue placeholder="Select a connection" />
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              <SelectLabel>Connections</SelectLabel>
              {Object.keys(connectionsGroup).map((key) => (
                <SelectItem key={key} value={key}>
                  {connectionsGroup[key]}
                </SelectItem>
              ))}
            </SelectGroup>
          </SelectContent>
        </Select>
      </div>
      <ScrollArea className="h-[calc(100vh-212px)] lg:h-[calc(100vh-164px)] p-2">
        <div className="group flex flex-col gap-4">
          <nav className="grid gap-1 w-full">
            {loading ? (
              <div className="flex items-center justify-center">
                <div className="flex flex-col justify-center items-center">
                  <div className="size-8 border-2 border-t-2 border-muted border-t-primary rounded-full animate-spin"></div>
                  <span className="text-sm text-center mt-4 text-primary">
                    Please wait, Loading...
                  </span>
                </div>
              </div>
            ) : selectedGroup === "all" ? (
              allConnections.map((group) => (
                <GroupList
                  group={group}
                  key={group.name}
                  selected={selected}
                  onSelect={onSelect}
                  selectedGroup={selectedGroup}
                />
              ))
            ) : currentList.length > 0 ? (
              currentList.map((channel: ConnectionType, index: number) => (
                <ListItem
                  key={index}
                  item={channel}
                  index={index + 1}
                  selectParent={false}
                  selected={selected}
                  onSelect={onSelect}
                />
              ))
            ) : (
              <div className="flex text-sm justify-center items-center">
                No connections
              </div>
            )}
          </nav>
        </div>
      </ScrollArea>
    </Fragment>
  );
};

type GroupListProp = {
  group: GroupType;
  selectedGroup: string;
  selected: ConnectionType[];
  onSelect: (item: ConnectionType) => void;
};

const GroupList = ({
  group,
  selected,
  selectedGroup,
  onSelect,
}: GroupListProp) => {
  const [expand, setExpand] = useState(true);
  const contentRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <button
        onClick={() => setExpand(!expand)}
        className={cn(
          buttonVariants({
            variant: expand ? "secondary" : "outline",
            size: "sm",
          }),
          "justify-start h-10"
        )}
      >
        <div className="truncate">{connectionsGroup[group.name]}</div>

        <span
          className={cn(
            "ml-auto",
            "text-primary transition-all",
            expand && "rotate-90"
          )}
        >
          <ChevronRight size={18} />
        </span>
      </button>

      {/* <Separator /> */}

      <div
        className={cn(
          "flex flex-col gap-1 overflow-hidden",
          `transition-all duration-500 ease-in-out ${
            expand
              ? `max-h-screen opacity-100 visible`
              : "max-h-0 opacity-0 invisible"
          }`
        )}
        ref={contentRef}
      >
        {group.list.length === 0 ? (
          <span className="text-sm text-center py-2 text-primary">
            No connections
          </span>
        ) : (
          group.list.map((ch, index) => (
            <ListItem
              key={index}
              item={ch}
              index={index + 1}
              selected={selected}
              onSelect={onSelect}
              selectParent={selectedGroup === "all"}
            />
          ))
        )}
      </div>
    </>
  );
};

type ListProps = {
  index: number;
  item: ConnectionType;
  selectParent?: boolean;
  selected: ConnectionType[];
  onSelect: (item: ConnectionType) => void;
};

const ListItem = ({ item, selected, selectParent, onSelect }: ListProps) => {
  const { toast } = useToast();
  const {
    setIsReconnecting,
    setConnectionData,
    setConnectionState,
    setIsOpen,
  } = useConnection();
  const { state, dispatch } = useAppState();
  const { account_token } = state.user;

  const [openAlert, setOpenAlert] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [loadingSchedules, setLoadingSchedules] = useState(false);
  const [listOfSchedules, setListOfSchedules] = useState<string[]>([]);

  const selection = new Set(selected.map((c) => c.id));

  useEffect(() => {
    if (!openAlert) return;
    getSchedule();
    // eslint-disable-next-line
  }, [openAlert]);

  const getSchedule = async () => {
    setLoadingSchedules(true);
    try {
      const { data } = await apiClient.get("/get_scheduled_linkage_dashboard", {
        headers: {
          request: JSON.stringify({ connection_id: item.id }),
          token: account_token,
        },
      });
      const scheduleData: Schedule[] = data?.message || [];
      setListOfSchedules(scheduleData.map((s) => s.schedule_name));
      setLoadingSchedules(false);
    } catch (error) {
      setLoadingSchedules(false);
    }
  };

  const deleteConnection = async () => {
    const endpoint = getDeleteEndpoint(item.channel);
    setIsDeleting(true);

    try {
      await apiClient.delete(endpoint, {
        headers: {
          request: JSON.stringify({ id: item.id }),
          token: account_token,
        },
      });

      if (listOfSchedules.length > 0) {
        await deleteSchedule();
      }
      setIsDeleting(false);
      toast({
        description: "Delete successful!",
      });
      dispatch({ type: "SET_REFRESH", payload: "connections" });
    } catch (error) {
      setIsDeleting(false);
      console.log(error);
    }
  };

  const deleteSchedule = async () => {
    toast({
      description: "Deleting connection...",
    });
    try {
      await apiClient.delete("/delete_schedule_by_connection", {
        headers: {
          request: JSON.stringify({
            connection_id: item.id,
          }),
          token: account_token,
        },
      });
      setIsDeleting(false);
    } catch (error) {
      setIsDeleting(false);
    }
  };

  return (
    <>
      <button
        onClick={() => {
          if (item.connection_status === 0) {
            toast({
              description: "Connection is invalid, please reconnect!",
              variant: "destructive",
              action: (
                <ToastAction
                  onClick={() => {
                    setIsReconnecting(true);
                    setConnectionData(item as Connection);
                    setConnectionState((item as Connection).state);
                    setIsOpen(true);
                  }}
                  altText="Reconnect"
                >
                  Reconnect
                </ToastAction>
              ),
            });
            return;
          }
          onSelect(item!!);
        }}
        className={cn(
          buttonVariants({
            variant: selection.has(item.id) ? "default" : "ghost",
            size: "sm",
          }),
          "group justify-start transition-all h-10",
          selectParent && " ps-5"
        )}
      >
        <div className="truncate me-2">
          {!item?.connection_name
            ? item.connection_status === 0
              ? "Incomplete connection"
              : "Unnamed connection"
            : item.connection_name}
        </div>

        {selection.has(item.id) ? (
          <span
            className={cn(
              "ml-auto",
              "text-background dark:default transition-all"
            )}
          >
            <Check size={16} />
          </span>
        ) : (
          <span className="ml-auto flex items-center gap-2">
            {!selection.has(item.id) && (
              <Tooltip>
                <TooltipTrigger asChild>
                  <span
                    onClick={(evt) => {
                      evt.stopPropagation();
                      if (isDeleting) return;
                      setOpenAlert(true);
                    }}
                  >
                    <Trash size={16} className="text-red-500" />
                  </span>
                </TooltipTrigger>
                <TooltipContent>
                  <p>Delete connection</p>
                </TooltipContent>
              </Tooltip>
            )}
            {item.connection_status === 0 && (
              <Tooltip>
                <TooltipTrigger asChild>
                  <span
                    className={cn(
                      "text-background dark:default transition-all"
                    )}
                  >
                    <RefreshCw className="text-red-500" size={16} />
                  </span>
                </TooltipTrigger>
                <TooltipContent>
                  <p>Invalid connection, click to refresh.</p>
                </TooltipContent>
              </Tooltip>
            )}
          </span>
        )}
      </button>

      <AlertDialog open={openAlert} onOpenChange={setOpenAlert}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Are you sure?</AlertDialogTitle>
            <AlertDialogDescription>
              This action cannot be undone. This will permanently delete your
              connection and all associated schedules.
              {loadingSchedules ? (
                <span className="mt-2 block">Fetching schedules...</span>
              ) : listOfSchedules.length > 0 ? (
                <>
                  <span className="mt-2 block">Associated schedules.</span>
                  <span className="mt-2 dark:text-gray-200 block bg-gray-50 dark:bg-neutral-800 rounded-lg p-2">
                    {listOfSchedules.map((name, index) => (
                      <span key={name} className="block">
                        {index + 1}. {name}
                      </span>
                    ))}
                  </span>
                </>
              ) : (
                <span className="mt-2 block">No associated schedules.</span>
              )}
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction
              disabled={loadingSchedules}
              onClick={deleteConnection}
            >
              Confirm
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};
