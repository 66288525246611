import * as React from "react";
import {
  ColumnDef,
  ColumnFiltersState,
  Row,
  SortingState,
  VisibilityState,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Button } from "components/ui/button";
import { Checkbox } from "components/ui/checkbox";
import { MoreHorizontal } from "lucide-react";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "components/ui/alert-dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "components/ui/dropdown-menu";
import { useToast } from "components/ui/use-toast";
import CommonTable from "components/Shared/CommonTable";
import { DataTableColumnHeader } from "components/Shared/AdminTableHeader";
import AddAvatarDialog from "./AddAvatarDialog";
import { Avatar } from "types";
import apiClient from "api";
import LinkRenderer from "components/Shared/LinkRenderer";

type Props = {
  data: Avatar[];
  loading: boolean;
  fetchData: () => void;
  setSelectedAvatar: React.Dispatch<React.SetStateAction<Avatar | null>>;
};

export function DataTableAvatars({
  data,
  loading,
  fetchData,
  setSelectedAvatar,
}: Props) {
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );
  const [columnVisibility, setColumnVisibility] =
    React.useState<VisibilityState>({});
  const [rowSelection, setRowSelection] = React.useState({});
  const [selectedRowId, setSelectedRowId] = React.useState<number | null>(null);

  const toggleSelected = (row: Row<Avatar>) => {
    if (selectedRowId === row.original.avatar_id) {
      setSelectedRowId(null);
    } else {
      setSelectedRowId(row.original.avatar_id);
    }
  };

  React.useEffect(() => {
    if (selectedRowId) {
      const selectedRowData =
        data.find((row) => row.avatar_id === selectedRowId) || null;
      setSelectedAvatar(selectedRowData);
    } else {
      setSelectedAvatar(null);
    }

    // eslint-disable-next-line
  }, [selectedRowId, data]);

  const columns: ColumnDef<Avatar>[] = [
    {
      id: "select",
      header: () => null,
      cell: ({ row }) => (
        <Checkbox
          checked={selectedRowId === row.original.avatar_id}
          onCheckedChange={() => toggleSelected(row)}
          aria-label="Select row"
        />
      ),
      enableSorting: false,
      enableHiding: false,
    },
    {
      accessorKey: "avatar_id",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="ID" />
      ),
      cell: ({ row }) => <div className="w-24">{row.original.avatar_id}</div>,
    },

    {
      accessorKey: "avatar_name",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Avatar name" />
      ),
      cell: ({ row }) => (
        <div
          className="w-24 cursor-pointer hover:underline"
          onClick={() => toggleSelected(row)}
        >
          {row.original.avatar_name}
        </div>
      ),
    },
    {
      accessorKey: "avatar_url",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Avatar URL" />
      ),
      cell: ({ row }) => <LinkRenderer link={row.original.avatar_url} />,
    },
    {
      accessorKey: "job_title",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Job title" />
      ),
      cell: ({ row }) => (
        <div className="w-28">{row.original.job_title || "-"}</div>
      ),
    },
    {
      accessorKey: "paging_id",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Paging ID" />
      ),
      cell: ({ row }) => (
        <div className="w-24">{row.original?.paging_id ?? 0}</div>
      ),
    },
    {
      accessorKey: "bio",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Bio" />
      ),
      cell: ({ row }) => (
        <div className="w-40 truncate" title={row.original.bio}>
          {row.original.bio}
        </div>
      ),
    },
    {
      accessorKey: "channel_name",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Channel" />
      ),
      cell: ({ row }) => (
        <div className="w-24">
          {row.original.channel_name || "ID - " + row.original.channel_id}
        </div>
      ),
    },
    {
      accessorKey: "connection_name",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Connection name" />
      ),
      cell: ({ row }) => (
        <div className="w-36">
          <span className="truncate">
            {row.original.connection_name ||
              "ID - " + row.original.connection_id}
          </span>
        </div>
      ),
    },
    {
      id: "actions",
      header: "Actions",
      enableHiding: false,
      cell: ({ row }) => <RowAction row={row} fetchData={fetchData} />,
    },
  ];

  const table = useReactTable({
    data,
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
    },
  });

  return (
    <CommonTable
      table={table}
      name="Avatars"
      columns={columns}
      data={data}
      loading={loading}
    />
  );
}

interface DataTableRowActionsProps<TData> {
  row: Row<TData>;
  fetchData: () => void;
}

const RowAction = ({ row, fetchData }: DataTableRowActionsProps<Avatar>) => {
  const { toast } = useToast();

  const [openAlert, setOpenAlert] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [isDeleting, setIsDeleting] = React.useState(false);

  const data = row.original;

  const deleteRow = async () => {
    setIsDeleting(true);
    toast({
      description: "Deleting avatar...",
      variant: "destructive",
    });

    try {
      await apiClient.delete("/admin_creative_delete_avatar", {
        headers: {
          request: JSON.stringify({
            avatar_id: data.avatar_id,
          }),
        },
      });
      fetchData();
      setIsDeleting(false);
      toast({
        description: "Avatar deleted successfully.",
        variant: "destructive",
      });
    } catch (error) {
      setIsDeleting(false);
      toast({
        description: "Avatar deletion failed.",
        variant: "destructive",
      });
    }
  };

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" className="h-6 w-6 p-0">
            <span className="sr-only">Open menu</span>
            <MoreHorizontal className="h-3 w-3" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <DropdownMenuLabel>Actions</DropdownMenuLabel>
          <DropdownMenuItem onClick={() => setOpenEdit(true)}>
            Edit
          </DropdownMenuItem>
          <DropdownMenuSeparator />
          <DropdownMenuItem
            disabled={isDeleting}
            onClick={() => setOpenAlert(true)}
            className="text-red-600"
          >
            Delete
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>

      <AlertDialog open={openAlert} onOpenChange={setOpenAlert}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Are you sure?</AlertDialogTitle>
            <AlertDialogDescription>
              This action cannot be undone. This will permanently delete your
              avatar.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction onClick={deleteRow}>Confirm</AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      <AddAvatarDialog
        fetchData={fetchData}
        open={openEdit}
        onOpenChange={setOpenEdit}
        editData={data}
      />
    </>
  );
};
