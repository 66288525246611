import { useEffect, useState } from "react";
import { ChevronDown, ChevronRight } from "lucide-react";
import { ReloadIcon } from "@radix-ui/react-icons";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "components/ui/card";
import { Input } from "components/ui/input";
import { Label } from "components/ui/label";
import { Button } from "components/ui/button";
import Breadcrumbs from "components/Shared/Breadcrumbs";

import { useAppState } from "context/AppContext";
import AccountSelect from "../AccountSelect";
import apiClient from "api";

const PrimaryLinkedIn = () => {
  const { state, dispatch } = useAppState();
  const { agency, client, account } = state.admin;

  const [companyId, setCompanyId] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [isExpand, setIsExpand] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (!account) return;
    setCompanyName(account.linkedin_company_name || "");
    setCompanyId(account.linkedin_company_id || "");

    // eslint-disable-next-line
  }, [account]);

  const addWordpressSite = async (evt: React.FormEvent) => {
    evt.preventDefault();
    if (!account) return;
    setIsSubmitting(true);

    try {
      await apiClient.post(
        "/admin_creative_update_account",
        {},
        {
          headers: {
            request: JSON.stringify({
              ...account,
              linkedin_company_name: companyName.trim(),
              linkedin_company_id: companyId.trim(),
              client_id: client?.customer_id,
            }),
          },
        }
      );
      dispatch({ type: "SET_ADMIN_ACCOUNT_REFRESH", payload: true });
      setIsSubmitting(false);
      setIsEditing(false);
    } catch (error) {
      setIsEditing(false);
      setIsSubmitting(false);
    }
  };

  return (
    <div>
      <Breadcrumbs
        // icon={
        //   <Button size="icon" variant="secondary" className="mr-2">
        //     <PanelRightClose className="size-4" />
        //   </Button>
        // }
        routes={[
          { title: agency?.agency_name || "", path: "/admin/agencies" },
          { title: client?.customer_name || "", path: "/admin/clients" },
          { title: account?.account_name || "", path: "/admin/accounts" },
          { title: "Primary LinkedIn", path: "" },
        ]}
      />
      <div className="space-y-2 mt-8">
        <div className="flex justify-between items-center">
          <h3 className="scroll-m-20 text-2xl font-bold tracking-tight">
            Primary LinkedIn
          </h3>
          <AccountSelect />
        </div>
        {/* <p className="text-base text-muted-foreground">
      List of all campaigns edit/delete campaign from here.
    </p> */}
      </div>

      <div className="mt-10">
        <Card className="w-full mt-4">
          <CardHeader>
            <div className="flex justify-between items-center">
              <CardTitle>LinkedIn company configuration</CardTitle>
              <Button
                onClick={() => setIsExpand(!isExpand)}
                size="sm"
                variant="secondary"
              >
                {isExpand ? (
                  <ChevronDown className="size-4" />
                ) : (
                  <ChevronRight className="size-4" />
                )}
              </Button>
            </div>
          </CardHeader>
          {isExpand && (
            <CardContent>
              <form>
                <div className="grid w-full items-center gap-4">
                  <div className="flex flex-col space-y-1.5">
                    <Label htmlFor="company-name">Company name</Label>
                    {isEditing ? (
                      <Input
                        required
                        autoFocus={isEditing}
                        value={companyName}
                        onChange={(e) => setCompanyName(e.target.value)}
                        id="company-name"
                      />
                    ) : (
                      <p>{account?.linkedin_company_name || "-"}</p>
                    )}
                  </div>
                  <div className="flex flex-col space-y-1.5">
                    <Label htmlFor="company-id">Company ID</Label>
                    {isEditing ? (
                      <Input
                        required
                        value={companyId}
                        onChange={(e) => setCompanyId(e.target.value)}
                        id="company-id"
                      />
                    ) : (
                      <p>{account?.linkedin_company_id || "-"}</p>
                    )}
                  </div>
                </div>
              </form>
            </CardContent>
          )}
          {isExpand && (
            <CardFooter className="flex justify-between">
              {isEditing ? (
                <Button
                  type="button"
                  disabled={isSubmitting}
                  variant={"outline"}
                  onClick={() => setIsEditing(false)}
                >
                  Cancel
                </Button>
              ) : (
                <div></div>
              )}
              {isEditing ? (
                <Button
                  type="submit"
                  onClick={addWordpressSite}
                  disabled={!companyName || !companyId || isSubmitting}
                >
                  Save configuration{" "}
                  {isSubmitting && (
                    <ReloadIcon className="ml-2 animate-spin size-4" />
                  )}
                </Button>
              ) : (
                <Button type="button" onClick={() => setIsEditing(true)}>
                  Edit configuration
                </Button>
              )}
            </CardFooter>
          )}
        </Card>
      </div>
    </div>
  );
};

export default PrimaryLinkedIn;
