import * as React from "react";
import {
  Row,
  ColumnDef,
  SortingState,
  VisibilityState,
  getCoreRowModel,
  ColumnFiltersState,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
  getFacetedRowModel,
  getFacetedUniqueValues,
} from "@tanstack/react-table";
import { Button } from "components/ui/button";
import { Checkbox } from "components/ui/checkbox";
import { Eye, EyeOff, MoreHorizontal } from "lucide-react";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "components/ui/alert-dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "components/ui/dropdown-menu";
import { useToast } from "components/ui/use-toast";
import CommonTable from "components/Shared/CommonTable";
import { DataTableColumnHeader } from "components/Shared/AdminTableHeader";
import CustomLongTextRenderer from "components/Shared/CustomLongTextRenderer";
import AddGroupDialog from "./AddGroupDialog";
import { Group } from "types";
import apiClient from "api";
import { Input } from "components/ui/input";

type Props = {
  data: Group[];
  loading: boolean;
  resetRows: boolean;
  deleteMode: boolean;
  fetchData: () => void;
  setSelectedGroup: React.Dispatch<React.SetStateAction<Group | null>>;
  setResetRows: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedIds: React.Dispatch<React.SetStateAction<number[]>>;
};

export function DataTableGroup({
  data,
  loading,
  resetRows,
  deleteMode,
  fetchData,
  setResetRows,
  setSelectedIds,
  setSelectedGroup,
}: Props) {
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );
  const [columnVisibility, setColumnVisibility] =
    React.useState<VisibilityState>({});
  const [rowSelection, setRowSelection] = React.useState({});
  const [selectedRowId, setSelectedRowId] = React.useState<number | null>(null);
  const [expandAllRows, setExpandAllRows] = React.useState(false);
  const [toggleRow, setToggleRow] = React.useState<{
    [key: string]: boolean;
  }>({});

  const selectionToggle = (row: Row<Group>) => {
    if (selectedRowId === row.original.group_id) {
      setSelectedRowId(null);
    } else {
      setSelectedRowId(row.original.group_id);
    }
  };

  const handleToggleShowAll = (row: Group) => {
    const isExpanded =
      Object.keys(toggleRow).includes(String(row.group_id)) &&
      Boolean(toggleRow[String(row.group_id)]);

    if (isExpanded) {
      setToggleRow({ ...toggleRow, [String(row.group_id)]: false });
    } else {
      setToggleRow({ ...toggleRow, [String(row.group_id)]: true });
    }
  };

  const columns: ColumnDef<Group>[] = [
    {
      id: "select",
      header: () =>
        deleteMode ? (
          <Checkbox
            checked={
              table.getIsAllPageRowsSelected() ||
              (table.getIsSomePageRowsSelected() && "indeterminate")
            }
            onCheckedChange={(value) =>
              table.toggleAllPageRowsSelected(!!value)
            }
            aria-label="Select all"
          />
        ) : null,
      cell: ({ row }) =>
        deleteMode ? (
          <Checkbox
            checked={row.getIsSelected()}
            onCheckedChange={(value) => row.toggleSelected(!!value)}
            aria-label="Select row"
          />
        ) : (
          <Checkbox
            checked={selectedRowId === row.original.group_id}
            onCheckedChange={() => {
              selectionToggle(row);
            }}
            aria-label="Select row"
          />
        ),
      enableSorting: false,
      enableHiding: false,
    },
    {
      accessorKey: "group_id",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="ID" />
      ),
      cell: ({ row }) => <div className="">{row.original.group_id}</div>,
    },

    {
      accessorKey: "group_name",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Group name" />
      ),
      cell: ({ row }) => (
        <CustomLongTextRenderer
          longText={row.original.group_name}
          maxWidth="max-w-[240px]"
          onClick={() => selectionToggle(row)}
          showAll={
            expandAllRows
              ? true
              : Boolean(toggleRow[String(row.original.group_id)])
          }
        />
      ),
      enableSorting: true,
    },
    {
      accessorKey: "group_url",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Group URL" />
      ),
      cell: ({ row }) => (
        <CustomLongTextRenderer
          longText={row.original.group_url || "-"}
          maxWidth="max-w-[240px]"
          hasLink
          showAll={
            expandAllRows
              ? true
              : Boolean(toggleRow[String(row.original.group_id)])
          }
        />
      ),

      enableSorting: true,
    },
    {
      accessorKey: "group_description",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Group description" />
      ),
      cell: ({ row }) => (
        <CustomLongTextRenderer
          longText={row.original.group_description || "-"}
          maxWidth="max-w-[240px]"
          showAll={
            expandAllRows
              ? true
              : Boolean(toggleRow[String(row.original.group_id)])
          }
        />
      ),
      enableSorting: true,
    },
    {
      accessorKey: "channel_name",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Channel name" />
      ),
      cell: ({ row }) => (
        <div className="w-32">{row.original.channel_name}</div>
      ),

      enableSorting: true,
    },
    {
      accessorKey: "internal_group",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Internal group" />
      ),
      cell: ({ row }) => (
        <div className="w-36">
          {row.original.internal_group === 1 ? "Yes" : "No"}
        </div>
      ),

      enableSorting: true,
    },
    {
      id: "actions",
      header: () => {
        return (
          <div className="flex items-center space-x-2">
            <Button
              onClick={() => setExpandAllRows(!expandAllRows)}
              variant="ghost"
              className="h-6 w-6 p-0"
            >
              {expandAllRows ? (
                <EyeOff className="size-4" />
              ) : (
                <Eye className="size-4" />
              )}
            </Button>
            <p className="ml-2">Actions</p>
          </div>
        );
      },
      enableHiding: false,
      cell: ({ row }) => (
        <RowAction
          row={row}
          hasShowAll={true}
          showAllText={
            expandAllRows
              ? true
              : expandAllRows
              ? true
              : Boolean(toggleRow[String(row.original.group_id)])
          }
          fetchData={fetchData}
          toggleRow={() => handleToggleShowAll(row.original)}
        />
      ),
    },
  ];

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      rowSelection,
      columnFilters,
      columnVisibility,
    },
    enableRowSelection: true,
    enableColumnResizing: true,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
  });

  React.useEffect(() => {
    if (selectedRowId) {
      const selectedRowData =
        data.find((row) => row.group_id === selectedRowId) || null;
      setSelectedGroup(selectedRowData);
    } else {
      setSelectedGroup(null);
    }
    // eslint-disable-next-line
  }, [selectedRowId, data]);

  React.useEffect(() => {
    if (!resetRows) return;
    setRowSelection({});
    setResetRows(false);
    // eslint-disable-next-line
  }, [resetRows]);

  React.useEffect(() => {
    setSelectedIds(
      table.getSelectedRowModel().rows.map((row) => row.original.group_id)
    );
    // eslint-disable-next-line
  }, [rowSelection, table]);

  return (
    <CommonTable
      table={table}
      name="Groups"
      searchComponent={
        <div className="flex items-center py-4 pl-1">
          <Input
            placeholder="Filter groups..."
            value={
              (table.getColumn("group_name")?.getFilterValue() as string) ?? ""
            }
            onChange={(event) =>
              table.getColumn("group_name")?.setFilterValue(event.target.value)
            }
            className="max-w-sm h-8"
          />

          {/* <Button
            className="ml-auto"
            size="sm"
            onClick={() => setOpenAdd(true)}
          >
            Add agency
          </Button> */}
        </div>
      }
      columns={columns}
      data={data}
      loading={loading}
    />
  );
}

interface DataTableRowActionsProps<TData> {
  row: Row<TData>;
  hasShowAll: boolean;
  showAllText: boolean;
  fetchData: () => void;
  toggleRow: () => void;
}

const RowAction = ({
  row,
  hasShowAll,
  showAllText,
  fetchData,
  toggleRow,
}: DataTableRowActionsProps<Group>) => {
  const { toast } = useToast();

  const [openAlert, setOpenAlert] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [isDeleting, setIsDeleting] = React.useState(false);

  const group = row.original;

  const deleteSetting = async () => {
    setIsDeleting(true);
    toast({
      description: "Deleting group...",
      variant: "destructive",
    });

    try {
      await apiClient.delete("/admin_creative_delete_group", {
        headers: {
          request: JSON.stringify({
            group_id: group.group_id,
          }),
        },
      });
      fetchData();
      setIsDeleting(false);
      toast({
        description: "Group deleted successfully.",
        variant: "destructive",
      });
    } catch (error) {
      setIsDeleting(false);
      toast({
        description: "Group deletion failed.",
        variant: "destructive",
      });
    }
  };

  return (
    <div className="flex items-center space-x-2">
      {hasShowAll && (
        <Button onClick={toggleRow} variant="ghost" className="h-6 w-6 p-0">
          {showAllText ? (
            <EyeOff className="size-4" />
          ) : (
            <Eye className="size-4" />
          )}
        </Button>
      )}
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" className="h-6 w-6 p-0">
            <span className="sr-only">Open menu</span>
            <MoreHorizontal className="size-3" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <DropdownMenuLabel>Actions</DropdownMenuLabel>
          <DropdownMenuItem onClick={() => setOpenEdit(true)}>
            Edit
          </DropdownMenuItem>
          <DropdownMenuSeparator />
          <DropdownMenuItem
            disabled={isDeleting}
            onClick={() => setOpenAlert(true)}
            className="text-red-600"
          >
            Delete
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>

      <AlertDialog open={openAlert} onOpenChange={setOpenAlert}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Are you sure?</AlertDialogTitle>
            <AlertDialogDescription>
              This action cannot be undone. This will permanently delete your
              group.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction onClick={deleteSetting}>
              Confirm
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      <AddGroupDialog
        fetchData={fetchData}
        open={openEdit}
        onOpenChange={setOpenEdit}
        editData={group}
      />
    </div>
  );
};
