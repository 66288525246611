import { Button } from "components/ui/button";
import { Plus } from "lucide-react";

type ScreenProps = {
  title: string;
  btnTitle?: string;
  description?: string;
  handleClick?: () => void;
};

const ScreenWithTitles = ({
  title,
  btnTitle,
  description,
  handleClick,
}: ScreenProps) => {
  return (
    <div className="flex sm:items-center flex-col sm:flex-row sm:justify-between space-y-2">
      <div>
        {title && (
          <h2 className="text-xl sm:text-2xl font-bold tracking-tight">
            {title}
          </h2>
        )}
        {description && (
          <p className="text-muted-foreground sm:inline-block hidden">
            {description}
          </p>
        )}
      </div>
      {handleClick && (
        <div className="flex items-center space-x-2">
          <Button
            size="sm"
            variant="default"
            className="w-full sm:w-auto"
            onClick={handleClick}
          >
            <Plus className="size-4 mr-2" /> {btnTitle}
          </Button>
        </div>
      )}
    </div>
  );
};

export default ScreenWithTitles;
