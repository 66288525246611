import { useEffect, useState } from "react";
import { columns } from "components/Tables/connections/columns";
import { DataTable } from "components/Tables/connections/data-table";
import { useAppState } from "context/AppContext";
import { useConnection } from "context/ConnectionContext";
import ScreenWithTitles from "components/Shared/ScreenWithTitles";
import { Connection } from "types";
import apiClient from "api";

const Connections = () => {
  const { state, dispatch } = useAppState();
  const { setIsOpen } = useConnection();
  const { account, refresh } = state.user;
  const [connections, setConnections] = useState<Connection[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!refresh || refresh !== "connections") return;
    const timeout = setTimeout(fetchConnections, 200);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line
  }, [refresh]);

  useEffect(() => {
    if (!account) return;
    const timeout = setTimeout(fetchConnections, 200);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line
  }, [account]);

  const fetchConnections = async () => {
    try {
      const { data } = await apiClient.get("/creative_get_social_dashboard", {
        headers: {
          request: JSON.stringify({ creative_id: account?.account_id }),
        },
      });

      const connections: Connection[] = data?.message || [];
      setConnections(connections);
      if (refresh === "connections")
        dispatch({ type: "SET_REFRESH", payload: "" });
    } catch (error) {
      console.error("Error fetching connections:", error);
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line
  };

  return (
    <div className="flex flex-col space-y-8 md:py-4">
      <ScreenWithTitles
        title="Social connections"
        btnTitle="Add connection"
        handleClick={() => setIsOpen(true)}
        description={"Here's a list of your connections."}
      />
      <DataTable loading={loading} data={connections} columns={columns} />
    </div>
  );
};

export default Connections;
