export const channel = [
  {
    value: "facebook",
    label: "Facebook",
  },
  {
    value: "twitter",
    label: "X (Twitter)",
  },
  {
    value: "linkedin",
    label: "LinkedIn",
  },
];

export const type = [
  {
    value: "auto",
    label: "Auto",
  },
  {
    value: "manual",
    label: "Manual",
  },
];
