import * as React from "react";
import {
  ColumnDef,
  ColumnFiltersState,
  Row,
  SortingState,
  VisibilityState,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Button } from "components/ui/button";
import { MoreHorizontal } from "lucide-react";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "components/ui/alert-dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "components/ui/dropdown-menu";
import { useToast } from "components/ui/use-toast";
import CommonTable from "components/Shared/CommonTable";
import { DataTableColumnHeader } from "components/Shared/AdminTableHeader";
import AddGroupScheduleDialog from "./AddGroupScheduleDialog";
import { formatDateTime } from "utils/helper-methods";
import { Group, GroupSchedule } from "types";
import apiClient from "api";

type Props = {
  loading: boolean;
  data: GroupSchedule[];
  selectedGroup: Group | null;
  fetchData: () => void;
};

export function DataTableGroupSchedules({
  data,
  loading,
  selectedGroup,
  fetchData,
}: Props) {
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );
  const [columnVisibility, setColumnVisibility] =
    React.useState<VisibilityState>({});
  const [rowSelection, setRowSelection] = React.useState({});

  const columns: ColumnDef<GroupSchedule>[] = [
    {
      accessorKey: "group_schedule_id",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="ID" />
      ),
      cell: ({ row }) => (
        <div className="">{row.original.group_schedule_id}</div>
      ),
    },
    {
      accessorKey: "group_name",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Group name" />
      ),
      cell: ({ row }) => (
        <div className="w-60 line-clamp-2 truncate whitespace-normal">
          {row.original.group_name}
        </div>
      ),
    },
    {
      accessorKey: "content_type_name",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Content name" />
      ),
      cell: ({ row }) => (
        <div className="w-60 line-clamp-2 truncate whitespace-normal">{row.original.content_type_name}</div>
      ),
    },
    {
      accessorKey: "content_description",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Content description" />
      ),
      cell: ({ row }) => (
        <div className="w-60 line-clamp-2 truncate whitespace-normal">{row.original.content_description || "-"}</div>
      ),
    },
    {
      accessorKey: "poster_name",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Poster name" />
      ),
      cell: ({ row }) => <div className="w-28">{row.original.poster_name}</div>,
    },
    {
      accessorKey: "poster_description",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Poster description" />
      ),
      cell: ({ row }) => (
        <div className="w-40">{row.original.poster_description || "-"}</div>
      ),
    },
    {
      accessorKey: "earliest_timestamp",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Earliest timestamp" />
      ),
      cell: ({ row }) => (
        <div className="w-40">
          {row.original.earliest_timestamp
            ? formatDateTime(
                new Date(row.original.earliest_timestamp * 1000),
                true
              )
            : "-"}
        </div>
      ),
    },
    {
      accessorKey: "hours_between",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Hours between" />
      ),
      cell: ({ row }) => (
        <div className="w-28">{row.original.hours_between}</div>
      ),
    },
    {
      id: "actions",
      header: "Actions",
      enableHiding: false,
      cell: ({ row }) => (
        <RowAction
          row={row}
          selectedGroup={selectedGroup}
          fetchData={fetchData}
        />
      ),
    },
  ];

  const table = useReactTable({
    data,
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
    },
  });

  return (
    <CommonTable
      table={table}
      name="Group schedules"
      columns={columns}
      data={data}
      loading={loading}
    />
  );
}

interface DataTableRowActionsProps<TData> {
  row: Row<TData>;
  selectedGroup: Group | null;
  fetchData: () => void;
}

const RowAction = ({
  row,
  selectedGroup,
  fetchData,
}: DataTableRowActionsProps<GroupSchedule>) => {
  const { toast } = useToast();

  const [openEdit, setOpenEdit] = React.useState(false);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [isDeleting, setIsDeleting] = React.useState(false);

  const group = row.original;

  const deleteGroupSchedule = async () => {
    setIsDeleting(true);
    toast({
      description: "Deleting group schedule...",
      variant: "destructive",
    });

    try {
      await apiClient.delete("/admin_creative_delete_group_schedule", {
        headers: {
          request: JSON.stringify({
            group_schedule_id: group.group_schedule_id,
          }),
        },
      });
      fetchData();
      setIsDeleting(false);
      toast({
        description: "Group schedule deleted successfully.",
        variant: "destructive",
      });
    } catch (error) {
      setIsDeleting(false);
      toast({
        description: "Group schedule deletion failed.",
        variant: "destructive",
      });
    }
  };

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" className="h-6 w-6 p-0">
            <span className="sr-only">Open menu</span>
            <MoreHorizontal className="h-3 w-3" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <DropdownMenuLabel>Actions</DropdownMenuLabel>
          <DropdownMenuItem onClick={() => setOpenEdit(true)}>
            Edit
          </DropdownMenuItem>
          <DropdownMenuSeparator />
          <DropdownMenuItem
            disabled={isDeleting}
            onClick={() => setOpenAlert(true)}
            className="text-red-600"
          >
            Delete
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>

      <AlertDialog open={openAlert} onOpenChange={setOpenAlert}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Are you sure?</AlertDialogTitle>
            <AlertDialogDescription>
              This action cannot be undone. This will permanently delete your
              group schedule.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction onClick={deleteGroupSchedule}>
              Confirm
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      <AddGroupScheduleDialog
        open={openEdit}
        editData={group}
        selectedGroup={selectedGroup}
        onOpenChange={setOpenEdit}
        fetchData={fetchData}
      />
    </>
  );
};
