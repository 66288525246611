import * as React from "react";
import { Popover, PopoverTrigger, PopoverContent } from "components/ui/popover";
import { Button } from "components/ui/button";
import { Input } from "components/ui/input";
import { Calendar } from "components/ui/calendar";
import { ClockIcon, CalendarIcon } from "@radix-ui/react-icons";
import { format } from "date-fns";
import { type Locale, enGB } from "date-fns/locale";

interface DateTimePickerProps {
  value?: Date;
  onChange?: (date: Date) => void;
  locale?: Locale;
}

const DateTimePicker: React.FC<DateTimePickerProps> = ({
  value,
  onChange,
  locale = enGB,
}) => {
  const [selectedDate, setSelectedDate] = React.useState<Date | undefined>(
    value
  );
  const [hours, setHours] = React.useState<string>("00");
  const [minutes, setMinutes] = React.useState<string>("00");
  const hourInputRef = React.useRef<HTMLInputElement | null>(null);
  const minuteInputRef = React.useRef<HTMLInputElement | null>(null);
  const [flag, setFlag] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (flag) {
      const timer = setTimeout(() => {
        setFlag(false);
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [flag]);

  React.useEffect(() => {
    if (selectedDate && onChange) {
      const updatedDate = new Date(selectedDate);
      updatedDate.setHours(parseInt(hours, 10));
      updatedDate.setMinutes(parseInt(minutes, 10));
      onChange(updatedDate);
    }
  }, [hours, minutes, selectedDate, onChange]);

  const calculateNewValue = (
    currentValue: string,
    newKey: string,
    maxValue: number
  ) => {
    let newValue = !flag ? `0${newKey}` : currentValue.slice(1) + newKey;
    if (parseInt(newValue, 10) > maxValue) {
      newValue = maxValue.toString().padStart(2, "0");
    }
    return newValue;
  };

  // Handle key down events for both inputs
  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    type: "hours" | "minutes"
  ) => {
    if (e.key === "Tab") return;
    e.preventDefault();

    if (e.key >= "0" && e.key <= "9") {
      const currentValue = type === "hours" ? hours : minutes;
      const maxValue = type === "hours" ? 23 : 59;
      const newValue = calculateNewValue(currentValue, e.key, maxValue);

      if (type === "hours") {
        setHours(newValue);
        setFlag(true);
      }

      if (type === "minutes") {
        setMinutes(newValue);
        setFlag(true);
      }
    }

    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
      let step = e.key === "ArrowUp" ? 1 : -1;

      if (type === "hours") {
        let newHours = (parseInt(hours, 10) + step + 24) % 24;
        setHours(newHours.toString().padStart(2, "0"));
      }

      if (type === "minutes") {
        let newMinutes = (parseInt(minutes, 10) + step + 60) % 60;
        setMinutes(newMinutes.toString().padStart(2, "0"));
      }
    }
  };

  const handleDateChange = (date: Date | undefined) => {
    if (!date) return;
    setSelectedDate(date);
    if (onChange) {
      const updatedDate = new Date(date);
      updatedDate.setHours(parseInt(hours, 10));
      updatedDate.setMinutes(parseInt(minutes, 10));
      onChange(updatedDate);
    }
  };

  return (
    <div className="flex items-center space-x-2">
      <Popover>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            className="w-full justify-start text-left font-normal mb-4"
          >
            <CalendarIcon className="mr-2 h-4 w-4" />
            {selectedDate ? (
              format(selectedDate, "PPP", { locale })
            ) : (
              <span>Pick a date & time</span>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent
          className="w-auto p-4"
          align="start"
          onOpenAutoFocus={(e) => e.preventDefault()}
        >
          <div className="mb-4">
            <Calendar
              mode="single"
              required
              locale={locale}
              selected={selectedDate}
              onSelect={handleDateChange}
              className="mb-4"
            />
          </div>
        </PopoverContent>
      </Popover>

      <div className="flex items-center gap-2 mb-4">
        <ClockIcon className="w-4 h-4" />
        <Input
          type="tel"
          ref={hourInputRef}
          value={hours}
          inputMode="decimal"
          onChange={(e) => {
            // Allow users to manually enter a value
            let newValue = e.target.value.replace(/[^0-9]/g, "");
            if (newValue.length > 2) {
              newValue = newValue.slice(-2);
            }
            if (parseInt(newValue, 10) > 23) {
              newValue = "23";
            }
            setHours(newValue.padStart(2, "0"));
          }}
          onFocus={(e) => e.target.select()}
          onClick={() => hourInputRef.current?.focus()}
          onKeyDown={(e) => handleKeyDown(e, "hours")}
          className="w-12 text-center"
          maxLength={2}
        />
        :
        <Input
          type="tel"
          ref={minuteInputRef}
          value={minutes}
          inputMode="decimal"
          onChange={(e) => {
            // Allow users to manually enter a value
            let newValue = e.target.value.replace(/[^0-9]/g, "");
            if (newValue.length > 2) {
              newValue = newValue.slice(-2);
            }
            if (parseInt(newValue, 10) > 59) {
              newValue = "59";
            }
            setMinutes(newValue.padStart(2, "0"));
          }}
          onFocus={(e) => e.target.select()}
          onClick={() => minuteInputRef.current?.focus()}
          onKeyDown={(e) => handleKeyDown(e, "minutes")}
          className="w-12 text-center"
          maxLength={2}
        />
      </div>
    </div>
  );
};

export { DateTimePicker };
