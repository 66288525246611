import { useEffect, useState } from "react";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "components/ui/dropdown-menu";
import { Button, buttonVariants } from "components/ui/button";
import { Skeleton } from "components/ui/skeleton";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "components/ui/alert-dialog";
import {
  SortAsc,
  SortDesc,
  RefreshCw,
  Filter,
  FilterX,
  Activity,
  Wrench,
  RefreshCcw,
  X,
  Info,
  Trash2,
} from "lucide-react";
import { formatDistanceToNow } from "date-fns";
import Breadcrumbs from "components/Shared/Breadcrumbs";
import { useToast } from "components/ui/use-toast";
import NotificationDetails from "./NotificationDetails";
import { Checkbox } from "components/ui/checkbox";
import { Input } from "components/ui/input";
import { ScrollArea } from "components/ui/scroll-area";
import { useAppState } from "context/AppContext";
import { Notification } from "types";
import { cn } from "lib/utils";
import apiClient from "api";

const NotificationsPage = () => {
  const { toast } = useToast();
  const { state, dispatch } = useAppState();
  const {
    sortOrder,
    searchQuery,
    isAllSelected,
    notifications,
    selectedNotifications,
    filteredNotifications,
    selectedNotificationType,
  } = state.notifications;

  const [loading, setLoading] = useState(false);
  const [isClearing, setIsClearing] = useState(false);
  const [notificationFilters, setNotificationFilters] = useState<
    { id: number; name: string }[]
  >([]);
  const [showNotificationDetails, setShowNotificationDetails] =
    useState<boolean>(false);
  const [selectedNotification, setSelectedNotification] =
    useState<Notification | null>(null);

  const setSelectedNotificationType = (payload: number) =>
    dispatch({
      type: "SET_SELECTED_NOTIFICATION_TYPE",
      payload,
    });

  const setSelectedNotifications = (payload: number[]) =>
    dispatch({ type: "SET_SELECTED_NOTIFICATIONS", payload });

  const setFilteredNotifications = (payload: Notification[]) =>
    dispatch({ type: "SET_FILTERED_NOTIFICATIONS", payload });

  const setIsAllSelected = (payload: boolean) =>
    dispatch({
      type: "SET_IS_ALL_SELECTED",
      payload,
    });

  const setNotifications = (payload: Notification[]) =>
    dispatch({ type: "SET_NOTIFICATIONS", payload });

  const setSearchQuery = (payload: string) =>
    dispatch({ type: "SET_SEARCH_QUERY", payload });

  const setSortOrder = (payload: "asc" | "desc") =>
    dispatch({ type: "SET_SORT_ORDER", payload });

  useEffect(() => {
    fetchNotifications(true);
    fetchNotificationSources();
    // eslint-disable-next-line
  }, []);

  const fetchNotificationSources = async () => {
    try {
      const { data } = await apiClient.get(
        "/admin_creative_get_notification_sources"
      );
      const sources = data.message || [];
      setNotificationFilters([
        { id: -1, name: "All" },
        ...sources.map((s: any) => ({
          id: s.notification_source_id,
          name: s.notification_source_description,
        })),
      ]);
    } catch (error) {
      console.log("Error:", error);
    }
  };

  useEffect(() => {
    handleFilterNotifications();
    // eslint-disable-next-line
  }, [selectedNotificationType, notifications, searchQuery]);

  const fetchNotifications = async (clearFilter: boolean) => {
    if (clearFilter) setSelectedNotificationType(-1);

    setShowNotificationDetails(false);
    setSelectedNotification(null);
    setLoading(true);
    try {
      const { data } = await apiClient.get("/admin_creative_get_notifications");
      setNotifications(data?.message ?? []);
    } catch (error) {
      console.log("Error", error);
      toast({
        description: "Failed to fetch notifications",
        variant: "destructive",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleFilterNotifications = () => {
    let filtered = notifications;
    setFilteredNotifications(
      notifications.filter(
        (n) => n.notification_type === selectedNotificationType
      )
    );
    if (selectedNotificationType !== -1) {
      filtered = filtered.filter(
        (n) => n.notification_type === selectedNotificationType
      );
    }
    if (searchQuery.trim()) {
      const searchQueryLowerCase = searchQuery.toLowerCase();
      filtered = filtered.filter(
        (notification) =>
          notification.notification_text
            .toLowerCase()
            .includes(searchQueryLowerCase) ||
          (notification.account_name &&
            notification.account_name
              .toLowerCase()
              .includes(searchQueryLowerCase)) ||
          (notification.notification_name &&
            notification.notification_name
              .toLowerCase()
              .includes(searchQueryLowerCase))
      );
    }

    setFilteredNotifications(filtered);
  };

  // Sort notifications from date asc and desc
  const toggleSortOrder = () => {
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  const sortedNotifications = [...filteredNotifications].sort((a, b) =>
    sortOrder === "asc"
      ? new Date(a.reference_date).getTime() -
        new Date(b.reference_date).getTime()
      : new Date(b.reference_date).getTime() -
        new Date(a.reference_date).getTime()
  );

  const getIconByType = (type: number) => {
    switch (type) {
      case 4:
        return <Activity className="text-blue-500 size-5" />;
      case 2:
        return <RefreshCcw className="text-red-500 size-5" />;
      case 1:
        return <Wrench className="text-blue-500 size-5" />;
      default:
        return <Info className="text-blue-500 size-5" />;
    }
  };

  const handleRemoveNotification = async (id: number) => {
    setIsClearing(true);
    try {
      await apiClient.post(
        "/admin_creative_clear_notification",
        {},
        {
          headers: {
            request: JSON.stringify({
              notification_id: id,
            }),
          },
        }
      );
      const clearFilter = false;
      fetchNotifications(clearFilter);
    } catch (error) {
      toast({
        description: "Failed to clear notifications",
        variant: "destructive",
      });
    } finally {
      setIsClearing(false);
    }
  };

  useEffect(() => {
    const filteredData = filteredNotifications.filter((n) => n.can_clear === 1);
    if (filteredData.length === 0 || selectedNotifications.length === 0) return;

    if (filteredData.length === selectedNotifications.length) {
      setIsAllSelected(true);
    } else {
      setIsAllSelected(false);
    }
    // eslint-disable-next-line
  }, [selectedNotifications, filteredNotifications]);

  const handleSelectAll = () => {
    if (
      filteredNotifications.filter((n) => n.can_clear === 1).length ===
      selectedNotifications.length
    ) {
      setSelectedNotifications([]);
    } else {
      setSelectedNotifications(
        filteredNotifications
          .filter((n) => n.can_clear === 1)
          .map((n) => n.notification_id)
      );
    }
  };

  const handleSelectNotification = (id: number) => {
    const updatedData = selectedNotifications.includes(id)
      ? selectedNotifications.filter((notificationId) => notificationId !== id)
      : [...selectedNotifications, id];

    setSelectedNotifications(updatedData);
  };

  const handleDeleteSelected = async () => {
    setIsClearing(true);
    toast({
      description: "Clearing notifications...",
    });
    try {
      for (const id of selectedNotifications) {
        await apiClient.post(
          "/admin_creative_clear_notification",
          {},
          {
            headers: {
              request: JSON.stringify({
                notification_id: id,
              }),
            },
          }
        );
      }
      setSelectedNotifications([]);
      fetchNotifications(false);
      toast({
        description: "Notifications cleared sucessfully.",
      });
    } catch (error) {
      toast({
        description: "Failed to clear notifications",
        variant: "destructive",
      });
    } finally {
      setIsClearing(false);
    }
  };

  return (
    <div>
      <Breadcrumbs routes={[{ title: "Notifications", path: "" }]} />
      <div className="max-w-screen-sm mx-auto mt-4">
        {showNotificationDetails && selectedNotification ? (
          <NotificationDetails
            notification={selectedNotification}
            fetchNotifications={() => fetchNotifications(false)}
            goBack={() => {
              setSelectedNotification(null);
              setShowNotificationDetails(false);
            }}
          />
        ) : (
          <div>
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-lg font-semibold">
                Notifications ({filteredNotifications.length})
              </h2>
              <div className="flex items-center space-x-2">
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <Button
                      size="icon"
                      variant={
                        selectedNotificationType === -1
                          ? "secondary"
                          : "destructive"
                      }
                      aria-label="Filter by type"
                    >
                      {selectedNotificationType === -1 ? (
                        <Filter className="size-4" />
                      ) : (
                        <FilterX className="size-4" />
                      )}
                    </Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent className="w-36">
                    <DropdownMenuLabel>Type</DropdownMenuLabel>
                    <DropdownMenuSeparator />
                    <DropdownMenuGroup>
                      {notificationFilters.map((item) => (
                        <DropdownMenuCheckboxItem
                          key={item.id}
                          checked={selectedNotificationType === item.id}
                          onCheckedChange={() =>
                            setSelectedNotificationType(item.id)
                          }
                        >
                          {item.name}
                        </DropdownMenuCheckboxItem>
                      ))}
                    </DropdownMenuGroup>
                  </DropdownMenuContent>
                </DropdownMenu>
                <Button
                  size="icon"
                  variant="secondary"
                  onClick={toggleSortOrder}
                  aria-label="Sort by date"
                >
                  {sortOrder === "asc" ? (
                    <SortAsc className="size-4" />
                  ) : (
                    <SortDesc className="size-4" />
                  )}
                </Button>
                <Button
                  size="icon"
                  variant="secondary"
                  onClick={() => {
                    if (loading) return;
                    fetchNotifications(false);
                  }}
                  aria-label="Fetch notifications"
                >
                  <RefreshCw
                    className={`size-4 ${loading ? "animate-spin" : ""}`}
                  />
                </Button>
              </div>
            </div>

            <div className="flex items-center pb-4">
              <Input
                placeholder="Search..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="h-10"
              />
            </div>

            <div className="flex items-center justify-between mb-4 py-2 px-3 bg-primary-foreground rounded-lg">
              <div className="flex items-center space-x-2">
                <div
                  onClick={handleSelectAll}
                  className={cn(
                    buttonVariants({ size: "icon", variant: "ghost" })
                  )}
                >
                  <Checkbox checked={isAllSelected} id="select-all" />
                </div>
                <label
                  htmlFor="select-all"
                  className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                >
                  Select ({selectedNotifications.length})
                </label>
              </div>

              {selectedNotifications.length > 0 && (
                <AlertDialog>
                  <AlertDialogTrigger asChild>
                    <Button
                      disabled={isClearing}
                      className="ml-2"
                      variant="destructive"
                      size="icon"
                    >
                      <Trash2 className="size-4" />
                    </Button>
                  </AlertDialogTrigger>
                  <AlertDialogContent>
                    <AlertDialogHeader>
                      <AlertDialogTitle>Are you sure?</AlertDialogTitle>
                      <AlertDialogDescription>
                        This action cannot be undone and will delete all the
                        selected notifications.
                      </AlertDialogDescription>
                    </AlertDialogHeader>
                    <AlertDialogFooter>
                      <AlertDialogCancel>Cancel</AlertDialogCancel>
                      <AlertDialogAction onClick={handleDeleteSelected}>
                        Continue
                      </AlertDialogAction>
                    </AlertDialogFooter>
                  </AlertDialogContent>
                </AlertDialog>
              )}
            </div>

            <ScrollArea className="h-[75vh]">
              <ul className="space-y-4 mb-4">
                {loading
                  ? Array.from({ length: 10 }).map((_, index) => (
                      <Skeleton key={index} className="h-14 w-full" />
                    ))
                  : sortedNotifications.map((notification) => (
                      <li
                        onClick={() => {
                          setShowNotificationDetails(true);
                          setSelectedNotification(notification);
                        }}
                        key={notification.notification_id}
                        className={`flex justify-between items-center hover:bg-secondary p-3 rounded-lg border cursor-pointer`}
                      >
                        <div className="flex items-center space-x-3">
                          <div className="flex items-center">
                            <div
                              className={cn(
                                buttonVariants({
                                  size: "icon",
                                  variant: "ghost",
                                }),
                                "mr-3"
                              )}
                              onClick={(e) => {
                                e.stopPropagation();
                                if (notification.can_clear === 0) return;
                                handleSelectNotification(
                                  notification.notification_id
                                );
                              }}
                            >
                              <Checkbox
                                disabled={notification.can_clear === 0}
                                checked={selectedNotifications.includes(
                                  notification.notification_id
                                )}
                              />
                            </div>

                            {getIconByType(notification.notification_type)}
                          </div>
                          <div className="flex-1">
                            {notification.account_name && (
                              <p className="text-xs text-muted-foreground font-semibold">
                                {notification.account_name}
                              </p>
                            )}

                            <p className="text-xs font-medium text-primary">
                              {notification.notification_text
                                .trim()
                                .replace(/-$/, "")}
                            </p>

                            <p className="text-xs text-muted-foreground">
                              {formatDistanceToNow(
                                new Date(notification.reference_date)
                              )}{" "}
                              ago
                            </p>
                          </div>
                        </div>
                        {notification.can_clear === 1 && (
                          <button
                            disabled={isClearing}
                            className="p-2 ml-2 bg-muted hover:bg-muted-foreground hover:text-secondary rounded-md disabled:cursor-not-allowed"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleRemoveNotification(
                                notification.notification_id
                              );
                            }}
                            aria-label="Remove notification"
                          >
                            <X className="size-4" />
                          </button>
                        )}
                      </li>
                    ))}
              </ul>
            </ScrollArea>
          </div>
        )}

        {!loading && sortedNotifications.length === 0 && (
          <p className="text-sm text-gray-500 mt-4 text-center">
            No notifications
          </p>
        )}
      </div>
    </div>
  );
};

export default NotificationsPage;
