import { TooltipProvider } from "components/ui/tooltip";
import ErrorBoundary from "./ErrorBoundary";
import AppProvider from "context/AppContext";
import ConnectionProvider from "context/ConnectionContext";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { routes } from "routes/routeConfig";

const router = createBrowserRouter(routes);
function App() {
  return (
    <AppProvider>
      <ConnectionProvider>
        <ErrorBoundary>
          <TooltipProvider>
            <RouterProvider router={router} />
          </TooltipProvider>
        </ErrorBoundary>
      </ConnectionProvider>
    </AppProvider>
  );
}

export default App;
