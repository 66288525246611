import { useEffect, useState } from "react";
import {
  CalendarIcon,
  ChevronLeft,
  ChevronRight,
  Clock,
  LayoutPanelTop,
  Plus,
  Rows3,
  Trash,
} from "lucide-react";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "components/ui/select";
import { Button } from "components/ui/button";
import { Calendar } from "components/ui/calendar";
import { useToast } from "components/ui/use-toast";
import { Separator } from "components/ui/separator";
import { ScrollArea } from "components/ui/scroll-area";
import { ToggleGroup, ToggleGroupItem } from "components/ui/toggle-group";
import { Popover, PopoverContent, PopoverTrigger } from "components/ui/popover";
import { deepEqual, getUTCOffset, toStartCase } from "utils/helper-methods";
import { Channel, ScheduleSet } from "types";
import ScheduleItem from "./ScheduleItem";
import { ConnectionType } from ".";
import { cn } from "lib/utils";

const days = Array.from({ length: 31 }, (_, index) =>
  index.toString().padStart(2, "0")
);
const hours = Array.from({ length: 24 }, (_, index) =>
  index.toString().padStart(2, "0")
);
const minutes = Array.from({ length: 60 }, (_, index) =>
  index.toString().padStart(2, "0")
);

export interface Time {
  frequency: string;
  time: string[];
  weekDay?: string;
  monthDate?: string;
  oneTimeDate?: Date | undefined;
}

export interface TimeSet {
  times: Time[];
  channel: Channel | null;
  connection: ConnectionType[];
}

type Props = {
  selectedData: ScheduleSet;
  schedulesSet: ScheduleSet[];
  setSchedulesSet: React.Dispatch<React.SetStateAction<ScheduleSet[]>>;
};

const SchedulesSet = ({
  selectedData,
  schedulesSet,
  setSchedulesSet,
}: Props) => {
  const { toast } = useToast();
  const [view, setView] = useState("list");
  const [time, setTime] = useState("00:00");
  const [weekDay, setWeekDay] = useState("monday");
  const [monthlyDate, setMonthlyDate] = useState("1");
  const [scheduleFrequency, setScheduleFrequency] = useState("daily");
  const [oneTimeDate, setOneTimeDate] = useState<Date | undefined>(undefined);
  const [timesSet, setTimesSet] = useState<TimeSet[]>([]);

  useEffect(() => {
    if (schedulesSet.length === 0) {
      setTimesSet([]);
      return;
    }
    const newTimeSet = transformScheduleToTimeSet();
    setTimesSet(newTimeSet);
    // eslint-disable-next-line
  }, [schedulesSet]);

  const handleTimeChange = (hour: string, minute: string) => {
    const newTime = `${hour}:${minute}`;
    setTime(newTime);
  };

  const handleAddSchedule = () => {
    if (
      !selectedData.selectedChannel ||
      selectedData.selectedConnection.length === 0
    ) {
      toast({
        title: `Channel and connection are required`,
        description:
          "Before you add a schedule you must select a channel and a connection.",
      });
      return;
    }

    let schedule: any = {
      timezone: getUTCOffset(new Date()),
      frequency: scheduleFrequency,
      time,
    };

    if (scheduleFrequency === "weekly") {
      schedule = {
        ...schedule,
        weekDay,
      };
    }
    if (scheduleFrequency === "monthly") {
      schedule = {
        ...schedule,
        monthlyDate,
      };
    }
    if (scheduleFrequency === "one_time") {
      if (!oneTimeDate) {
        toast({
          title: "Date is missing!",
          description: "For one time schedule date is required",
        });

        return;
      } else {
        schedule = {
          ...schedule,
          oneTimeDate,
        };
      }
    }

    if (
      schedulesSet.some((set) =>
        deepEqual(set, { ...selectedData, timing: schedule })
      )
    ) {
      toast({
        title: "Schedule already exist",
        description: "Check your selection for a duplicate.",
      });
    } else {
      setSchedulesSet((ps) => [...ps, { ...selectedData, timing: schedule }]);
    }
  };

  const removeScheduleFromSet = (dataToRemove: ScheduleSet) => {
    setSchedulesSet((set) =>
      set.filter((item) => {
        return !(
          item.selectedChannel?.campaign_id ===
            dataToRemove.selectedChannel!.campaign_id &&
          item.selectedConnection[0]?.id ===
            dataToRemove.selectedConnection[0]!.id &&
          item.timing!.frequency === dataToRemove.timing!.frequency &&
          item.timing?.weekDay === dataToRemove.timing?.weekDay &&
          item.timing!.time === dataToRemove.timing!.time &&
          item.timing?.monthlyDate === dataToRemove.timing?.monthlyDate &&
          (item.timing?.oneTimeDate === dataToRemove.timing?.oneTimeDate ||
            (dataToRemove.timing?.oneTimeDate === undefined &&
              item.timing?.oneTimeDate === undefined))
        );
      })
    );
  };

  function transformScheduleToTimeSet(): TimeSet[] {
    const timeSetMap = new Map<string, TimeSet>();

    schedulesSet.forEach((schedule) => {
      const { selectedChannel, selectedConnection, timing } = schedule;
      const key = `${selectedChannel?.channel_id}-${selectedConnection[0]?.id}`;

      if (!timeSetMap.has(key)) {
        timeSetMap.set(key, {
          times: [],
          channel: selectedChannel || null,
          connection: selectedConnection,
        });
      }

      const timeSetEntry = timeSetMap.get(key);

      const matchesTiming = (timeEntry: Time) => {
        switch (timing?.frequency) {
          case "daily":
            return timeEntry.frequency === "daily";
          case "weekly":
            return (
              timeEntry.frequency === "weekly" &&
              timeEntry.weekDay === timing.weekDay
            );
          case "monthly":
            return (
              timeEntry.frequency === "monthly" &&
              timeEntry.monthDate === timing.monthlyDate
            );
          case "one_time":
            return (
              timeEntry.frequency === "one_time" &&
              (timeEntry.oneTimeDate?.getTime() ===
                timing.oneTimeDate?.getTime() ||
                timeEntry.oneTimeDate === timing.oneTimeDate)
            );
          default:
            return false;
        }
      };

      let timeEntry = timeSetEntry?.times.find(matchesTiming);

      const createTiming = (): Time => {
        switch (timing?.frequency) {
          case "daily":
            return { frequency: timing.frequency, time: [timing?.time] };
          case "weekly":
            return {
              frequency: timing.frequency,
              time: [timing?.time],
              weekDay: timing?.weekDay,
            };
          case "monthly":
            return {
              frequency: timing.frequency,
              time: [timing?.time],
              monthDate: timing?.monthlyDate,
            };
          case "one_time":
            return {
              frequency: timing.frequency,
              time: [timing?.time],
              oneTimeDate: timing?.oneTimeDate,
            };
          default:
            return {
              frequency: timing!.frequency,
              time: [timing!.time],
              weekDay: timing!.weekDay || "",
              monthDate: timing!.monthlyDate || "",
              oneTimeDate: timing!.oneTimeDate,
            };
        }
      };

      if (!timeEntry) {
        timeEntry = createTiming();

        timeSetEntry?.times.push(timeEntry);
      } else {
        if (!timeEntry.time.includes(timing!.time)) {
          timeEntry.time.push(timing!.time);
        }
      }
    });

    return Array.from(timeSetMap.values());
  }

  const convertTimeForList = (schedule: ScheduleSet) => {
    const timing = schedule.timing;
    if (timing?.frequency === "daily") {
      return "Daily - " + timing.time;
    }

    if (timing?.frequency === "weekly") {
      return "Weekly - " + toStartCase(timing?.weekDay) + " - " + timing.time;
    }

    if (timing?.frequency === "monthly") {
      return "Monthly - " + timing?.monthlyDate + " - " + timing.time;
    }

    if (timing?.frequency === "one_time") {
      return (
        "One time - " +
        timing?.oneTimeDate?.toDateString() +
        " - " +
        timing.time
      );
    }
  };

  return (
    <>
      <div className="flex flex-col xl:flex-row items-center justify-between xl:px-4 py-2">
        <div className="flex-1 w-full flex items-center xl:px-0 xl:pb-0 pb-2 px-4 justify-between xl:justify-start">
          <div className="flex items-center">
            <Clock size={18} className={cn("text-primary me-2")} />
            <h1 className="inline-flex items-center font-bold">Schedules</h1>
          </div>

          <div className="flex items-center space-x-4">
            <Select
              value={scheduleFrequency}
              onValueChange={setScheduleFrequency}
            >
              <SelectTrigger className="w-28 ms-2">
                <SelectValue placeholder="Frequency" />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  <SelectLabel>Frequency</SelectLabel>

                  <SelectItem value={"daily"}>Daily</SelectItem>
                  <SelectItem value={"weekly"} disabled>
                    Weekly
                  </SelectItem>
                  <SelectItem value={"monthly"} disabled>
                    Monthly
                  </SelectItem>
                  <SelectItem value={"one_time"} disabled>
                    One-Time
                  </SelectItem>
                </SelectGroup>
              </SelectContent>
            </Select>
          </div>
        </div>

        <Separator className="xl:hidden" />

        <div className="flex space-x-2 xl:mt-0 mt-2">
          {scheduleFrequency === "weekly" && (
            <div className="flex items-center">
              <span className="me-2 text-sm font-medium">Day: </span>
              <Select value={weekDay} onValueChange={setWeekDay}>
                <SelectTrigger className="">
                  <SelectValue placeholder="Day" />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    <SelectLabel>Week Day</SelectLabel>

                    <SelectItem value={"monday"}>Monday</SelectItem>
                    <SelectItem value={"tuesday"}>Tuesday</SelectItem>
                    <SelectItem value={"wednesday"}>Wednesday</SelectItem>
                    <SelectItem value={"thursday"}>Thursday</SelectItem>
                    <SelectItem value={"friday"}>Friday</SelectItem>
                    <SelectItem value={"saturday"}>Saturday</SelectItem>
                    <SelectItem value={"sunday"}>Sunday</SelectItem>
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>
          )}

          {scheduleFrequency === "monthly" && (
            <div className="flex items-center">
              <span className="me-2 text-sm font-medium">Date: </span>
              <Select value={monthlyDate} onValueChange={setMonthlyDate}>
                <SelectTrigger className="">
                  <SelectValue placeholder="Day" />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    <SelectLabel>Days</SelectLabel>
                    {days.map((day) => (
                      <SelectItem key={day} value={String(parseInt(day) + 1)}>
                        {parseInt(day) + 1}
                      </SelectItem>
                    ))}
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>
          )}

          {scheduleFrequency === "one_time" && (
            <div className="flex items-center">
              <Popover>
                <PopoverTrigger asChild>
                  <Button
                    size="sm"
                    variant={"outline"}
                    className={cn("w-[140px] pl-3 text-left font-normal")}
                  >
                    {oneTimeDate ? (
                      oneTimeDate.toLocaleDateString()
                    ) : (
                      <span>Pick a date</span>
                    )}

                    <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                  </Button>
                </PopoverTrigger>
                <PopoverContent className="w-auto p-0" align="start">
                  <Calendar
                    mode="single"
                    selected={oneTimeDate}
                    onSelect={(date) => setOneTimeDate(date)}
                    disabled={(date) =>
                      date < new Date(new Date().setDate(new Date().getDate()))
                    }
                  />
                </PopoverContent>
              </Popover>
            </div>
          )}

          <div className="flex items-center">
            <span className="me-2 text-sm font-medium">Hrs </span>
            <Select
              value={time.split(":")[0]}
              onValueChange={(hour) =>
                handleTimeChange(hour, time.split(":")[1])
              }
            >
              <SelectTrigger>
                <SelectValue placeholder="Hours" />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  <SelectLabel>Hours</SelectLabel>
                  {hours.map((hour) => (
                    <SelectItem key={hour} value={hour}>
                      {hour}
                    </SelectItem>
                  ))}
                </SelectGroup>
              </SelectContent>
            </Select>
          </div>

          <div className="flex items-center">
            <span className="me-2 text-sm font-medium">Min </span>
            <Select
              value={time.split(":")[1]}
              onValueChange={(minute) =>
                handleTimeChange(time.split(":")[0], minute)
              }
            >
              <SelectTrigger>
                <SelectValue placeholder="Minutes" />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  <SelectLabel>Minutes</SelectLabel>
                  {minutes.map((minute) => (
                    <SelectItem key={minute} value={minute}>
                      {minute}
                    </SelectItem>
                  ))}
                </SelectGroup>
              </SelectContent>
            </Select>
          </div>
          <div>
            <Button onClick={handleAddSchedule} size="sm" variant="default">
              Add
              <Plus size={16} className="ms-2" />
            </Button>
          </div>
        </div>
      </div>

      <Separator className="xl:block hidden" />

      {schedulesSet?.length > 0 && (
        <div className="mt-2">
          <ToggleGroup
            type="single"
            value={view}
            size="sm"
            onValueChange={setView}
          >
            <ToggleGroupItem value="grid" aria-label="Toggle Grid">
              <LayoutPanelTop className="h-4 w-4" />
            </ToggleGroupItem>

            <ToggleGroupItem value="list" aria-label="Toggle List">
              <Rows3 className="h-4 w-4" />
            </ToggleGroupItem>
          </ToggleGroup>
        </div>
      )}

      <ScrollArea className="h-[calc(100vh-234px)] lg:h-[calc(100vh-184px)] xl:h-[calc(100vh-144px)] py-4">
        <div className="flex flex-col gap-2 p-4 pt-0">
          {schedulesSet.length > 0 ? (
            view === "grid" ? (
              timesSet.map((timeSet, index) => (
                <ScheduleItem item={timeSet} key={index} />
              ))
            ) : (
              schedulesSet.reverse().map((schedule, index) => (
                <div
                  key={index}
                  className={cn(
                    "flex flex-col items-start gap-2 rounded-lg border p-2 text-left transition-all hover:bg-accent cursor-default"
                  )}
                >
                  <div className="flex w-full flex-col gap-1">
                    <div className="flex items-start">
                      <div className="flex flex-col justify-center gap-1">
                        <div className="font-semibold text-xs text-primary">
                          {schedule.selectedChannel?.channel_name} (
                          {convertTimeForList(schedule)})
                        </div>
                        <div className="text-xs text-muted-foreground">
                          {schedule.selectedConnection[0]?.connection_name}
                        </div>
                      </div>
                      <div
                        className={cn(
                          "ml-auto text-xs",
                          "text-muted-foreground"
                        )}
                      >
                        <Button
                          onClick={() => removeScheduleFromSet(schedule)}
                          size="icon"
                          variant="ghost"
                        >
                          <Trash size={18} />
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            )
          ) : (
            <div>
              {!selectedData?.selectedChannel ? (
                <div className="h-[500px] text-center flex items-center justify-between">
                  <h1 className="flex items-center">
                    <span className="text-5xl animate-pulse">
                      <ChevronLeft size={42} />
                    </span>
                  </h1>
                  <h1 className="text-2xl font-medium flex items-center">
                    Select channel
                  </h1>
                  <div></div>
                </div>
              ) : selectedData.selectedConnection.length === 0 ? (
                <div className="h-[500px] text-center flex items-center justify-between">
                  <div></div>
                  <h1 className="text-2xl font-medium flex items-center">
                    Select connection
                  </h1>
                  <h1 className="text-2xl font-medium flex items-center">
                    <span className="text-5xl animate-pulse">
                      <ChevronRight size={42} />
                    </span>
                  </h1>
                </div>
              ) : (
                <div className="h-[500px] text-center flex items-center justify-between">
                  <div></div>
                  <h1 className="text-2xl font-medium flex items-center">
                    Start adding schedules
                  </h1>
                  <div className="text-2xl font-medium flex items-center"></div>
                </div>
              )}
            </div>
          )}
        </div>
      </ScrollArea>
    </>
  );
};

export default SchedulesSet;
