import * as React from "react";
import {
  ColumnDef,
  ColumnFiltersState,
  Row,
  SortingState,
  VisibilityState,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Button } from "components/ui/button";
// import { Checkbox } from "components/ui/checkbox";
import { MoreHorizontal } from "lucide-react";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "components/ui/alert-dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "components/ui/dropdown-menu";
import { useToast } from "components/ui/use-toast";
import CommonTable from "components/Shared/CommonTable";
import { DataTableColumnHeader } from "components/Shared/AdminTableHeader";
import AddGroupScheduleDialog from "./AddHintScheduleDialog";
import { formatDateTime } from "utils/helper-methods";
import { HintSchedule, HintSubject } from "types";
import apiClient from "api";

type Props = {
  loading: boolean;
  data: HintSchedule[];
  selectedSubject: HintSubject | null;
  fetchData: () => void;
};

export function DataTableHintSchedules({
  data,
  loading,
  selectedSubject,
  fetchData,
}: Props) {
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );
  const [columnVisibility, setColumnVisibility] =
    React.useState<VisibilityState>({});
  const [rowSelection, setRowSelection] = React.useState({});

  const columns: ColumnDef<HintSchedule>[] = [
    // {
    //   id: "select",
    //   header: ({ table }) => (
    //     <Checkbox
    //       checked={
    //         table.getIsAllPageRowsSelected() ||
    //         (table.getIsSomePageRowsSelected() && "indeterminate")
    //       }
    //       onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
    //       aria-label="Select all"
    //     />
    //   ),
    //   cell: ({ row }) => (
    //     <Checkbox
    //       checked={row.getIsSelected()}
    //       onCheckedChange={(value) => row.toggleSelected(!!value)}
    //       aria-label="Select row"
    //     />
    //   ),
    //   enableSorting: false,
    //   enableHiding: false,
    // },
    {
      accessorKey: "hint_schedule_id",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Schedule ID" />
      ),
      cell: ({ row }) => (
        <div className="w-28">{row.original.hint_schedule_id}</div>
      ),
    },
    {
      accessorKey: "hint_id",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Hint ID" />
      ),
      cell: ({ row }) => <div className="w-28">{row.original.hint_id}</div>,
    },
    {
      accessorKey: "poster_name",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Poster name" />
      ),
      cell: ({ row }) => <div className="w-28">{row.original.poster_name}</div>,
    },
    {
      accessorKey: "poster_description",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Poster type" />
      ),
      cell: ({ row }) => (
        <div className="w-32">{row.original.poster_description}</div>
      ),
    },
    {
      accessorKey: "target_name",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Target name" />
      ),
      cell: ({ row }) => <div className="w-28">{row.original.target_name}</div>,
    },
    {
      accessorKey: "target_type_name",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Target type" />
      ),
      cell: ({ row }) => (
        <div className="w-32">{row.original.target_type_name}</div>
      ),
    },
    {
      accessorKey: "earliest_timestamp",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Earliest timestamp" />
      ),
      cell: ({ row }) => (
        <div className="w-40">
          {row.original.earliest_timestamp
            ? formatDateTime(
                new Date(row.original.earliest_timestamp * 1000),
                true
              )
            : "-"}
        </div>
      ),
    },
    {
      accessorKey: "hours_between",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Hours between" />
      ),
      cell: ({ row }) => (
        <div className="w-28">{row.original.hours_between}</div>
      ),
    },
    {
      id: "actions",
      header: "Actions",
      enableHiding: false,
      cell: ({ row }) => (
        <RowAction
          row={row}
          selectedSubject={selectedSubject}
          fetchData={fetchData}
        />
      ),
    },
  ];

  const table = useReactTable({
    data,
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
    },
  });

  return (
    <CommonTable
      table={table}
      name="Hint schedules"
      columns={columns}
      data={data}
      loading={loading}
    />
  );
}

interface DataTableRowActionsProps<TData> {
  row: Row<TData>;
  selectedSubject: HintSubject | null;
  fetchData: () => void;
}

const RowAction = ({
  row,
  selectedSubject,
  fetchData,
}: DataTableRowActionsProps<HintSchedule>) => {
  const { toast } = useToast();

  const [openEdit, setOpenEdit] = React.useState(false);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [isDeleting, setIsDeleting] = React.useState(false);

  const hint = row.original;

  const deleteGroupSchedule = async () => {
    setIsDeleting(true);
    toast({
      description: "Deleting hint schedule...",
      variant: "destructive",
    });

    try {
      await apiClient.delete("/admin_creative_delete_hint_schedule", {
        headers: {
          request: JSON.stringify({
            hint_schedule_id: hint.hint_schedule_id,
          }),
        },
      });
      fetchData();
      setIsDeleting(false);
      toast({
        description: "Hint schedule deleted successfully.",
        variant: "destructive",
      });
    } catch (error) {
      setIsDeleting(false);
      toast({
        description: "Hint schedule deletion failed.",
        variant: "destructive",
      });
    }
  };

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" className="h-6 w-6 p-0">
            <span className="sr-only">Open menu</span>
            <MoreHorizontal className="h-3 w-3" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <DropdownMenuLabel>Actions</DropdownMenuLabel>
          <DropdownMenuItem onClick={() => setOpenEdit(true)}>
            Edit
          </DropdownMenuItem>
          <DropdownMenuSeparator />
          <DropdownMenuItem
            disabled={isDeleting}
            onClick={() => setOpenAlert(true)}
            className="text-red-600"
          >
            Delete
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>

      <AlertDialog open={openAlert} onOpenChange={setOpenAlert}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Are you sure?</AlertDialogTitle>
            <AlertDialogDescription>
              This action cannot be undone. This will permanently delete your
              hint schedule.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction onClick={deleteGroupSchedule}>
              Confirm
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      <AddGroupScheduleDialog
        editData={hint}
        open={openEdit}
        selectedSubject={selectedSubject}
        fetchData={fetchData}
        onOpenChange={setOpenEdit}
      />
    </>
  );
};
