import React, { useEffect, useState } from "react";
import { RefreshCw } from "lucide-react";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "components/ui/dialog";
import { enGB } from "date-fns/locale";
import { Label } from "components/ui/label";
import { Button } from "components/ui/button";
import { Textarea } from "components/ui/textarea";
import { useToast } from "components/ui/use-toast";
import { Input } from "components/ui/input";
import { DateTimePicker } from "components/ui/datetime-picker";
import { sanitizeString } from "utils/helper-methods";
import { useAppState } from "context/AppContext";
import { Podcast } from "types";
import apiClient from "api";

type Props = {
  open: boolean;
  editData?: Podcast | null;
  selectedPodcast: Podcast | null;
  fetchData: () => void;
  onOpenChange: (value: boolean) => void;
};

const AddPodcastDialog = ({
  open,
  editData,
  fetchData,
  onOpenChange,
}: Props) => {
  const { state } = useAppState();
  const { account } = state.admin;
  const { toast } = useToast();
  const [submitting, setSubmitting] = useState(false);

  const [rssUrl, setRssUrl] = useState("");
  const [podcastUrl, setPodcastUrl] = useState("");
  const [podcastTitle, setPodcastTitle] = useState("");
  const [description, setDescription] = useState("");
  const [hoursBetween, setHoursBetween] = useState(0);
  const [podcastTimestamp, setPodcastTimestamp] = useState<Date | undefined>(
    undefined
  );

  useEffect(() => {
    if (!editData) return;
    setDescription(editData.description);
    setPodcastTitle(editData.podcast_title);
    setPodcastUrl(editData.podcast_url);
    setRssUrl(editData.rss_url);
    setHoursBetween(editData.hours_between);
    setPodcastTimestamp(
      editData.start_timestamp
        ? new Date(editData.start_timestamp * 1000)
        : undefined
    );
  }, [editData]);

  useEffect(() => {
    if (editData) return;
    if (!open) {
      setDescription("");
      setPodcastTitle("");
      setPodcastUrl("");
      setRssUrl("");
      setHoursBetween(0);
      setPodcastTimestamp(undefined);
      return;
    }

    // eslint-disable-next-line
  }, [open]);

  const addPodcast = async (evt: React.FormEvent) => {
    evt.preventDefault();
    setSubmitting(true);
    if (Boolean(editData)) {
      try {
        await apiClient.post(
          "/admin_creative_update_podcast",
          {},
          {
            headers: {
              request: JSON.stringify({
                podcast_id: editData?.podcast_id,
                podcast_title: sanitizeString(podcastTitle),
                podcast_start_timestamp: podcastTimestamp
                  ? Math.floor(podcastTimestamp.getTime() / 1000)
                  : 0,
                hours_between: hoursBetween,
                podcast_url: podcastUrl,
                rss_url: rssUrl,
                description: sanitizeString(description),
              }),
            },
          }
        );
        onOpenChange(false);
        fetchData();
      } catch (error) {
        console.log("Error:", error);
      } finally {
        setSubmitting(false);
      }
    } else {
      try {
        const { data } = await apiClient.post(
          "/admin_creative_add_podcast",
          {},
          {
            headers: {
              request: JSON.stringify({
                creative_id: account?.account_id,
                podcast_title: sanitizeString(podcastTitle),
                podcast_start_timestamp: podcastTimestamp
                  ? Math.floor(podcastTimestamp.getTime() / 1000)
                  : 0,
                hours_between: hoursBetween,
                podcast_url: podcastUrl,
                rss_url: rssUrl,
                description: sanitizeString(description),
              }),
            },
          }
        );

        if (data?.message[0].result === -1) {
          toast({
            title: "Duplicate content",
            description: "Podcast type already exists.",
          });
        } else {
          onOpenChange(false);
          fetchData();
        }
      } catch (error) {
        console.log("Error:", error);
      } finally {
        setSubmitting(false);
      }
    }
  };

  return (
    <>
      <Dialog open={open} onOpenChange={onOpenChange}>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>{!!editData ? "Edit" : "Add"} podcast</DialogTitle>
          </DialogHeader>

          <form className="grid gap-4" onSubmit={addPodcast}>
            <div className="grid gap-2">
              <Label htmlFor="podcast-title">Podcast title</Label>
              <div className="relative">
                <Input
                  id="podcast-title"
                  required
                  value={podcastTitle}
                  onChange={(evt) => {
                    const val = evt.target.value;
                    setPodcastTitle(val);
                  }}
                />
              </div>
            </div>

            <div className="grid gap-2">
              <Label htmlFor="podcast-timestamp">Podcast start timestamp</Label>

              <DateTimePicker
                locale={enGB}
                value={podcastTimestamp}
                onChange={setPodcastTimestamp}
              />
            </div>

            <div className="grid gap-2">
              <Label htmlFor="podcast-url">Podcast URL</Label>
              <div className="relative">
                <Input
                  id="podcast-url"
                  required
                  value={podcastUrl}
                  onChange={(evt) => {
                    const val = evt.target.value;
                    setPodcastUrl(val);
                  }}
                />
              </div>
            </div>
            <div className="grid gap-2">
              <Label htmlFor="description">Description</Label>
              <div className="relative">
                <Textarea
                  required
                  id="description"
                  value={description}
                  onChange={(evt) => {
                    setDescription(evt.target.value);
                  }}
                  rows={3}
                  className="resize-none"
                />
              </div>
            </div>

            <div className="grid gap-2">
              <Label htmlFor="rss-url">RSS URL</Label>
              <div className="relative">
                <Input
                  id="rss-url"
                  required
                  value={rssUrl}
                  onChange={(evt) => {
                    const val = evt.target.value;
                    setRssUrl(val);
                  }}
                />
              </div>
            </div>

            <div className="grid gap-2">
              <Label htmlFor="hours-between">Hours between</Label>
              <div className="relative">
                <Input
                  id="hours-between"
                  required
                  type="number"
                  value={hoursBetween}
                  onChange={(evt) => {
                    const val = parseInt(evt.target.value);
                    if (val < 0) return;
                    setHoursBetween(val);
                  }}
                />
              </div>
            </div>

            <DialogFooter>
              <DialogClose asChild>
                <Button
                  disabled={submitting}
                  type="button"
                  size="sm"
                  variant="outline"
                >
                  Cancel
                </Button>
              </DialogClose>
              <Button disabled={submitting} type="submit" size="sm">
                {submitting && (
                  <RefreshCw className="size-4 animate-spin mr-2" />
                )}{" "}
                Save
              </Button>
            </DialogFooter>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddPodcastDialog;
