import { useState } from "react";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "components/ui/dialog";
import { Button } from "components/ui/button";
import { Fullscreen } from "lucide-react";

type Props = {
  imageUrl: string;
  altText: string;
  title?: string;
  description?: string;
  className: string;
};

const ImageModalViewer = ({
  title,
  altText,
  imageUrl,
  className,
  description,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const downloadImage = (url: string) => {
    window.open(url, "_blank");
  };

  return (
    <div>
      {/* Thumbnail or button to open modal */}

      <div className={`${className} relative overflow-hidden group`}>
        <div className="group-hover:flex hidden absolute w-full h-full top-0 left-0 items-center justify-center bg-[rgba(0,0,0,0.2)]">
          <Button size="icon" variant="default" onClick={handleOpen}>
            <Fullscreen className="size-4" />
          </Button>
        </div>
        <img src={imageUrl} alt={altText} className="w-full h-auto" />
      </div>

      {/* Modal to view the image in larger size */}
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogContent className="max-w-3xl">
          <DialogHeader>
            <DialogTitle>{title ?? altText}</DialogTitle>
            {description && (
              <DialogDescription>{description}</DialogDescription>
            )}
          </DialogHeader>
          <img src={imageUrl} alt={altText} className="w-full h-auto rounded" />

          <DialogFooter>
            <Button variant="secondary" onClick={() => downloadImage(imageUrl)}>
              Download
            </Button>
            <DialogClose asChild>
              <Button type="submit">Close</Button>
            </DialogClose>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ImageModalViewer;

// Usage Example
// <ImageModalViewer imageUrl="https://example.com/your-image.jpg" altText="Your image description" />
