import React, { memo, useCallback, useEffect } from "react";
import { IResolveParams, objectType } from "reactjs-social-login";

interface Props {
  client_id: string;
  className?: string;
  redirect_uri: string;
  state?: string;
  fields?: string;
  scope?: string;
  children?: React.ReactNode;
  isOnlyGetCode?: boolean;
  isOnlyGetToken?: boolean;
  onLoginStart?: () => void;
  onLogoutSuccess?: () => void;
  onReject: (reject: string | objectType) => void;
  onResolve: ({ provider, data }: IResolveParams) => void;
}

export const LoginSocialTwitter = ({
  client_id,
  className = "",
  redirect_uri,
  children,
  state = "state",
  scope = "users.read%20tweet.read",
  isOnlyGetCode = false,
  isOnlyGetToken = false,
  onLoginStart,
  onReject,
  onResolve,
}: Props) => {
  useEffect(() => {
    const popupWindowURL = new URL(window.location.href);
    const code = popupWindowURL.searchParams.get("code");
    const state = popupWindowURL.searchParams.get("state");
    if (state?.includes("_twitter") && code) {
      localStorage.setItem("twitter", code);
      window.close();
    }
  }, []);

  const clearTwitterCookies = () => {
    const cookies = document.cookie.split(";");
    for (let cookie of cookies) {
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      if (name.trim().startsWith("auth_token")) {
        document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;domain=.twitter.com`;
      }
    }
  };

  const getAccessToken = useCallback(
    async (code: string) => {
      if (isOnlyGetCode) onResolve({ provider: "twitter", data: { code } });
    },
    [onResolve, isOnlyGetCode]
  );

  const handlePostMessage = useCallback(
    async ({ type, code, provider }: objectType) =>
      type === "code" && provider === "twitter" && code && getAccessToken(code),
    [getAccessToken]
  );

  const onChangeLocalStorage = useCallback(() => {
    window.removeEventListener("storage", onChangeLocalStorage, false);
    const code = localStorage.getItem("twitter");
    if (code) {
      handlePostMessage({ provider: "twitter", type: "code", code });
      localStorage.removeItem("twitter");
    }
  }, [handlePostMessage]);

  const onLogin = useCallback(async () => {
    clearTwitterCookies();
    onLoginStart && onLoginStart();
    window.addEventListener("storage", onChangeLocalStorage, false);
    const TWITTER_URL = "https://x.com";
    // const code_challenge = "y_SfRG4BmOES02uqWeIkIgLQAlTBggyf_G7uKT51ku8";
    // const code_challenge_method = "S256";

    const oauthUrl = `${TWITTER_URL}/i/oauth2/authorize?response_type=code&client_id=${client_id}&redirect_uri=${redirect_uri}&scope=${scope}&state=${
      state + "_twitter"
    }&code_challenge=challenge&code_challenge_method=plain`;
    const width = 450;
    const height = 730;
    const left = window.screen.width / 2 - width / 2;
    const top = window.screen.height / 2 - height / 2;

    // const qs = new URLSearchParams(options).toString();

    window.open(
      oauthUrl,
      "twitter",
      "menubar=no,location=no,resizable=no,scrollbars=no,status=no, width=" +
        width +
        ", height=" +
        height +
        ", top=" +
        top +
        ", left=" +
        left
    );
  }, [
    scope,
    state,
    client_id,
    onLoginStart,
    redirect_uri,
    onChangeLocalStorage,
  ]);

  return (
    <div className={className} onClick={onLogin}>
      {children}
    </div>
  );
};

export default memo(LoginSocialTwitter);
