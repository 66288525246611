import { useEffect, useState } from "react";
import { DotsHorizontalIcon } from "@radix-ui/react-icons";
import { Row } from "@tanstack/react-table";

import { Button } from "components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "components/ui/dropdown-menu";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "components/ui/alert-dialog";
import { useToast } from "components/ui/use-toast";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "components/ui/dialog";
import { Label } from "components/ui/label";
import { Input } from "components/ui/input";
import { useConnection } from "context/ConnectionContext";
import { Connection, Schedule } from "types";
import { getDeleteEndpoint } from "utils/helper-methods";
import { useAppState } from "context/AppContext";
import apiClient from "api";

interface DataTableRowActionsProps<TData> {
  row: Row<TData>;
}

export function DataTableRowActions({
  row,
}: DataTableRowActionsProps<Connection>) {
  const {
    setIsOpen,
    setIsReconnecting,
    setConnectionData,
    setConnectionState,
  } = useConnection();
  const { state, dispatch } = useAppState();
  const { account_token } = state.user;
  const { toast } = useToast();
  const [openEdit, setOpenEdit] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [connectionName, setConnectionName] = useState("");
  const [loadingSchedules, setLoadingSchedules] = useState(false);
  const [listOfSchedules, setListOfSchedules] = useState<string[]>([]);

  const connection = row.original;

  useEffect(() => {
    if (!connection) return;
    setConnectionName(connection.connection_name);
    // eslint-disable-next-line
  }, [connection]);

  useEffect(() => {
    if (!openAlert) return;
    getSchedule();
    // eslint-disable-next-line
  }, [openAlert]);

  const getSchedule = async () => {
    setLoadingSchedules(true);
    try {
      const { data } = await apiClient.get("/get_scheduled_linkage_dashboard", {
        headers: {
          request: JSON.stringify({ connection_id: connection?.id }),
          token: account_token,
        },
      });
      const scheduleData: Schedule[] = data?.message || [];
      setListOfSchedules(scheduleData.map((s) => s.schedule_name));
      setLoadingSchedules(false);
    } catch (error) {
      setLoadingSchedules(false);
    }
  };

  const deleteConnection = async () => {
    const endpoint = getDeleteEndpoint(connection.channel);
    setIsDeleting(true);

    try {
      await apiClient.delete(endpoint, {
        headers: {
          request: JSON.stringify({ id: connection?.id }),
          token: account_token,
        },
      });

      if (listOfSchedules.length > 0) {
        await deleteSchedule();
      }
      setIsDeleting(false);
      toast({
        description: "Delete successful!",
      });
      dispatch({ type: "SET_REFRESH", payload: "connections" });
    } catch (error) {
      setIsDeleting(false);
      console.log(error);
    }
  };

  const deleteSchedule = async () => {
    toast({
      description: "Deleting connection...",
    });
    try {
      await apiClient.delete("/delete_schedule_by_connection", {
        headers: {
          request: JSON.stringify({
            connection_id: connection.id,
          }),
          token: account_token,
        },
      });
      setIsDeleting(false);
    } catch (error) {
      setIsDeleting(false);
    }
  };

  const handleSave = async () => {
    toast({ description: "Updating name..." });
    try {
      await apiClient.post(
        "/add_connection_details",
        {},
        {
          headers: {
            token: account_token,
            request: JSON.stringify({
              connection_name: connectionName,
              id: row.original.id,
              source_id: 0,
              posts: 0,
            }),
          },
        }
      );

      toast({ description: "Name update successful." });
      dispatch({ type: "SET_REFRESH", payload: "connections" });
    } catch (error) {
      toast({ description: "Name update failed." });
      console.error("error", error);
    }
  };

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            variant="ghost"
            className="flex h-8 w-8 p-0 data-[state=open]:bg-muted"
          >
            <DotsHorizontalIcon className="h-4 w-4" />
            <span className="sr-only">Open menu</span>
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end" className="w-[160px]">
          <DropdownMenuLabel>Actions</DropdownMenuLabel>
          <DropdownMenuItem
            disabled={isDeleting}
            onClick={() => setOpenEdit(true)}
          >
            Edit
          </DropdownMenuItem>

          {/* {connection.connection_status === 0 && ( */}
          <DropdownMenuItem
            disabled={isDeleting}
            onClick={() => {
              setIsReconnecting(true);
              setConnectionData(connection);
              setConnectionState(connection.state);
              setIsOpen(true);
            }}
          >
            Reconnect
          </DropdownMenuItem>
          {/* )} */}

          <DropdownMenuSeparator />
          <DropdownMenuItem
            disabled={isDeleting}
            onClick={() => setOpenAlert(true)}
            className="text-red-600"
          >
            Delete
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>

      <Dialog open={openEdit} onOpenChange={setOpenEdit}>
        <DialogContent className="sm:max-w-md">
          <DialogHeader>
            <DialogTitle>Edit connection name</DialogTitle>
          </DialogHeader>
          <div className="flex items-center space-x-2">
            <div className="grid flex-1 gap-2">
              <Label htmlFor="connection-name" className="sr-only">
                Connection name
              </Label>
              <Input
                id="connection-name"
                value={connectionName}
                onChange={(evt) => setConnectionName(evt.target.value)}
              />
            </div>
          </div>
          <DialogFooter className="">
            <DialogClose asChild>
              <Button
                onClick={handleSave}
                disabled={row.original.connection_name === connectionName}
              >
                Save
              </Button>
            </DialogClose>
            <DialogClose asChild>
              <Button type="button" variant="secondary">
                Close
              </Button>
            </DialogClose>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      <AlertDialog open={openAlert} onOpenChange={setOpenAlert}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Are you sure?</AlertDialogTitle>
            <AlertDialogDescription>
              This action cannot be undone. This will permanently delete your
              connection and all associated schedules.
              {loadingSchedules ? (
                <span className="mt-2 block">Fetching schedules...</span>
              ) : listOfSchedules.length > 0 ? (
                <>
                  <span className="mt-2 block">Associated schedules.</span>
                  <span className="mt-2 dark:text-gray-200 block bg-gray-50 dark:bg-neutral-800 rounded-lg p-2">
                    {listOfSchedules.map((name, index) => (
                      <span key={name} className="block">
                        {index + 1}. {name}
                      </span>
                    ))}
                  </span>
                </>
              ) : (
                <span className="mt-2 block">No associated schedules.</span>
              )}
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction
              disabled={loadingSchedules}
              onClick={deleteConnection}
            >
              Confirm
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}
