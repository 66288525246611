import { ConnectionStatusDialog } from "components/Dialogs/ConnectionStatusDialog";
import Header from "components/Header";
import Navigation from "components/Header/Navigation";
// import { Tabs, TabsList, TabsTrigger } from "components/ui/tabs";

const Container = ({
  header = true,
  container,
  navigation = true,
  fullScreen = false,
  children,
}: {
  header?: boolean;
  container?: boolean;
  fullScreen?: boolean;
  navigation?: boolean;
  children: React.ReactNode;
}) => {
  return (
    <div className="relative">
      {header && (
        <header>
          <Header />
        </header>
      )}
      {navigation && (
        <header>
          <Navigation />
        </header>
      )}
      <main
        className={`dark:bg-neutral-950 flex-1 h-screen ${
          fullScreen ? "p-0" : "p-4"
        } ${container ? "container" : "w-full"} `}
      >
        {children}
        <ConnectionStatusDialog />
      </main>
    </div>
  );
};

export default Container;
