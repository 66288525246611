import { useEffect, useState } from "react";
import { RefreshCw } from "lucide-react";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "components/ui/dialog";
import { Label } from "components/ui/label";
import { Button } from "components/ui/button";
import { useAppState } from "context/AppContext";
import { Textarea } from "components/ui/textarea";
import { sanitizeString } from "utils/helper-methods";
import { QuestionSubject } from "types";
import apiClient from "api";

type Props = {
  open: boolean;
  editData?: QuestionSubject | null;
  fetchData: () => void;
  onOpenChange: (value: boolean) => void;
};

const AddQuestionSubjectDialog = ({
  open,
  editData,
  fetchData,
  onOpenChange,
}: Props) => {
  const { state } = useAppState();
  const { account } = state.admin;

  const [questionSubject, setQuestionSubject] = useState("");
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (!editData) return;
    setQuestionSubject(editData.question_subject_prompt);

    // eslint-disable-next-line
  }, [editData]);

  const addQuestionSubject = async (evt: React.FormEvent) => {
    evt.preventDefault();

    setSubmitting(true);
    if (Boolean(editData)) {
      try {
        await apiClient.post(
          "/admin_creative_update_question_subject",
          {},
          {
            headers: {
              request: JSON.stringify({
                creative_id: account?.account_id,
                question_subject_id: editData?.question_subject_id,
                question_subject_prompt: sanitizeString(questionSubject),
              }),
            },
          }
        );
        onOpenChange(false);
        fetchData();
      } catch (error) {
        console.log("Error:", error);
      } finally {
        setSubmitting(false);
      }
    } else {
      try {
        await apiClient.post(
          "/admin_creative_add_question_subject",
          {},
          {
            headers: {
              request: JSON.stringify({
                creative_id: account?.account_id,
                question_subject_prompt: sanitizeString(questionSubject),
              }),
            },
          }
        );
        onOpenChange(false);
        fetchData();
      } catch (error) {
        console.log("Error:", error);
      } finally {
        setSubmitting(false);
      }
    }
  };

  return (
    <>
      <Dialog open={open} onOpenChange={onOpenChange}>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>
              {!!editData ? "Edit" : "Add"} question subject
            </DialogTitle>
          </DialogHeader>

          <form onSubmit={addQuestionSubject} className="grid gap-4">
            <div className="grid gap-2">
              <Label htmlFor="question-subject">Question subject prompt</Label>
              <Textarea
                required
                id="question-subject"
                value={questionSubject}
                rows={4}
                onChange={(evt) => {
                  const val = evt.target.value;
                  setQuestionSubject(val);
                }}
              />
            </div>

            <DialogFooter>
              <DialogClose asChild>
                <Button
                  disabled={submitting}
                  type="button"
                  size="sm"
                  variant="outline"
                >
                  Cancel
                </Button>
              </DialogClose>
              <Button disabled={submitting} type="submit" size="sm">
                {submitting && (
                  <RefreshCw className="size-4 animate-spin mr-2" />
                )}{" "}
                Save
              </Button>
            </DialogFooter>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddQuestionSubjectDialog;
