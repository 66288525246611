import { Bar, BarChart, CartesianGrid, XAxis, YAxis } from "recharts";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "components/ui/card";
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "components/ui/chart";
import { Visitor } from "types";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { convertToYYYYMMDD } from "utils/helper-methods";

const chartConfig = {
  log_volume: {
    label: "Visitor",
    color: "hsl(var(--chart-1))",
  },
} satisfies ChartConfig;

type Props = {
  data: Visitor[];
  loading: boolean;
};

export function Demographic({ data, loading }: Props) {
  const navigate = useNavigate();
  const [chartData, setChartData] = useState<Visitor[]>([]);

  useEffect(() => {
    setChartData(
      data
        .sort(
          (a, b) =>
            new Date(a.log_date).getTime() - new Date(b.log_date).getTime()
        )
        .map((d) => ({
          ...d,
          log_date: new Date(d.log_date).toLocaleDateString(),
        }))
    );
  }, [data]);

  function formatDateRange(): string {
    if (data.length === 0) return "";

    const dates = data.map((entry) => new Date(entry.log_date));
    dates.sort((a, b) => a.getTime() - b.getTime());

    const startDate = dates[0];
    const endDate = dates[dates.length - 1];

    const options: Intl.DateTimeFormatOptions = {
      day: "numeric",
      month: "long",
      year: "numeric",
    };

    const startDateStr = startDate.toLocaleDateString("en-GB", options);
    const endDateStr = endDate.toLocaleDateString("en-GB", options);

    return `${startDateStr} - ${endDateStr}`;
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle>Visitors to the news portal</CardTitle>
        <CardDescription>{formatDateRange()}</CardDescription>
      </CardHeader>
      <CardContent>
        {loading ? (
          <div className="py-20">
            <div className="flex flex-col justify-center items-center">
              <div className="size-8 border-2 border-t-2 border-muted border-t-primary rounded-full animate-spin"></div>
              <span className="text-center text-sm mt-4 text-primary font-medium">
                Loading...
              </span>
            </div>
          </div>
        ) : data.length > 0 ? (
          <ChartContainer config={chartConfig}>
            <BarChart
              accessibilityLayer
              data={chartData}
              onClick={(data) => {
                navigate(
                  `/overview/user-agents?end_date=${convertToYYYYMMDD(
                    data?.activeLabel || ""
                  )}`
                );
              }}
              margin={{
                top: 10,
                right: 30,
                left: 20,
                bottom: 0,
              }}
            >
              <CartesianGrid vertical={false} />
              <XAxis
                dataKey="log_date"
                tickLine={true}
                tickMargin={10}
                axisLine={false}
                tickFormatter={(value) => value}
              />
              {/* YAxis added here */}
              <YAxis />
              <ChartTooltip
                cursor={false}
                content={<ChartTooltipContent indicator="dashed" />}
              />
              <Bar
                dataKey="log_volume"
                fill="var(--color-log_volume)"
                radius={4}
              />
            </BarChart>
          </ChartContainer>
        ) : (
          <div className="py-36">
            <div className="flex flex-col justify-center items-center">
              <span>No data</span>
            </div>
          </div>
        )}
      </CardContent>
    </Card>
  );
}
