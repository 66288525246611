import { memo, useEffect, useState } from "react";
import { columns } from "components/Tables/posts/columns";
import { DataTable } from "components/Tables/posts/data-table";
import { useAppState } from "context/AppContext";
import ScreenWithTitles from "components/Shared/ScreenWithTitles";
import { Post } from "types";
import apiClient from "api";

const Posts = () => {
  const { state, dispatch } = useAppState();
  const { account_token, refresh } = state.user;

  const [loading, setLoading] = useState(false);
  const [posts, setPosts] = useState<Post[]>([]);

  useEffect(() => {
    if (refresh !== "posts") return;
    const timeout = setTimeout(fetchPosts, 100);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line
  }, [refresh]);

  useEffect(() => {
    if (!account_token) return;
    const timeout = setTimeout(fetchPosts, 100);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line
  }, [account_token]);

  const fetchPosts = async () => {
    setLoading(true);

    const request: {
      page_start: number;
      page_size: number;
      id?: number;
      schedule_id?: number;
    } = {
      page_size: 50,
      page_start: 1,
    };

    try {
      const { data }: { data: { message: Post[] } } = await apiClient.get(
        "/get_social_posts",
        {
          headers: {
            token: account_token,
            request: JSON.stringify(request),
          },
        }
      );

      let postsData = data?.message || [];

      if (postsData.length > 0) {
        postsData = postsData.filter((post) => post.article_title !== null);
      }

      setPosts(postsData);
      setLoading(false);
      if (refresh === "posts") dispatch({ type: "SET_REFRESH", payload: "" });
    } catch (error) {
      setLoading(false);
    }
  };
  return (
    <div className="flex flex-col space-y-8 md:py-4">
      <ScreenWithTitles
        title="Posts"
        btnTitle="Add post"
        description={"Here's a list of your posts."}
      />
      <DataTable data={posts} loading={loading} columns={columns} />
    </div>
  );
};

export default memo(Posts);
