import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useAppState } from "context/AppContext";
import { DateRange } from "react-day-picker";
import {
  ColumnDef,
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  SortingState,
  ColumnFiltersState,
  VisibilityState,
} from "@tanstack/react-table";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "components/ui/alert-dialog";
import { format } from "date-fns";
import { CalendarIcon } from "@radix-ui/react-icons";
import { Calendar } from "components/ui/calendar";
import { Button } from "components/ui/button";
import { Input } from "components/ui/input";
import Breadcrumbs from "components/Shared/Breadcrumbs";
import CommonTable from "components/Shared/CommonTable";
import { useToast } from "components/ui/use-toast";
import { Checkbox } from "components/ui/checkbox";
import { Popover, PopoverContent, PopoverTrigger } from "components/ui/popover";
import apiClient from "api";

type UserAgent = {
  user_agent: string;
};

const UserAgents = () => {
  const { toast } = useToast();
  const { state } = useAppState();
  const { account } = state.user;
  const { isAdmin } = state.auth;
  const [searchParams, setSearchParams] = useSearchParams();

  const [loading, setLoading] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [rowSelection, setRowSelection] = useState({});
  const [sorting, setSorting] = useState<SortingState>([]);
  const [logsData, setLogsData] = useState<UserAgent[]>([]);
  const [previousQuery, setPreviousQuery] = useState<string>("");
  const [rangeDate, setRangeDate] = useState<DateRange | undefined>();
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({});

  useEffect(() => {
    // Initialize rangeDate from URL parameters
    const start_date = searchParams.get("start_date");
    const end_date = searchParams.get("end_date");

    if (start_date && end_date) {
      const from = new Date(start_date);
      const to = new Date(end_date);
      setRangeDate({ from, to });
    }
  }, [searchParams]);

  useEffect(() => {
    fetchData();

    // eslint-disable-next-line
  }, [searchParams, account]);

  /**
   *
   * @param requestAll
   */
  const fetchData = async () => {
    const start_date = searchParams.get("start_date");
    const end_date = searchParams.get("end_date");

    setLoading(true);
    try {
      const request: any = {};
      if (end_date) request.date_required = end_date;
      if (end_date && start_date) request.start_date = start_date;

      if (Object.keys(request).length)
        request.creative_id = account?.account_id;

      const { data } = await apiClient.get("/admin_creative_get_user_agents", {
        headers: {
          request: JSON.stringify(request),
        },
      });
      setLogsData(data?.message || []);
    } catch (error) {
      console.error(error);
      toast({ description: "Error fetching data", variant: "destructive" });
    } finally {
      setLoading(false);
    }
  };

  const handleDateRangeChange = (newRangeDate: DateRange | undefined) => {
    setRangeDate(newRangeDate);

    if (newRangeDate?.from && newRangeDate?.to) {
      const start_date = format(newRangeDate.from, "yyyy-MM-dd");
      const end_date = format(newRangeDate.to, "yyyy-MM-dd");

      setSearchParams({ start_date, end_date });
    } else if (newRangeDate?.to) {
      const end_date = format(newRangeDate.to, "yyyy-MM-dd");
      setSearchParams({ end_date });
    }
  };

  const columns: ColumnDef<UserAgent>[] = [
    {
      id: "select",
      header: ({ table }) => (
        <div className="w-20">
          <Checkbox
            checked={
              table.getIsAllPageRowsSelected() ||
              (table.getIsSomePageRowsSelected() && "indeterminate")
            }
            onCheckedChange={(value) =>
              table.toggleAllPageRowsSelected(!!value)
            }
            aria-label="Select all"
          />
        </div>
      ),
      cell: ({ row }) => (
        <Checkbox
          checked={row.getIsSelected()}
          onCheckedChange={(value) => row.toggleSelected(!!value)}
          aria-label="Select row"
        />
      ),
      enableSorting: false,
      enableHiding: false,
    },
    {
      accessorKey: "user_agent",
      header: "User agent",
      cell: ({ row }) => (
        <div className="">{row.getValue("user_agent") || "Null"}</div>
      ),
    },
  ];

  const table = useReactTable({
    data: logsData,
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
    },
  });

  const handleDelete = async () => {
    const selectedItems = table
      .getSelectedRowModel()
      .flatRows.map((row) => row.original);

    try {
      await Promise.all(
        selectedItems.map(async (item) => {
          await apiClient.delete("/admin_creative_delete_user_agent", {
            headers: {
              request: JSON.stringify(item),
            },
          });
        })
      );

      // Use browser notifications
      if (Notification.permission === "granted") {
        new Notification("Items deleted successfully");
      } else {
        toast({ description: "Items deleted successfully" });
      }
    } catch (error) {
      if (Notification.permission === "granted") {
        new Notification("Error deleting items");
        toast({ description: "Error deleting items", variant: "destructive" });
      } else {
        toast({ description: "Error deleting items", variant: "destructive" });
      }
    } finally {
      fetchData();
      table.resetRowSelection(true);
    }
  };

  // Request browser notification permission on page load
  useEffect(() => {
    if (Notification.permission !== "granted") {
      Notification.requestPermission();
    }
  }, []);

  const requestAllData = async () => {
    setRangeDate(undefined);
    setSearchParams({});
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const currentQuery = event.target.value;

    if (previousQuery && currentQuery === "") {
      // If a query was set earlier and is now cleared, reset row selection
      setRowSelection({});
    }

    setPreviousQuery(currentQuery); // Update previous query
    table.getColumn("user_agent")?.setFilterValue(currentQuery);
  };

  return (
    <>
      <Breadcrumbs
        home={{ title: "Overview", path: "/" }}
        routes={[{ title: "User agents", path: "" }]}
      />
      <CommonTable
        table={table}
        data={logsData}
        columns={columns}
        loading={loading}
        showAllRows={true}
        showSelectedCount={true}
        name="User agents"
        searchComponent={
          <div className="flex flex-col md:flex-row md:justify-between md:items-center py-4 space-y-4 md:space-y-0 ml-1">
            <div className="flex space-x-4 items-center">
              <Input
                placeholder="Search..."
                onChange={handleSearch}
                className="w-full md:max-w-[284px] h-8"
              />

              <Button disabled={loading} onClick={requestAllData}>
                Request all
              </Button>
            </div>

            <div className="flex justify-between md:justify-normal items-center space-x-4">
              <Popover>
                <PopoverTrigger asChild>
                  <Button
                    id="date"
                    variant="outline"
                    className="w-[260px] justify-start text-left font-normal"
                  >
                    <CalendarIcon className="mr-2 h-4 w-4" />
                    {rangeDate?.from ? (
                      rangeDate.to ? (
                        <>
                          {format(rangeDate.from, "LLL dd, y")} -{" "}
                          {format(rangeDate.to, "LLL dd, y")}
                        </>
                      ) : (
                        format(rangeDate.from, "LLL dd, y")
                      )
                    ) : (
                      <span>Pick a date</span>
                    )}
                  </Button>
                </PopoverTrigger>
                <PopoverContent className="w-auto p-0" align="start">
                  <Calendar
                    mode="range"
                    defaultMonth={rangeDate?.from}
                    selected={rangeDate}
                    onSelect={handleDateRangeChange}
                    numberOfMonths={2}
                  />
                </PopoverContent>
              </Popover>

              {isAdmin && Object.keys(rowSelection).length > 0 && (
                <Button
                  variant="destructive"
                  onClick={() => setOpenAlert(true)}
                >
                  Delete ({Object.keys(rowSelection).length}){" "}
                  {Object.keys(rowSelection).length === 1 ? "row" : "rows"}
                </Button>
              )}
            </div>
          </div>
        }
      />

      <AlertDialog open={openAlert} onOpenChange={setOpenAlert}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Are you sure?</AlertDialogTitle>
            <AlertDialogDescription>
              This action cannot be undone. This will permanently delete all the
              selected items.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction onClick={handleDelete}>
              Confirm
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default UserAgents;
