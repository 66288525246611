import Breadcrumbs from "components/Shared/Breadcrumbs";
import { useAppState } from "context/AppContext";
import AccountSelect from "../AccountSelect";

const Clickfarm = () => {
  const { state } = useAppState();
  const { agency, client, account } = state.admin;

  return (
    <div>
      <Breadcrumbs
        // icon={
        //   <Button size="icon" variant="secondary" className="mr-2">
        //     <PanelRightClose className="size-4" />
        //   </Button>
        // }
        routes={[
          { title: agency?.agency_name || "", path: "/admin/agencies" },
          { title: client?.customer_name || "", path: "/admin/clients" },
          { title: account?.account_name || "", path: "/admin/accounts" },
          { title: "Clickfarm", path: "" },
        ]}
      />

      <div className="space-y-2 mt-8">
        <div className="flex justify-between items-center">
          <h3 className="scroll-m-20 text-2xl font-bold tracking-tight">
            Clickfarm
          </h3>
          <AccountSelect />
        </div>
        {/* <p className="text-base text-muted-foreground">
      List of all campaigns edit/delete campaign from here.
    </p> */}
      </div>

      <div></div>
    </div>
  );
};

export default Clickfarm;
