import { useEffect, useState } from "react";
import { RefreshCw } from "lucide-react";
import { enGB } from "date-fns/locale";
import { DateTimePicker } from "components/ui/datetime-picker";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "components/ui/dialog";
import { Label } from "components/ui/label";
import { Button } from "components/ui/button";
import { Input } from "components/ui/input";
import { useAppState } from "context/AppContext";
import CustomSelect from "components/Shared/CustomSelect";
import { HintSchedule, Target, Poster, Actor, HintSubject } from "types";
import apiClient from "api";

type Props = {
  open: boolean;
  editData?: HintSchedule | null;
  selectedSubject: HintSubject | null;
  fetchData: () => void;
  onOpenChange: (value: boolean) => void;
};

const AddHintScheduleDialog = ({
  open,
  editData,
  selectedSubject,
  fetchData,
  onOpenChange,
}: Props) => {
  const { state } = useAppState();
  const { account } = state.admin;
  const [targetTypes, setTargetTypes] = useState<Target[]>([]);
  const [targetType, setTargetType] = useState<Target | null>(null);
  const [posterTypes, setPosterTypes] = useState<Poster[]>([]);
  const [posterType, setPosterType] = useState<Poster | null>(null);
  const [actors, setActors] = useState<Actor[]>([]);
  const [posterList, setPosterList] = useState<Actor[]>([]);
  const [targetList, setTargetList] = useState<Actor[]>([]);
  const [poster, setPoster] = useState<Actor | null>(null);
  const [target, setTarget] = useState<Actor | null>(null);
  const [submitting, setSubmitting] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [hoursBetween, setHoursBetween] = useState(0);
  const [earliestTimestamp, setEarliestTimestamp] = useState<Date | undefined>(
    undefined
  );

  useEffect(() => {
    if (!editData) return;
    setHoursBetween(editData.hours_between);
    setTargetType(
      targetTypes.find((c) => c.target_id === editData.target_type) || null
    );
    setPosterType(
      posterTypes.find((p) => p.content_type === editData.poster_type) || null
    );
    setEarliestTimestamp(
      editData.earliest_timestamp
        ? new Date(editData.earliest_timestamp * 1000)
        : undefined
    );
    // eslint-disable-next-line
  }, [editData, posterTypes, targetTypes]);

  useEffect(() => {
    if (actors.length === 0) return;
    const targets = actors.filter(
      (item) =>
        item.actor_type === targetType?.target_id &&
        item.creative_id === account?.account_id
    );
    const posters = actors.filter(
      (item) =>
        item.actor_type === posterType?.content_type &&
        item.creative_id === account?.account_id
    );
    setTargetList(targets);
    setPosterList(posters);
    // eslint-disable-next-line
  }, [posterType, targetType, actors, editData]);

  useEffect(() => {
    if (!editData) return;
    const target = targetList.find(
      (a) =>
        a.actor_type === editData.target_type &&
        a.creative_id === account?.account_id
    );
    const poster = posterList.find(
      (a) =>
        a.actor_type === editData.poster_type &&
        a.actor_id === editData.poster_id &&
        a.creative_id === account?.account_id
    );
    setTarget(target || null);
    setPoster(poster || null);
    // eslint-disable-next-line
  }, [posterList, targetList, editData]);

  useEffect(() => {
    if (!open) {
      setHoursBetween(0);
      setTargetType(null);
      setTarget(null);
      setPoster(null);
      setPosterType(null);
      setPosterList([]);
      setTargetList([]);
      setActors([]);
      return;
    }

    // Fetch all required data when the dialog opens
    const fetchData = async () => {
      setLoadingData(true);
      try {
        const [posterRes, targetRes, actorsRes] = await Promise.all([
          apiClient.get("/admin_creative_get_poster_types"),
          apiClient.get("/admin_creative_get_target_types"),
          apiClient.get("/admin_creative_get_actors"),
        ]);

        setPosterTypes(posterRes.data?.message || []);
        setTargetTypes(targetRes.data?.message || []);
        setActors(actorsRes.data?.message || []);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoadingData(false);
      }
    };

    fetchData();

    // eslint-disable-next-line
  }, [open]);

  const addHintSchedule = async (evt: React.FormEvent) => {
    evt.preventDefault();
    setSubmitting(true);
    if (Boolean(editData)) {
      try {
        await apiClient.post(
          "/admin_creative_update_hint_schedule",
          {},
          {
            headers: {
              request: JSON.stringify({
                hint_schedule_id: editData?.hint_schedule_id,
                hint_subject_id: selectedSubject?.hint_subject_id,
                poster_type: posterType?.content_type,
                poster_id: poster?.actor_id || 0,
                target_type: targetType?.target_id,
                target_id: target?.actor_id || 0,
                hours_between: hoursBetween,
                earliest_timestamp: earliestTimestamp
                  ? Math.floor(earliestTimestamp.getTime() / 1000)
                  : 0,
              }),
            },
          }
        );
        onOpenChange(false);
        fetchData();
      } catch (error) {
        console.log("Error:", error);
      } finally {
        setSubmitting(false);
      }
    } else {
      try {
        await apiClient.post(
          "/admin_creative_add_hint_schedule",
          {},
          {
            headers: {
              request: JSON.stringify({
                hint_subject_id: selectedSubject?.hint_subject_id,
                poster_type: posterType?.content_type,
                poster_id: poster?.actor_id || 0,
                target_type: targetType?.target_id,
                target_id: target?.actor_id || 0,
                hours_between: hoursBetween,
                earliest_timestamp: earliestTimestamp
                  ? Math.floor(earliestTimestamp.getTime() / 1000)
                  : 0,
              }),
            },
          }
        );
        onOpenChange(false);
        fetchData();
      } catch (error) {
        console.log("Error:", error);
      } finally {
        setSubmitting(false);
      }
    }
  };

  return (
    <>
      <Dialog open={open} onOpenChange={onOpenChange}>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>
              {!!editData ? "Edit" : "Add"} hint schedule
            </DialogTitle>
          </DialogHeader>

          <form onSubmit={addHintSchedule} className="w-full grid gap-4">
            <div className="grid gap-2">
              <Label htmlFor="earliest-timestamp">Earliest timestamp</Label>

              <DateTimePicker
                locale={enGB}
                value={earliestTimestamp}
                onChange={setEarliestTimestamp}
              />
            </div>

            <div className="grid gap-2 w-full">
              <Label htmlFor="poster-type">Poster type</Label>
              <CustomSelect
                loading={loadingData}
                value={posterType ? String(posterType?.content_type) : ""}
                onSelect={(value) => {
                  if (value === "") {
                    setPosterType(null);
                    setPoster(null);
                    setPosterList([]);
                    return;
                  }
                  const poster = posterTypes.filter(
                    (p) => p.content_type === parseInt(String(value))
                  );
                  setPosterType(poster[0] || null);
                  setPoster(null);
                  const posters = actors.filter(
                    (item) =>
                      item.actor_type === poster[0]?.content_type &&
                      item.creative_id === account?.account_id
                  );
                  setPosterList(posters);
                }}
                data={posterTypes.map((t) => ({
                  value: String(t.content_type),
                  label: t.description,
                }))}
                label="poster type"
              />
            </div>

            <div className="grid gap-2 w-full">
              <Label htmlFor="poster">Poster</Label>
              <CustomSelect
                loading={loadingData}
                disabled={!Boolean(posterType)}
                value={poster ? String(poster?.actor_id) : ""}
                onSelect={(value) => {
                  if (value === "") {
                    setPoster(null);
                    return;
                  }
                  const _poster = posterList.filter(
                    (d) => d.actor_id === parseInt(String(value))
                  );
                  setPoster(_poster[0] || null);
                }}
                data={posterList.map((t) => ({
                  value: String(t.actor_id),
                  label: t.identified_name,
                }))}
                label="poster"
              />
            </div>

            <div className="grid gap-2 w-full">
              <Label htmlFor="target-type">Target type</Label>
              <CustomSelect
                loading={loadingData}
                value={targetType ? String(targetType?.target_id) : ""}
                onSelect={(value) => {
                  if (value === "") {
                    setTargetType(null);
                    setTarget(null);
                    setTargetList([]);
                    return;
                  }
                  const target = targetTypes.filter(
                    (t) => t.target_id === parseInt(String(value))
                  );
                  setTargetType(target[0] || null);
                  setTarget(null);

                  const posters = actors.filter(
                    (item) =>
                      item.actor_type === target[0]?.target_id &&
                      item.creative_id === account?.account_id
                  );
                  setTargetList(posters);
                }}
                data={targetTypes.map((t) => ({
                  value: String(t.target_id),
                  label: t.description,
                }))}
                label="target type"
              />
            </div>

            <div className="grid gap-2 w-full">
              <Label htmlFor="target">Target</Label>
              <CustomSelect
                loading={loadingData}
                disabled={!Boolean(targetType)}
                value={target ? String(target?.actor_id) : ""}
                onSelect={(value) => {
                  if (value === "") {
                    setTarget(null);
                    return;
                  }
                  const _target = targetList.filter(
                    (d) => d.actor_id === parseInt(String(value))
                  );
                  setTarget(_target[0] || null);
                }}
                data={targetList.map((t) => ({
                  value: String(t.actor_id),
                  label: t.identified_name,
                }))}
                label="target"
              />
            </div>

            <div className="grid gap-2">
              <Label htmlFor="hours">Hours between</Label>
              <Input
                required
                type="number"
                id="hours"
                value={hoursBetween || ""}
                onChange={(evt) => {
                  const val = evt.target.value;
                  if (parseInt(val) < 0) return;
                  setHoursBetween(parseInt(val));
                }}
              />
            </div>

            <DialogFooter>
              <DialogClose asChild>
                <Button
                  disabled={submitting}
                  type="button"
                  size="sm"
                  variant="outline"
                >
                  Cancel
                </Button>
              </DialogClose>
              <Button disabled={submitting} type="submit" size="sm">
                {submitting && (
                  <RefreshCw className="size-4 animate-spin mr-2" />
                )}{" "}
                Save
              </Button>
            </DialogFooter>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddHintScheduleDialog;
