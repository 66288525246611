import { useEffect, useState } from "react";
import { DotsHorizontalIcon } from "@radix-ui/react-icons";
import { Row } from "@tanstack/react-table";

import { Button } from "components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "components/ui/dropdown-menu";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "components/ui/alert-dialog";
import { useToast } from "components/ui/use-toast";
import { WordpressConnection } from "types";
import { useAppState } from "context/AppContext";
import apiClient from "api";
import WordpressAddDialog from "routes/private/Wordpress/WordpressDialog";

interface DataTableRowActionsProps<TData> {
  row: Row<TData>;
}

export function DataTableRowActions({
  row,
}: DataTableRowActionsProps<WordpressConnection>) {
  const { dispatch } = useAppState();
  const { toast } = useToast();

  const [isEditing, setIsEditing] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const wp_connection = row.original;

  useEffect(() => {
    if (!openAlert) return;

    // eslint-disable-next-line
  }, [openAlert]);

  const deleteWPConnection = async () => {
    try {
      await apiClient.delete("/creative_delete_wordpress_connection", {
        headers: {
          request: JSON.stringify({
            wp_connection_id: wp_connection.wp_connection_id,
          }),
        },
      });
      setIsDeleting(false);
      toast({
        description: "Delete successful!",
      });
      dispatch({ type: "SET_REFRESH", payload: "wordpress" });
    } catch (error) {
      setIsDeleting(false);
      console.log(error);
    }
  };

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            variant="ghost"
            className="flex h-8 w-8 p-0 data-[state=open]:bg-muted"
          >
            <DotsHorizontalIcon className="h-4 w-4" />
            <span className="sr-only">Open menu</span>
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end" className="w-[160px]">
          <DropdownMenuLabel>Actions</DropdownMenuLabel>
          <DropdownMenuItem
            disabled={isDeleting}
            onClick={() => {
              setIsEditing(true);
            }}
          >
            Edit
          </DropdownMenuItem>

          <DropdownMenuSeparator />
          <DropdownMenuItem
            disabled={isDeleting}
            onClick={() => setOpenAlert(true)}
            className="text-red-600"
          >
            Delete
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>

      <AlertDialog open={openAlert} onOpenChange={setOpenAlert}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Are you sure?</AlertDialogTitle>
            <AlertDialogDescription>
              This action cannot be undone. This will permanently delete your
              wordpress connection.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction onClick={deleteWPConnection}>
              Confirm
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      <WordpressAddDialog
        wp_data={wp_connection}
        open={isEditing}
        onOpenChange={setIsEditing}
      />
    </>
  );
}
