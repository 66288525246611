import { useEffect, useState } from "react";
import { useAppState } from "context/AppContext";

import { DataTableCampaigns } from "./data";
import { Podcast } from "types";
import apiClient from "api";

type Campaign = {
  campaign_id: number;
  key_phrases: string;
  selected: number;
};

type Props = {
  selectedPodcast: Podcast | null;
};

const Campaigns = ({ selectedPodcast }: Props) => {
  const { state } = useAppState();
  const { account } = state.admin;

  const [data, setData] = useState<Campaign[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setData([]);
    if (!account || !selectedPodcast) return;
    const timeout = setTimeout(fetchCampaigns, 200);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line
  }, [account, selectedPodcast]);

  const fetchCampaigns = async () => {
    setLoading(true);
    try {
      const { data } = await apiClient.get(
        "admin_creative_get_podcast_campaign_set",
        {
          headers: {
            request: JSON.stringify({
              podcast_id: selectedPodcast?.podcast_id,
            }),
          },
        }
      );
      setData(data.message || []);
    } catch (error) {
      console.log("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="mt-8">
        <div className="flex justify-between items-center">
          <h3 className="scroll-m-20 text-2xl font-bold tracking-tight">
            Campaigns
          </h3>
        </div>
      </div>

      <DataTableCampaigns
        data={data}
        loading={loading}
        podcast={selectedPodcast}
      />
    </div>
  );
};

export default Campaigns;
