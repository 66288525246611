import * as React from "react";
import {
  Row,
  ColumnDef,
  SortingState,
  VisibilityState,
  getCoreRowModel,
  ColumnFiltersState,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Button } from "components/ui/button";
import { Checkbox } from "components/ui/checkbox";
import { Eye, EyeOff, MoreHorizontal } from "lucide-react";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "components/ui/alert-dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "components/ui/dropdown-menu";
import { useToast } from "components/ui/use-toast";
import CommonTable from "components/Shared/CommonTable";
import AddHintSubjectDialog from "./AddHintSubjectDialog";
import { DataTableColumnHeader } from "components/Shared/AdminTableHeader";
import CustomLongTextRenderer from "components/Shared/CustomLongTextRenderer";
import { HintSubject } from "types";
import apiClient from "api";
import { Input } from "components/ui/input";

type Props = {
  loading: boolean;
  data: HintSubject[];
  fetchData: () => void;
  setOpenAdd: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedSubject: React.Dispatch<React.SetStateAction<HintSubject | null>>;
};

export function DataTableHintSubjects({
  data,
  loading,
  fetchData,
  setOpenAdd,
  setSelectedSubject,
}: Props) {
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );
  const [columnVisibility, setColumnVisibility] =
    React.useState<VisibilityState>({});
  const [rowSelection, setRowSelection] = React.useState({});
  const [selectedRowId, setSelectedRowId] = React.useState<number | null>(null);
  const [expandAllRows, setExpandAllRows] = React.useState(false);
  const [toggleRow, setToggleRow] = React.useState<{
    [key: string]: boolean;
  }>({});
  const handleToggleShowAll = (row: HintSubject) => {
    const isExpanded =
      Object.keys(toggleRow).includes(String(row.hint_subject_id)) &&
      Boolean(toggleRow[String(row.hint_subject_id)]);

    if (isExpanded) {
      setToggleRow({
        ...toggleRow,
        [String(row.hint_subject_id)]: false,
      });
    } else {
      setToggleRow({
        ...toggleRow,
        [String(row.hint_subject_id)]: true,
      });
    }
  };

  const selectionToggle = (row: Row<HintSubject>) => {
    if (selectedRowId === row.original.hint_subject_id) {
      setSelectedRowId(null);
    } else {
      setSelectedRowId(row.original.hint_subject_id);
    }
  };

  const columns: ColumnDef<HintSubject>[] = [
    {
      id: "select",
      header: () => null,
      cell: ({ row }) => (
        <Checkbox
          checked={selectedRowId === row.original.hint_subject_id}
          onCheckedChange={() => {
            selectionToggle(row);
          }}
          aria-label="Select row"
        />
      ),
      enableSorting: false,
      enableHiding: false,
    },
    {
      accessorKey: "hint_subject_id",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="ID" />
      ),
      cell: ({ row }) => (
        <div className="w-8">{row.original.hint_subject_id}</div>
      ),
    },
    {
      accessorKey: "hint_subject_prompt",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Hint subject prompt" />
      ),
      cell: ({ row }) => (
        <CustomLongTextRenderer
          longText={row.original.hint_subject_prompt}
          onClick={() => selectionToggle(row)}
          maxWidth="max-w-2xl"
          showAll={
            expandAllRows
              ? true
              : Boolean(toggleRow[String(row.original.hint_subject_id)])
          }
        />
      ),
    },
    {
      id: "actions",
      header: () => {
        return (
          <div className="flex items-center space-x-2">
            <Button
              onClick={() => setExpandAllRows(!expandAllRows)}
              variant="ghost"
              className="h-6 w-6 p-0"
            >
              {expandAllRows ? (
                <EyeOff className="size-4" />
              ) : (
                <Eye className="size-4" />
              )}
            </Button>
            <p className="ml-2">Actions</p>
          </div>
        );
      },
      enableHiding: false,
      cell: ({ row }) => (
        <RowAction
          row={row}
          hasShowAll={true}
          showAllText={
            expandAllRows
              ? true
              : Boolean(toggleRow[String(row.original.hint_subject_id)])
          }
          fetchData={fetchData}
          toggleRow={() => handleToggleShowAll(row.original)}
        />
      ),
    },
  ];

  const table = useReactTable({
    data,
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
    },
  });

  React.useEffect(() => {
    if (selectedRowId) {
      const selectedRowData =
        data.find((row) => row.hint_subject_id === selectedRowId) || null;
      setSelectedSubject(selectedRowData);
    } else {
      setSelectedSubject(null);
    }

    // eslint-disable-next-line
  }, [selectedRowId, data]);

  return (
    <CommonTable
      table={table}
      searchComponent={
        <div className="flex items-center justify-between py-4 pl-1">
          <Input
            placeholder="Filter hint subjects..."
            value={
              (table
                .getColumn("hint_subject_prompt")
                ?.getFilterValue() as string) ?? ""
            }
            onChange={(event) =>
              table
                .getColumn("hint_subject_prompt")
                ?.setFilterValue(event.target.value)
            }
            className="max-w-sm h-8"
          />

          <Button size="sm" onClick={() => setOpenAdd(true)}>
            Add hint subject
          </Button>
        </div>
      }
      data={data}
      columns={columns}
      loading={loading}
      name="Hint subjects"
    />
  );
}

interface DataTableRowActionsProps<TData> {
  row: Row<TData>;
  hasShowAll: boolean;
  showAllText: boolean;
  fetchData: () => void;
  toggleRow: () => void;
}

const RowAction = ({
  row,
  hasShowAll,
  showAllText,
  fetchData,
  toggleRow,
}: DataTableRowActionsProps<HintSubject>) => {
  const { toast } = useToast();

  const [openAlert, setOpenAlert] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [isDeleting, setIsDeleting] = React.useState(false);

  const data = row.original;

  const deleteHintSubject = async () => {
    setIsDeleting(true);
    toast({
      description: "Deleting hint subject...",
      variant: "destructive",
    });

    try {
      await apiClient.delete("/admin_creative_delete_hint_subject", {
        headers: {
          request: JSON.stringify({
            hint_subject_id: data.hint_subject_id,
          }),
        },
      });
      fetchData();
      setIsDeleting(false);
      toast({
        description: "Hint subject deleted successfully.",
        variant: "destructive",
      });
    } catch (error) {
      setIsDeleting(false);
      toast({
        description: "Hint subject deletion failed.",
        variant: "destructive",
      });
    }
  };

  return (
    <div className="flex items-center space-x-2">
      {hasShowAll && (
        <Button onClick={toggleRow} variant="ghost" className="h-6 w-6 p-0">
          {showAllText ? (
            <EyeOff className="size-4" />
          ) : (
            <Eye className="size-4" />
          )}
        </Button>
      )}
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" className="h-6 w-6 p-0">
            <span className="sr-only">Open menu</span>
            <MoreHorizontal className="h-3 w-3" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <DropdownMenuLabel>Actions</DropdownMenuLabel>
          <DropdownMenuItem onClick={() => setOpenEdit(true)}>
            Edit
          </DropdownMenuItem>
          <DropdownMenuSeparator />
          <DropdownMenuItem
            disabled={isDeleting}
            onClick={() => setOpenAlert(true)}
            className="text-red-600"
          >
            Delete
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>

      <AlertDialog open={openAlert} onOpenChange={setOpenAlert}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Are you sure?</AlertDialogTitle>
            <AlertDialogDescription>
              This action cannot be undone. This will permanently delete your
              hint subject.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction onClick={deleteHintSubject}>
              Confirm
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      <AddHintSubjectDialog
        fetchData={fetchData}
        open={openEdit}
        onOpenChange={setOpenEdit}
        editData={data}
      />
    </div>
  );
};
