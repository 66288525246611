import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ArrowLeft, ChevronLeft, ChevronRight } from "lucide-react";
import { ToastAction } from "@radix-ui/react-toast";
import { Channels } from "components/Setup/LeftPanel/Channels";
import { Connections } from "components/Setup/RightPanel/Connections";
import { Button } from "components/ui/button";
import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from "components/ui/resizable";
import { useToast } from "components/ui/use-toast";
import ArticlesView from "./ArticleList";
import useMediaQuery from "hooks/useMediaQuery";
import { ScheduleSet } from "types";
import { useAppState } from "context/AppContext";
import apiClient from "api";

const getPostEndpoint = (social: string) => {
  switch (social) {
    case "linkedin":
      return "/manual_linkedin_post";
    case "facebook":
      return "/manual_facebook_post";
    case "twitter":
      return "/manual_twitter_post";
    default:
      return "";
  }
};

const SetupPost = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = useAppState();
  const { account_token } = state.user;
  const width = useMediaQuery();
  const { toast } = useToast();

  const [mobileStep, setMobileStep] = useState(1);
  const [submitting, setSubmitting] = useState(false);
  const [selectedData, setSelectedData] = useState<ScheduleSet>({
    selectedChannel: null,
    selectedConnection: [],
    article: null,
  });

  const goBackOrRedirect = () => {
    if (location.key === "default") {
      // If there's no history, redirect to the fallback route
      navigate("/posts", { replace: true });
    } else {
      // Otherwise, go back to the previous page
      navigate(-1);
    }
  };

  const nextStep = () => setMobileStep((ps) => ps + 1);
  const prevStep = () => setMobileStep((ps) => ps - 1);

  const disableNext = (): boolean => {
    if (mobileStep === 1 && !selectedData.selectedChannel) return true;
    if (mobileStep === 2 && !selectedData.selectedConnection) return true;
    if (mobileStep === 3 && !selectedData.article) return true;

    return false;
  };

  // MANUAL POST ACTION
  const manuallyPost = async () => {
    const endpoint = getPostEndpoint(
      selectedData?.selectedConnection[0].channel
    );
    if (!endpoint) return;

    setSubmitting(true);
    toast({
      description: "Submitting post...",
    });
    try {
      await apiClient.post(
        endpoint,
        {},
        {
          headers: {
            token: account_token,
            request: JSON.stringify({
              id: selectedData?.selectedConnection[0].id,
              article_id: selectedData.article?.article_id,
            }),
          },
        }
      );
      setSubmitting(false);
      toast({
        description: "Post has been submitted successful.",
        action: (
          <ToastAction altText="View post" onClick={() => navigate("/posts")}>
            View post
          </ToastAction>
        ),
      });
    } catch (error) {
      toast({
        description: "Failed to submit post.",
      });
      setSubmitting(false);
    }
  };

  return (
    <div className="overflow-hidden h-screen">
      <div className="border-b sticky top-0 backdrop-blur-sm bg-white/30 dark:bg-black/30 z-10">
        <div className="flex items-center p-2">
          <div className="flex items-center gap-2">
            <Button onClick={goBackOrRedirect} size="sm" variant="ghost">
              <ArrowLeft size={20} className="mr-2" />
              Back
            </Button>
          </div>

          <div className="ml-auto flex items-center space-x-4">
            <Button
              size="sm"
              variant="ghost"
              onClick={manuallyPost}
              disabled={
                submitting ||
                !selectedData.selectedChannel ||
                !selectedData.article ||
                !selectedData.selectedConnection.length
              }
            >
              Make a post
            </Button>
          </div>
        </div>
      </div>

      <ResizablePanelGroup
        direction="horizontal"
        onLayout={(sizes: number[]) => {
          document.cookie = `react-resizable-panels:layout=${JSON.stringify(
            sizes
          )}`;
        }}
        style={{
          display: width < 1024 ? "none" : "flex",
        }}
        className="h-full lg:flex hidden items-stretch"
      >
        <ResizablePanel minSize={15} maxSize={20}>
          <Channels
            selectedData={selectedData}
            setSelectedData={setSelectedData}
          />
        </ResizablePanel>

        <ResizableHandle withHandle />

        <ResizablePanel defaultSize={60} minSize={10} maxSize={60}>
          <ArticlesView
            selectedData={selectedData}
            setSelectedData={setSelectedData}
          />
        </ResizablePanel>

        <ResizableHandle withHandle />

        <ResizablePanel minSize={15} maxSize={20}>
          <Connections
            selectedData={selectedData}
            multiple={false}
            setSelectedData={setSelectedData}
          />
        </ResizablePanel>
      </ResizablePanelGroup>

      {/* Responsive ui for smaller screens */}
      <div className="lg:hidden h-full">
        {mobileStep === 1 && (
          <Channels
            selectedData={selectedData}
            setSelectedData={setSelectedData}
          />
        )}
        {mobileStep === 2 && (
          <Connections
            multiple={false}
            selectedData={selectedData}
            setSelectedData={setSelectedData}
          />
        )}
        {mobileStep === 3 && (
          <ArticlesView
            smallScreen={true}
            selectedData={selectedData}
            setSelectedData={setSelectedData}
          />
        )}
      </div>
      <div className="lg:hidden border-t sticky bottom-0 backdrop-blur-sm bg-white/30 dark:bg-black/30 z-10">
        <div className="flex items-center p-2">
          <div className="flex items-center gap-2">
            <Button
              onClick={prevStep}
              size="sm"
              variant="ghost"
              disabled={mobileStep === 1}
            >
              <ChevronLeft size={16} className="mr-2" />
              Previous
            </Button>
            {/* <span className="text-sm font-medium">Channels</span> */}
          </div>

          <div className="ml-auto flex items-center space-x-4">
            <div className="flex items-center gap-2">
              {/* <span className="text-sm font-medium">Connections</span> */}

              <Button
                onClick={nextStep}
                size="sm"
                variant="ghost"
                disabled={disableNext() || mobileStep === 3}
              >
                Next <ChevronRight size={16} className="ml-2" />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SetupPost;
