import { useEffect, useState } from "react";
import { RefreshCw } from "lucide-react";
import { enGB } from "date-fns/locale";
import { DateTimePicker } from "components/ui/datetime-picker";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "components/ui/dialog";
import { Label } from "components/ui/label";
import { Button } from "components/ui/button";
import { Input } from "components/ui/input";
import { useAppState } from "context/AppContext";
import CustomSelect from "components/Shared/CustomSelect";
import { GroupSchedule, Content, Poster, Group } from "types";
import apiClient from "api";

type PosterList = {
  poster_id: number;
  description: string;
  identified_name: string;
};

type Props = {
  open: boolean;
  selectedGroup: Group | null;
  editData?: GroupSchedule | null;
  fetchData: () => void;
  onOpenChange: (value: boolean) => void;
};

const AddGroupScheduleDialog = ({
  open,
  editData,
  selectedGroup,
  fetchData,
  onOpenChange,
}: Props) => {
  const { state } = useAppState();
  const { account } = state.admin;

  const [contentTypes, setContentTypes] = useState<Content[]>([]);
  const [contentList, setContentList] = useState<
    { content_id: number; description: string }[]
  >([]);
  const [posterTypes, setPosterTypes] = useState<Poster[]>([]);
  const [posterList, setPosterList] = useState<PosterList[]>([]);
  const [submitting, setSubmitting] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [hoursBetween, setHoursBetween] = useState(0);
  const [contentType, setContentType] = useState<Content | null>(null);

  const [content, setContent] = useState<{
    content_id: number;
    description: string;
  } | null>(null);
  const [posterType, setPosterType] = useState<Poster | null>(null);
  const [poster, setPoster] = useState<{
    poster_id: number;
    description: string;
  } | null>(null);
  const [earliestTimestamp, setEarliestTimestamp] = useState<Date | undefined>(
    undefined
  );

  useEffect(() => {
    if (!open) {
      setHoursBetween(0);
      setContentType(null);
      setContent(null);
      setPosterTypes([]);
      setContentTypes([]);
      setContentList([]);
      setPosterList([]);
      return;
    }

    const fetchData = async () => {
      setLoadingData(true);
      try {
        const [posterRes, contentRes] = await Promise.all([
          apiClient.get("/admin_creative_get_poster_types"),
          apiClient.get("/admin_creative_get_content_types"),
        ]);

        setPosterTypes(posterRes.data?.message || []);
        setContentTypes(contentRes.data?.message || []);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoadingData(false);
      }
    };

    fetchData();
    // eslint-disable-next-line
  }, [open]);

  useEffect(() => {
    if (!editData) return;
    setHoursBetween(editData.hours_between);
    setEarliestTimestamp(
      editData.earliest_timestamp
        ? new Date(editData.earliest_timestamp * 1000)
        : undefined
    );
    // eslint-disable-next-line
  }, [editData]);

  useEffect(() => {
    if (!editData || posterTypes.length === 0 || contentTypes.length === 0)
      return;
    setPosterType(
      posterTypes.find((p) => p.content_type === editData.poster_type) || null
    );
    setContentType(
      contentTypes.find((c) => c.content_type === editData.content_type) || null
    );
  }, [editData, posterTypes, contentTypes]);

  useEffect(() => {
    if (!editData) return;
    const poster = posterList.find((p) => p.poster_id === editData.poster_id);
    const content = contentList.find(
      (c) => c.content_id === editData.content_id
    );
    setPoster(poster || null);
    setContent(content || null);
  }, [editData, posterList, contentList]);

  useEffect(() => {
    if (!contentType) return;

    const getContent = async () => {
      try {
        const { data } = await apiClient.get(
          "/admin_creative_get_content_list",
          {
            headers: {
              request: JSON.stringify({
                content_type: contentType.content_type,
                creative_id: account?.account_id,
              }),
            },
          }
        );

        const list: { content_id: number; description: string }[] =
          data?.message || [];
        setContentList(list);
      } catch (error) {
        console.log("error:", error);
      } finally {
      }
    };

    getContent();
    // eslint-disable-next-line
  }, [contentType]);

  useEffect(() => {
    if (!posterType) return;

    const getPosterList = async () => {
      try {
        const { data } = await apiClient.get(
          "/admin_creative_get_poster_list",
          {
            headers: {
              request: JSON.stringify({
                poster_type: posterType.content_type,
                creative_id: account?.account_id,
              }),
            },
          }
        );

        const list: PosterList[] = data?.message || [];
        setPosterList(list);
      } catch (error) {
        console.log("error:", error);
      } finally {
      }
    };

    getPosterList();
    // eslint-disable-next-line
  }, [posterType]);

  const addGroupSchedule = async (evt: React.FormEvent) => {
    evt.preventDefault();
    setSubmitting(true);
    if (Boolean(editData)) {
      try {
        await apiClient.post(
          "/admin_creative_update_group_schedule",
          {},
          {
            headers: {
              request: JSON.stringify({
                group_schedule_id: editData?.group_schedule_id,
                group_id: selectedGroup?.group_id,
                content_type: contentType?.content_type,
                content_id: content?.content_id || 0,
                poster_type: posterType?.content_type,
                poster_id: poster?.poster_id || 0,
                hours_between: hoursBetween,
                earliest_timestamp: earliestTimestamp
                  ? Math.floor(earliestTimestamp.getTime() / 1000)
                  : 0,
              }),
            },
          }
        );
        onOpenChange(false);
        fetchData();
      } catch (error) {
        console.log("Error:", error);
      } finally {
        setSubmitting(false);
      }
    } else {
      try {
        await apiClient.post(
          "/admin_creative_add_group_schedule",
          {},
          {
            headers: {
              request: JSON.stringify({
                group_id: selectedGroup?.group_id,
                content_type: contentType?.content_type,
                content_id: content?.content_id || 0,
                poster_type: posterType?.content_type,
                poster_id: poster?.poster_id || 0,
                hours_between: hoursBetween,
                earliest_timestamp: earliestTimestamp
                  ? Math.floor(earliestTimestamp.getTime() / 1000)
                  : 0,
              }),
            },
          }
        );
        onOpenChange(false);
        fetchData();
      } catch (error) {
        console.log("Error:", error);
      } finally {
        setSubmitting(false);
      }
    }
  };

  return (
    <>
      <Dialog open={open} onOpenChange={onOpenChange}>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>
              {!!editData ? "Edit" : "Add"} group schedule
            </DialogTitle>
          </DialogHeader>

          <form onSubmit={addGroupSchedule} className="w-full grid gap-4">
            <div className="grid gap-2">
              <Label htmlFor="earliest-timestamp">Earliest timestamp</Label>

              <DateTimePicker
                locale={enGB}
                value={earliestTimestamp}
                onChange={setEarliestTimestamp}
              />
            </div>
            <div className="grid gap-2 w-full">
              <Label htmlFor="content-type">Content type</Label>
              <CustomSelect
                loading={loadingData}
                value={contentType ? String(contentType?.content_type) : ""}
                onSelect={(value) => {
                  if (value === "") {
                    setContentType(null);
                    setContent(null);
                    return;
                  }
                  const type = contentTypes.filter(
                    (type) => type.content_type === parseInt(String(value))
                  );
                  setContentType(type[0] || null);
                  setContent(null);
                }}
                data={contentTypes.map((t) => ({
                  value: String(t.content_type),
                  label: t.description,
                }))}
                label="content type"
              />
            </div>

            <div className="grid gap-2 w-full">
              <Label htmlFor="content">Content</Label>
              <CustomSelect
                loading={loadingData}
                value={content ? String(content?.content_id) : ""}
                onSelect={(value) => {
                  if (value === "") {
                    setContent(null);
                    return;
                  }
                  const _content = contentList.filter(
                    (d) => d.content_id === parseInt(String(value))
                  );
                  setContent(_content[0] || null);
                }}
                data={contentList.map((t) => ({
                  value: String(t.content_id),
                  label: t.description,
                }))}
                label="content"
              />
            </div>

            <div className="grid gap-2 w-full">
              <Label htmlFor="poster-type">Poster type</Label>
              <CustomSelect
                loading={loadingData}
                value={posterType ? String(posterType?.content_type) : ""}
                onSelect={(value) => {
                  if (value === "") {
                    setPosterType(null);
                    setPoster(null);
                    return;
                  }
                  const type = posterTypes.filter(
                    (p) => p.content_type === parseInt(String(value))
                  );
                  setPosterType(type[0] || null);
                  setPoster(null);
                }}
                data={posterTypes.map((t) => ({
                  value: String(t.content_type),
                  label: t.description,
                }))}
                label="poster type"
              />
            </div>

            <div className="grid gap-2 w-full">
              <Label htmlFor="poster">Poster</Label>
              <CustomSelect
                loading={loadingData}
                value={poster ? String(poster?.poster_id) : ""}
                onSelect={(value) => {
                  if (value === "") {
                    setPoster(null);
                    return;
                  }
                  const type = posterList.filter(
                    (p) => p.poster_id === parseInt(String(value))
                  );
                  setPoster(type[0] || null);
                }}
                data={posterList.map((t) => ({
                  value: String(t.poster_id),
                  label: t.identified_name,
                }))}
                label="poster"
              />
            </div>

            <div className="grid gap-2">
              <Label htmlFor="hours">Hours between</Label>
              <Input
                required
                type="number"
                id="hours"
                value={hoursBetween || ""}
                onChange={(evt) => {
                  const val = evt.target.value;
                  if (parseInt(val) < 0) return;
                  setHoursBetween(parseInt(val));
                }}
              />
            </div>

            <DialogFooter>
              <DialogClose asChild>
                <Button
                  disabled={submitting}
                  type="button"
                  size="sm"
                  variant="outline"
                >
                  Cancel
                </Button>
              </DialogClose>
              <Button disabled={submitting} type="submit" size="sm">
                {submitting && (
                  <RefreshCw className="size-4 animate-spin mr-2" />
                )}{" "}
                Save
              </Button>
            </DialogFooter>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddGroupScheduleDialog;
