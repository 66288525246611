import { useEffect, useState } from "react";

import { Button } from "components/ui/button";
import { useAppState } from "context/AppContext";
import Breadcrumbs from "components/Shared/Breadcrumbs";

import AccountSelect from "../AccountSelect";
import { DataTableClientSocials } from "./data";
import AddClientSocialDialog from "./AddClientSocialDialog";
import { ClientSocial } from "types";
import apiClient from "api";

const ClientSocials = () => {
  const { state } = useAppState();
  const { agency, client, account } = state.admin;

  const [loading, setLoading] = useState(false);
  const [openAddSetting, setOpenAddSetting] = useState(false);

  const [data, setData] = useState<ClientSocial[]>([]);

  useEffect(() => {
    setData([]);
    if (!account) return;
    const timeout = setTimeout(fetchClientSocial, 200);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line
  }, [account]);

  const fetchClientSocial = async () => {
    setLoading(true);
    try {
      const { data } = await apiClient.get(
        "admin_creative_get_client_socials",
        {
          headers: {
            request: JSON.stringify({ creative_id: account?.account_id }),
          },
        }
      );
      setData(data.message || []);
    } catch (error) {
      console.log("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Breadcrumbs
        // icon={
        //   <Button size="icon" variant="secondary" className="mr-2">
        //     <PanelRightClose className="size-4" />
        //   </Button>
        // }
        routes={[
          { title: agency?.agency_name || "", path: "/admin/agencies" },
          { title: client?.customer_name || "", path: "/admin/clients" },
          { title: account?.account_name || "", path: "/admin/accounts" },
          { title: "Client socials", path: "" },
        ]}
      />

      <div className="space-y-2 mt-8">
        <div className="flex justify-between items-center">
          <h3 className="scroll-m-20 text-2xl font-bold tracking-tight">
            Client socials
          </h3>
          <AccountSelect />
        </div>
      </div>

      <div className="mt-8">
        <div className="flex justify-end">
          <Button size="sm" onClick={() => setOpenAddSetting(true)}>
            Add client social
          </Button>
        </div>
      </div>

      <DataTableClientSocials
        data={data}
        loading={loading}
        fetchData={fetchClientSocial}
      />

      <AddClientSocialDialog
        fetchData={fetchClientSocial}
        open={openAddSetting}
        onOpenChange={setOpenAddSetting}
      />
    </div>
  );
};

export default ClientSocials;
