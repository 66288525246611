import { cn } from "lib/utils";
import { useState, useEffect } from "react";

type Props = {
  isLoading: boolean;
  isSuccess: boolean;
  isFailed: boolean;
};

const AnimatedLoading = ({ isLoading, isSuccess, isFailed }: Props) => {
  const [status, setStatus] = useState("loading");

  useEffect(() => {
    if (!isLoading) {
      if (isSuccess) {
        setStatus("success");
      }
      if (isFailed) {
        setStatus("error");
      }
    }
  }, [isLoading, isSuccess, isFailed]);

  return (
    <div className="relative flex justify-center items-center h-12 w-12">
      <div
        className={`absolute transition-opacity duration-500 ease-in-out ${
          status === "loading" ? "opacity-100" : "opacity-0"
        }`}
      >
        <div className="animate-spin rounded-full h-12 w-12 border-4 border-t-primary"></div>
      </div>

      <div
        className={cn(status === "success" ? "checkmark draw" : "none")}
      ></div>

      <div
        className={cn(status === "error" ? "cross draw" : "none")}
        style={{ width: "3.5em", height: "3.5em", position: "relative" }}
      ></div>
    </div>
  );
};

export default AnimatedLoading;
