import { useEffect, useState } from "react";
import Breadcrumbs from "components/Shared/Breadcrumbs";

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "components/ui/alert-dialog";
import { useToast } from "components/ui/use-toast";
import { Button } from "components/ui/button";
import { useAppState } from "context/AppContext";
import AccountSelect from "../AccountSelect";

import { RefreshCcw } from "lucide-react";
import { DataTableManualNews } from "./data";
import AddManualNewsDialog from "./AddManualNewsDialog";
import { ManualNewsType } from "types";
import apiClient from "api";

const ManualNews = () => {
  const { state } = useAppState();
  const { agency, client, account } = state.admin;
  const { toast } = useToast();

  const [loading, setLoading] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [resetRows, setResetRows] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [selectedIds, setSelectedIds] = useState<number[]>([]);

  const [data, setData] = useState<ManualNewsType[]>([]);

  useEffect(() => {
    setData([]);
    if (!account) return;
    const timeout = setTimeout(fetchManualNews, 200);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line
  }, [account]);

  const fetchManualNews = async () => {
    setLoading(true);
    try {
      const { data } = await apiClient.get("admin_creative_get_manual_news", {
        headers: {
          request: JSON.stringify({ creative_id: account?.account_id }),
        },
      });
      setData(data.message || []);
    } catch (error) {
      console.log("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const deleteData = async () => {
    if (selectedIds.length === 0) return;
    setIsDeleting(true);
    try {
      for (const id of selectedIds) {
        await apiClient.delete("/admin_creative_delete_manual_news", {
          headers: {
            request: JSON.stringify({
              manual_news_id: id,
            }),
          },
        });
      }

      toast({ description: "All selected items deleted successfully" });
      fetchManualNews();
      setSelectedIds([]);
      setResetRows(true);
    } catch (error) {
      toast({
        description: "An error occurred while deleting data.",
        variant: "destructive",
      });
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <div>
      <Breadcrumbs
        // icon={
        //   <Button size="icon" variant="secondary" className="mr-2">
        //     <PanelRightClose className="size-4" />
        //   </Button>
        // }
        routes={[
          { title: agency?.agency_name || "", path: "/admin/agencies" },
          { title: client?.customer_name || "", path: "/admin/clients" },
          { title: account?.account_name || "", path: "/admin/accounts" },
          { title: "Manual news", path: "" },
        ]}
      />
      <div className="space-y-2 mt-8">
        <div className="flex justify-between items-center">
          <h3 className="scroll-m-20 text-2xl font-bold tracking-tight">
            Manual news
          </h3>
          <AccountSelect />
        </div>
      </div>

      <div className="mt-8">
        <div className="flex justify-end space-x-2">
          <Button size="sm" onClick={() => setOpenAdd(true)}>
            Add manual news
          </Button>
          {selectedIds.length > 0 && (
            <AlertDialog>
              <AlertDialogTrigger asChild>
                <Button disabled={isDeleting} variant="destructive" size="sm">
                  {isDeleting ? (
                    <>
                      <RefreshCcw className="size-3 mr-2 animate-spin" />
                      Deleting...
                    </>
                  ) : (
                    `Delete (${selectedIds.length}) row(s)`
                  )}
                </Button>
              </AlertDialogTrigger>
              <AlertDialogContent>
                <AlertDialogHeader>
                  <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
                  <AlertDialogDescription>
                    This action cannot be undone. This will permanently delete
                    your selected rows.
                  </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                  <AlertDialogCancel>Cancel</AlertDialogCancel>
                  <AlertDialogAction onClick={deleteData}>
                    Continue
                  </AlertDialogAction>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialog>
          )}
        </div>
      </div>

      <DataTableManualNews
        data={data}
        loading={loading}
        resetRows={resetRows}
        fetchData={fetchManualNews}
        setResetRows={setResetRows}
        setSelectedIds={setSelectedIds}
      />

      <AddManualNewsDialog
        fetchData={fetchManualNews}
        open={openAdd}
        onOpenChange={setOpenAdd}
      />
    </div>
  );
};

export default ManualNews;
