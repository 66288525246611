import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ChevronLeft } from "lucide-react";
import { Button } from "components/ui/button";

// import { formatDateTime } from "utils/helper-function";
import { Article } from "types";
import sanitizeHtml from "utils/sanitizeHtml";
import { useAppState } from "context/AppContext";
import apiClient from "api";

const Post = () => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { state } = useAppState();
  const { account_token } = state.user;

  const [isLoading, setIsLoading] = useState(true);
  const [postData, setPostData] = useState<Article>();

  const goBackOrRedirect = () => {
    if (location.key === "default") {
      // If there's no history, redirect to the fallback route
      navigate("/posts", { replace: true });
    } else {
      // Otherwise, go back to the previous page
      navigate(-1);
    }
  };

  useEffect(() => {
    if (!account_token || !id) return;
    const timeout = setTimeout(handleFetchArticle, 100);

    return () => clearTimeout(timeout);
    // eslint-disable-next-line
  }, []);

  const handleFetchArticle = async () => {
    if (!id) {
      setPostData(undefined);
    }

    setIsLoading(true);
    try {
      const {
        data: { message },
      }: any = await apiClient.get("/get_single_article_detail", {
        headers: {
          request: JSON.stringify({
            article_id: parseInt(id || ""),
          }),
          token: account_token,
        },
      });
      setPostData(message);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setPostData(undefined);
    }
  };

  if (isLoading)
    return (
      <div className="min-h-60 flex items-center justify-center">
        <div className="flex flex-col justify-center items-center">
          <div className="size-8 border-2 border-t-2 border-muted border-t-primary rounded-full animate-spin"></div>
          <span className="text-sm text-center mt-4 text-primary">
            Loading post...
          </span>
        </div>
      </div>
    );

  return (
    <>
      <div className="max-w-6xl mx-auto py-4">
        <Button onClick={goBackOrRedirect} variant="outline">
          <ChevronLeft className="size-5 mr-2" /> Back
        </Button>
      </div>
      <article className="p-8 mx-auto xl:w-[828px] w-full max-w-none format format-sm sm:format-base lg:format-lg format-blue dark:format-invert">
        <header className="mb-8">
          <div className="w-full text-center">
            <h1 className="mx-auto mb-4 max-w-2xl text-2xl dark:text-white font-extrabold leading-none text-gray-900 sm:text-3xl lg:text-4xl">
              {postData?.title}
            </h1>
          </div>
        </header>

        <figure className="mt-4 max-w-2xl mx-auto flex flex-col items-center justify-center">
          <img
            className="h-auto max-w-full rounded-lg"
            src={postData?.image_url}
            alt={postData?.title}
          />

          <figcaption className="text-center mt-2 text-gray-500">
            Digital Art by{" "}
            <a
              href="https://creativeguru.ai"
              target="_blank"
              rel="noreferrer"
              className="underline"
            >
              creativeguru ai
            </a>
          </figcaption>
        </figure>

        <div className="mt-8">
          <div className="max-w-4xl mx-auto">
            <div className="">
              <p
                className="whitespace-pre-line dark:text-gray-300 font-light text-lg text-gray-700"
                dangerouslySetInnerHTML={{
                  __html: sanitizeHtml(postData!.article),
                }}
              ></p>
            </div>
          </div>
        </div>
      </article>
    </>
  );
};

export default Post;
