import { ColumnDef } from "@tanstack/react-table";

import { Badge } from "components/ui/badge";
import { Checkbox } from "components/ui/checkbox";

import { DataTableColumnHeader } from "./data-table-column-header";
import { DataTableRowActions } from "./data-table-row-actions";
import { toStartCase } from "utils/helper-methods";
import { Schedule } from "types";

export const columns: ColumnDef<Schedule>[] = [
  {
    id: "select",
    header: ({ table }) => (
      <Checkbox
        checked={
          table.getIsAllPageRowsSelected() ||
          (table.getIsSomePageRowsSelected() && "indeterminate")
        }
        onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
        aria-label="Select all"
        className="translate-y-[2px]"
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={(value) => row.toggleSelected(!!value)}
        aria-label="Select row"
        className="translate-y-[2px]"
      />
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "schedule_id",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="ID" />
    ),
    cell: ({ row }) => (
      <div className="w-[80px] text-xs sm:text-sm">
        {row.getValue("schedule_id")}
      </div>
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "schedule_name",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Schedule name" />
    ),
    cell: ({ row }) => {
      return (
        <div className="flex space-x-2 text-xs sm:text-sm">
          <span className="max-w-[200px] truncate font-medium">
            {row.getValue("schedule_name")}
          </span>
        </div>
      );
    },
    filterFn: (row, id, value) => {
      return row.original.schedule_name
        .toLowerCase()
        .includes(value.toLowerCase());
    },
    enableColumnFilter: true,
  },
  {
    accessorKey: "channel",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Channel" />
    ),
    cell: ({ row }) => {
      return (
        <div className="flex items-center text-xs sm:text-sm">
          <Badge variant="outline">
            {toStartCase(row.original.schedule_venue)}
          </Badge>
        </div>
      );
    },
    getUniqueValues: (row) => {
      return [row.schedule_venue.toLowerCase()];
    },
    filterFn: (row, id, value) => {
      return value.includes(row.original.schedule_venue.toLowerCase());
    },
  },
  {
    accessorKey: "connection_name",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Connection name" />
    ),
    cell: ({ row }) => {
      return (
        <div className="flex items-center text-xs sm:text-sm">
          <span className="max-w-[180px] truncate text-muted-foreground">
            {row.original.channel_name}
          </span>
        </div>
      );
    },
    filterFn: (row, id, value) => {
      return row.original.channel_name
        .toLowerCase()
        .includes(value.toLowerCase());
    },
    enableColumnFilter: true,
  },
  {
    accessorKey: "frequency",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Frequency" />
    ),
    cell: ({ row }) => {
      return (
        <div className="flex flex-col text-xs sm:text-sm">
          <span className="text-muted-foreground">Daily</span>
        </div>
      );
    },
    getUniqueValues: (row, index) => {
      return ["daily"];
    },
    filterFn: (row, id, value) => {
      return value.includes("daily");
    },
  },
  {
    accessorKey: "time",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Time" />
    ),
    cell: ({ row }) => {
      return (
        <div className="flex flex-col text-xs sm:text-sm">
          <span className="text-muted-foreground text-xs">
            {row.original.scheduled_times.length > 0
              ? row.original.scheduled_times
                  .map((s) => `${s.time.split(":")[0]}:${s.time.split(":")[1]}`)
                  .join(", ")
              : "No schedule"}
          </span>
        </div>
      );
    },
  },
  {
    id: "actions",
    cell: ({ row }) => <DataTableRowActions row={row} />,
  },
];
