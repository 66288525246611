import { Button } from "components/ui/button";
import { Separator } from "components/ui/separator";
import { cn } from "lib/utils";
import { ChevronRight, Clock } from "lucide-react";
import { getDateSuffix, toStartCase } from "utils/helper-methods";
import { Time, TimeSet } from "./SchedulesSet";
import { useEffect, useState } from "react";
import { Badge } from "components/ui/badge";

type Props = {
  item: TimeSet;
};

const ScheduleItem = ({ item }: Props) => {
  const [expand, setExpand] = useState(false);
  const [sortedTimes, setSortedTimes] = useState<Time[]>([]);

  useEffect(() => {
    const allSortedTimes = sortScheduleTimes(item.times);

    setSortedTimes(allSortedTimes);
  }, [item]);

  const formatTime = (schedule: Time) => {
    if (schedule.frequency === "daily") {
      return "Daily";
    }
    if (schedule.frequency === "weekly") {
      return "Weekly on " + toStartCase(schedule.weekDay);
    }
    if (schedule.frequency === "monthly") {
      return (
        getDateSuffix(parseInt(schedule?.monthDate || "")) + " of every month"
      );
    }
    if (schedule.frequency === "one_time") {
      return "One time on " + schedule.oneTimeDate?.toDateString();
    }
  };

  const sortScheduleTimes = (items: Time[]): Time[] => {
    // Helper function to convert weekdays to numbers for sorting
    const weekdayToNumber = (day: string): number => {
      const days = [
        "sunday",
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday",
      ];
      return days.indexOf(day.toLowerCase());
    };

    // Helper function to compare times
    const compareTimes = (a: string, b: string): number => {
      const timeA = a.split(":").map(Number);
      const timeB = b.split(":").map(Number);
      return timeA[0] - timeB[0] || timeA[1] - timeB[1];
    };

    // Main sort function
    return items
      .sort((a, b) => {
        // Sort by frequency order
        const order = ["daily", "weekly", "monthly", "one_time"];
        const freqOrder =
          order.indexOf(a.frequency) - order.indexOf(b.frequency);
        if (freqOrder !== 0) return freqOrder;

        switch (a.frequency) {
          case "daily":
          case "weekly":
            // For weekly, additionally sort by weekday
            if (a.frequency === "weekly") {
              const dayOrder =
                weekdayToNumber(a.weekDay!) - weekdayToNumber(b.weekDay!);
              if (dayOrder !== 0) return dayOrder;
            }
            // Then sort times within the day
            return compareTimes(a.time[0], b.time[0]);
          case "monthly":
            // Sort by monthDate then by time
            const dateOrder = Number(a.monthDate) - Number(b.monthDate);
            if (dateOrder !== 0) return dateOrder;
            return compareTimes(a.time[0], b.time[0]);
          case "one_time":
            // Sort by oneTimeDate then by time
            const dateA = new Date(a.oneTimeDate!);
            const dateB = new Date(b.oneTimeDate!);
            const timeOrder = dateA.getTime() - dateB.getTime();
            if (timeOrder !== 0) return timeOrder;
            return compareTimes(a.time[0], b.time[0]);
        }
        return 0;
      })
      .map((item) => ({ ...item, time: item.time.sort(compareTimes) })); // Also sort times in ascending order
  };

  return (
    <div className={cn("border rounded-md pt-2.5")}>
      <div className="flex flex-col">
        <div className="flex justify-between px-2.5 pb-2.5">
          <div className="flex flex-col">
            <div className="font-semibold text-sm text-primary">
              {item.channel?.channel_name}
            </div>
            <div className="text-xs text-muted-foreground">
              {toStartCase(item.connection[0]?.channel)} -{" "}
              {item.connection[0]?.connection_name}
            </div>
          </div>
          <div>
            <Button
              onClick={() => setExpand(!expand)}
              size="sm"
              variant="ghost"
            >
              <Badge>{sortedTimes.length}</Badge>
              <ChevronRight
                size={22}
                className={cn(
                  "text-muted-foreground transition-all",
                  expand && "rotate-90"
                )}
              />
            </Button>
          </div>
        </div>

        <Separator />

        <div className="px-2.5 flex flex-col">
          {/* {dailyTimes && (
            <div className="flex justify-between items-center">
              <p className="text-sm font-medium">{formatTime(dailyTimes)}</p>
              <p className="text-xs text-muted-foreground flex items-center leading-normal">
                <Clock size={13} className={"text-muted-foreground me-1"} />
                {dailyTimes.time.join(" | ")}
              </p>
            </div>
          )} */}

          <div
            className={cn(
              `transition-all duration-500 ease-in-out py-2.5 overflow-hidden ${
                expand ? `max-h-screen` : "max-h-[40px]"
              }`
            )}
          >
            {sortedTimes.map((freq, index) => (
              <div
                key={`${index}-${freq.frequency}`}
                className="flex justify-between items-center pb-2 last:mb-0 last:pb-0"
              >
                <p className="text-sm font-medium">{formatTime(freq)}</p>
                <p className="text-xs text-muted-foreground flex items-center mt-0.5">
                  <Clock size={13} className={"text-muted-foreground me-1"} />
                  {freq.time.join(" | ")}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScheduleItem;
