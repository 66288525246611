import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ArrowLeft, Copy } from "lucide-react";
import { formatDistanceToNow } from "date-fns";
import { Button } from "components/ui/button";
import { useAppState } from "context/AppContext";
import { useToast } from "components/ui/use-toast";
import ImageModalViewer from "components/Dialogs/ImageDialog";
import { Account, Agency, Client, Notification } from "types";
import apiClient from "api";

type ParsedNotificationData = {
  group: string;
  group_description: string | null;
  login_time: number;
  page: string;
  post_completed: number;
  replies: number;
  replies_completed: number;
  task_id: number;
  task_type: number;
  tasks_completed: number;
  tasks_remaining: number;
};

type Props = {
  notification: Notification;
  goBack: () => void;
  fetchNotifications: () => void;
};

const NotificationDetails = ({
  notification,
  goBack,
  fetchNotifications,
}: Props) => {
  const { toast } = useToast();
  const { dispatch } = useAppState();
  const navigate = useNavigate();

  const [isClearing, setIsClearing] = useState(false);

  const parseJsonForUI = (jsonString: string) => {
    try {
      const parsedObject = JSON.parse(jsonString);
      return parsedObject;
    } catch (error) {
      console.error("Invalid JSON:", error);
      return {};
    }
  };

  const formatJsonForUI = (obj: ParsedNotificationData) => {
    return JSON.stringify(obj, null, 2);
  };

  const handleAccountClick = useCallback(() => {
    if (notification.account_name) {
      // Set all three properties
      dispatch({
        type: "SET_ADMIN_AGENCY",
        payload: notification.agency_name
          ? ({
              access: "rw",
              agency_name: notification.agency_name,
              agency_id: notification.agency_id,
            } as Agency)
          : undefined,
      });
      dispatch({
        type: "SET_ADMIN_CLIENT",
        payload: notification.client_name
          ? ({
              access: "rw",
              customer_name: notification.client_name,
              customer_id: notification.client_id,
            } as Client)
          : undefined,
      });
      dispatch({
        type: "SET_ADMIN_ACCOUNT",
        payload: notification.creative_account
          ? ({
              account_name: notification.account_name,
              account_id: notification.creative_account,
            } as Account)
          : undefined,
      });

      navigate("/admin/account/summary");
    }
    // eslint-disable-next-line
  }, [
    dispatch,
    navigate,
    notification.agency_name,
    notification.agency_id,
    notification.client_name,
    notification.client_id,
    notification.account_name,
    notification.source_id,
  ]);

  const handleRemoveNotification = async () => {
    if (!notification) return;
    setIsClearing(true);
    try {
      await apiClient.post(
        "/admin_creative_clear_notification",
        {},
        {
          headers: {
            request: JSON.stringify({
              notification_id: notification.notification_id,
            }),
          },
        }
      );
      fetchNotifications();
      goBack();
    } catch (error) {
      toast({
        description: "Failed to clear notifications",
        variant: "destructive",
      });
    } finally {
      setIsClearing(false);
    }
  };

  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center mb-2">
        <div className="flex items-center w-full">
          <Button onClick={goBack} variant="ghost" size="icon">
            <ArrowLeft className="size-5" />
          </Button>
          <h2 className="text-lg font-semibold ml-2">
            {notification.notification_name} ({notification.notification_id})
          </h2>
        </div>
        <div className="flex items-center space-x-2">
          {notification.can_clear === 1 && (
            <Button
              disabled={isClearing}
              onClick={handleRemoveNotification}
              size="sm"
              variant="destructive"
            >
              Close
            </Button>
          )}
        </div>
      </div>

      <div className="font-medium">
        <span className="text-muted-foreground text-sm">
          {formatDistanceToNow(new Date(notification.reference_date))} ago
        </span>
        {notification.notification_name && (
          <span className="text-primary text-sm">
            {" - "}
            {notification.notification_name}
          </span>
        )}

        <p className="text-xs mt-1">{notification.notification_text}</p>
      </div>

      {/* Clickable Account Name */}
      {notification.account_name && (
        <p
          className="text-sm text-gray-500 dark:text-gray-400 cursor-pointer hover:underline"
          onClick={handleAccountClick}
        >
          {notification.account_name}
        </p>
      )}

      {/* Screenshot Image */}
      {notification.screenshot_url && (
        <>
          <div className="font-semibold text-sm">Screenshot</div>
          <ImageModalViewer
            imageUrl={notification.screenshot_url}
            altText="Notification Screenshot"
            title="Screenshot preview"
            description={notification.notification_text}
            className="w-52 h-auto mb-4 rounded"
          />
        </>
      )}
      {/* Parsed JSON Data */}
      {notification?.html_response && (
        <>
          <div className="font-semibold text-sm">Task details</div>
          <div className="relative">
            <pre className="bg-secondary p-4 rounded-lg text-xs overflow-auto">
              {formatJsonForUI(
                parseJsonForUI(
                  notification.html_response
                ) as ParsedNotificationData
              )}
            </pre>
            <Button
              onClick={() => {
                const parsedData = parseJsonForUI(notification.html_response);
                if (parsedData) {
                  navigator.clipboard.writeText(
                    JSON.stringify(parsedData, null, 2)
                  );
                }
                toast({
                  description: "Task content copied",
                });
              }}
              size="icon"
              className="absolute top-2 right-2 text-xs px-2 py-1"
            >
              <Copy className="size-4" />
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default NotificationDetails;
