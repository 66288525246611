import { Notification } from "types";

export interface NotificationsState {
	notifications: Notification[];
	filteredNotifications: Notification[];
	sortOrder: "asc" | "desc";
	selectedNotificationType: number;
	searchQuery: string;
	selectedNotifications: number[];
	isAllSelected: boolean;
}

export const initialNotificationsState: NotificationsState = {
	notifications: [],
	filteredNotifications: [],
	sortOrder: "desc",
	selectedNotificationType: -1,
	searchQuery: "",
	selectedNotifications: [],
	isAllSelected: false,
};

export type NotificationsAction =
	| { type: "SET_NOTIFICATIONS"; payload: Notification[] }
	| { type: "SET_FILTERED_NOTIFICATIONS"; payload: Notification[] }
	| { type: "SET_SORT_ORDER"; payload: "asc" | "desc" }
	| { type: "SET_SELECTED_NOTIFICATION_TYPE"; payload: number }
	| { type: "SET_SEARCH_QUERY"; payload: string }
	| { type: "SET_SELECTED_NOTIFICATIONS"; payload: number[] }
	| { type: "SET_IS_ALL_SELECTED"; payload: boolean }

const notificationsReducer = (
	state: NotificationsState,
	action: NotificationsAction
): NotificationsState => {
	switch (action.type) {
		case "SET_NOTIFICATIONS":
			return {
				...state,
				notifications: action.payload,
			};
		case "SET_FILTERED_NOTIFICATIONS":
			return {
				...state,
				filteredNotifications: action.payload,
			};
		case "SET_SORT_ORDER":
			return {
				...state,
				sortOrder: action.payload,
			};
		case "SET_SELECTED_NOTIFICATION_TYPE":
			return {
				...state,
				selectedNotificationType: action.payload,
			};
		case "SET_SEARCH_QUERY":
			return {
				...state,
				searchQuery: action.payload,
			};
		case "SET_SELECTED_NOTIFICATIONS":
			return {
				...state,
				selectedNotifications: action.payload,
			};
		case "SET_IS_ALL_SELECTED":
			return {
				...state,
				isAllSelected: action.payload,
			};
		default:
			return state;
	}
};

export default notificationsReducer;
