import { useEffect, useState } from "react";
import { ChevronDown, ChevronRight } from "lucide-react";
import { EyeClosedIcon, EyeOpenIcon, ReloadIcon } from "@radix-ui/react-icons";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "components/ui/card";
import { Input } from "components/ui/input";
import { Label } from "components/ui/label";
import { Button } from "components/ui/button";
import Breadcrumbs from "components/Shared/Breadcrumbs";

import { useAppState } from "context/AppContext";
import AccountSelect from "../AccountSelect";
import { DataTableAuthors } from "../Authors/data";
import apiClient from "api";

const WordpressSite = () => {
  const { state, dispatch } = useAppState();
  const { agency, client, account } = state.admin;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [authors, setAuthors] = useState([]);
  const [siteUrl, setSiteUrl] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isExpand, setIsExpand] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (!account) return;
    const timeout = setTimeout(getAuthors, 200);
    setUsername(account.wordpress_username || "");
    setSiteUrl(account.primary_url || "");
    setPassword(account.application_password || "");
    return () => clearTimeout(timeout);
    // eslint-disable-next-line
  }, [account]);

  const addWordpressSite = async (evt: React.FormEvent) => {
    evt.preventDefault();
    if (!account) return;
    setIsSubmitting(true);

    try {
      await apiClient.post(
        "/admin_creative_wordpress_site",
        {},
        {
          headers: {
            request: JSON.stringify({
              creative_account_id: account?.account_id,
              site_url: siteUrl,
              username: username,
              password: password,
            }),
          },
        }
      );
      dispatch({ type: "SET_ADMIN_ACCOUNT_REFRESH", payload: true });
      setIsSubmitting(false);
      setIsEditing(false);
    } catch (error) {
      setIsEditing(false);
      setIsSubmitting(false);
    }
  };

  const getAuthors = async () => {
    setLoading(true);
    try {
      const { data } = await apiClient.get("/admin_creative_get_users", {
        headers: {
          request: JSON.stringify({
            creative_account_id: account?.account_id,
          }),
        },
      });
      setAuthors(data?.message || {});
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <div>
      <Breadcrumbs
        // icon={
        //   <Button size="icon" variant="secondary" className="mr-2">
        //     <PanelRightClose className="size-4" />
        //   </Button>
        // }
        routes={[
          { title: agency?.agency_name || "", path: "/admin/agencies" },
          { title: client?.customer_name || "", path: "/admin/clients" },
          { title: account?.account_name || "", path: "/admin/accounts" },
          { title: "Newsportal", path: "" },
        ]}
      />
      <div className="space-y-2 mt-8">
        <div className="flex justify-between items-center">
          <h3 className="scroll-m-20 text-2xl font-bold tracking-tight">
            Newsportal
          </h3>
          <AccountSelect />
        </div>
        {/* <p className="text-base text-muted-foreground">
      List of all campaigns edit/delete campaign from here.
    </p> */}
      </div>

      <div className="mt-10">
        <Card className="w-full mt-4">
          <CardHeader>
            <div className="flex justify-between items-center">
              <CardTitle>Website configuration</CardTitle>
              <Button
                onClick={() => setIsExpand(!isExpand)}
                size="sm"
                variant="secondary"
              >
                {isExpand ? (
                  <ChevronDown className="size-4" />
                ) : (
                  <ChevronRight className="size-4" />
                )}
              </Button>
            </div>
          </CardHeader>
          {isExpand && (
            <CardContent>
              <form>
                <div className="grid w-full items-center gap-4">
                  <div className="flex flex-col space-y-1.5">
                    <Label htmlFor="site_url">Site URL</Label>
                    {isEditing ? (
                      <Input
                        required
                        type="url"
                        onBlur={(evt) => {
                          const value = evt.target.value;
                          if (!value.includes("https")) {
                            setSiteUrl(`https://${value}`);
                          }
                        }}
                        autoFocus={isEditing}
                        value={`${siteUrl}`}
                        onChange={(e) => setSiteUrl(e.target.value)}
                        id="site_url"
                        placeholder="www.wordpress-site.com"
                      />
                    ) : (
                      <p>{account?.primary_url || "-"}</p>
                    )}
                  </div>
                  <div className="flex flex-col space-y-1.5">
                    <Label htmlFor="username">Username</Label>
                    {isEditing ? (
                      <Input
                        required
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        id="username"
                        placeholder="John Doe"
                      />
                    ) : (
                      <p>{account?.wordpress_username || "-"}</p>
                    )}
                  </div>
                  <div className="flex flex-col space-y-1.5">
                    <Label htmlFor="password">Password</Label>
                    {isEditing ? (
                      <Input
                        required
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        id="password"
                        placeholder="••••••••••"
                      />
                    ) : (
                      <span>
                        {account?.application_password ? (
                          <span className="flex items-center justify-between">
                            {showPassword
                              ? account?.application_password || "-"
                              : account?.application_password
                              ? "••••••••••"
                              : "-"}

                            {!showPassword ? (
                              <Button
                                type="button"
                                title="Show password"
                                size="icon"
                                variant="ghost"
                                onClick={() => setShowPassword(true)}
                              >
                                <EyeOpenIcon className="size-4" />
                              </Button>
                            ) : (
                              <Button
                                type="button"
                                title="Hide password"
                                size="icon"
                                variant="ghost"
                                onClick={() => setShowPassword(false)}
                              >
                                <EyeClosedIcon className="size-4" />
                              </Button>
                            )}
                          </span>
                        ) : (
                          "_"
                        )}
                      </span>
                    )}
                  </div>
                </div>
              </form>
            </CardContent>
          )}
          {isExpand && (
            <CardFooter className="flex justify-between">
              {isEditing ? (
                <Button
                  type="button"
                  disabled={isSubmitting}
                  variant={"outline"}
                  onClick={() => setIsEditing(false)}
                >
                  Cancel
                </Button>
              ) : (
                <div></div>
              )}
              {isEditing ? (
                <Button
                  type="submit"
                  onClick={addWordpressSite}
                  disabled={!username || !siteUrl || !password || isSubmitting}
                >
                  Save configuration{" "}
                  {isSubmitting && (
                    <ReloadIcon className="ml-2 animate-spin size-4" />
                  )}
                </Button>
              ) : (
                <Button type="button" onClick={() => setIsEditing(true)}>
                  Edit configuration
                </Button>
              )}
            </CardFooter>
          )}
        </Card>
      </div>

      <div className="mt-10">
        <div className="space-y-2">
          <div className="flex justify-between items-center">
            <h3 className="scroll-m-20 text-xl font-bold tracking-tight">
              Authors
            </h3>
          </div>
        </div>
        <DataTableAuthors
          fetchData={getAuthors}
          data={Object.values(authors)}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default WordpressSite;
