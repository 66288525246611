import { useEffect, useState } from "react";
import Breadcrumbs from "components/Shared/Breadcrumbs";
import { useAppState } from "context/AppContext";
import AddAccountDialog from "./AddAccountDialog";
import { DataTableAccounts } from "./data";
import { Account } from "types";
import apiClient from "api";

const Accounts = () => {
  const { state } = useAppState();
  const { agency, client } = state.admin;

  const [data, setData] = useState<Account[]>([]);
  const [loading, setLoading] = useState(true);
  const [openAdd, setOpenAdd] = useState(false);

  useEffect(() => {
    if (!client) return;

    const timeout = setTimeout(() => {
      fetchAccounts();
    }, 200);

    return () => clearTimeout(timeout);
    // eslint-disable-next-line
  }, [client]);

  const fetchAccounts = async () => {
    try {
      const { data } = await apiClient.get("/admin_creative_get_accounts", {
        headers: {
          request: JSON.stringify({ client_id: client?.customer_id }),
        },
      });
      setData(data?.message || []);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("Error", error);
    }
  };

  return (
    <div>
      <Breadcrumbs
        // icon={
        //   <Button size="icon" variant="secondary" className="mr-2">
        //     <PanelRightClose className="size-4" />
        //   </Button>
        // }
        routes={[
          { title: agency?.agency_name || "", path: "/admin/agencies" },
          { title: client?.customer_name || "", path: "/admin/clients" },
          { title: "Accounts", path: "" },
        ]}
      />
      <div className="space-y-2 mt-8">
        <h3 className="scroll-m-20 text-2xl font-bold tracking-tight">
          Accounts
        </h3>
        <p className="text-base text-muted-foreground">
          Add edit/update and delete accounts.
        </p>
      </div>

      <div>
        {!client ? (
          <p className="text-xl text-muted-foreground text-center mt-10">
            Please select a client to view all the accounts.
          </p>
        ) : (
          <DataTableAccounts
            data={data}
            loading={loading}
            setOpenAdd={setOpenAdd}
            fetchData={fetchAccounts}
          />
        )}
      </div>

      <AddAccountDialog
        open={openAdd}
        onOpenChange={setOpenAdd}
        fetchData={fetchAccounts}
      />
    </div>
  );
};

export default Accounts;
