import React, { useEffect, useState } from "react";
import { RefreshCw } from "lucide-react";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "components/ui/dialog";
import { Label } from "components/ui/label";
import { Button } from "components/ui/button";
import { Input } from "components/ui/input";
import { Agency } from "types";
import apiClient from "api";

type Props = {
  open: boolean;
  editData?: Agency | null;
  fetchData: () => void;
  onOpenChange: (value: boolean) => void;
};

const AddAgencyDialog = ({
  open,
  editData,
  fetchData,
  onOpenChange,
}: Props) => {
  const [submitting, setSubmitting] = useState(false);
  const [agencyName, setAgencyName] = useState("");

  useEffect(() => {
    if (!editData) return;
    setAgencyName(editData.agency_name || "");
  }, [editData]);

  const addAgency = async (evt: React.FormEvent) => {
    evt.preventDefault();
    setSubmitting(true);
    if (Boolean(editData)) {
      try {
        await apiClient.post(
          "/admin_creative_update_agency",
          {},
          {
            headers: {
              request: JSON.stringify({
                agency_id: editData?.agency_id,
                agency_name: agencyName,
              }),
            },
          }
        );
        onOpenChange(false);
        fetchData();
      } catch (error) {
        console.log("Error:", error);
      } finally {
        setSubmitting(false);
      }
    } else {
      try {
        await apiClient.post(
          "/admin_creative_add_agency",
          {},
          {
            headers: {
              request: JSON.stringify({
                agency_name: agencyName,
              }),
            },
          }
        );

        onOpenChange(false);
        fetchData();
      } catch (error) {
        console.log("Error:", error);
      } finally {
        setSubmitting(false);
      }
    }
  };

  return (
    <>
      <Dialog open={open} onOpenChange={onOpenChange}>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>{!!editData ? "Edit" : "Add"} agency</DialogTitle>
          </DialogHeader>

          <form className="grid gap-4" onSubmit={addAgency}>
            <div className="grid gap-2">
              <Label htmlFor="agency-name">Agency name</Label>
              <div className="relative">
                <Input
                  id="agency-name"
                  required
                  value={agencyName}
                  onChange={(evt) => {
                    const val = evt.target.value;
                    setAgencyName(val);
                  }}
                />
              </div>
            </div>

            <DialogFooter>
              <DialogClose asChild>
                <Button
                  disabled={submitting}
                  type="button"
                  size="sm"
                  variant="outline"
                >
                  Cancel
                </Button>
              </DialogClose>
              <Button disabled={submitting} type="submit" size="sm">
                {submitting && (
                  <RefreshCw className="size-4 animate-spin mr-2" />
                )}{" "}
                Save
              </Button>
            </DialogFooter>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddAgencyDialog;
