import React, { useEffect, useState } from "react";

interface NumberCounterProps {
  targetNumber: number;
  duration?: number;
  className?: string;
}

const NumberCounter: React.FC<NumberCounterProps> = ({
  targetNumber,
  duration = 2000,
  className = "",
}) => {
  const [count, setCount] = useState<number>(0);

  useEffect(() => {
    let start = 0;
    const end = targetNumber;
    if (start === end) return; // If the target number is 0, don't animate

    let startTime: number | null = null;

    // Define the increment function
    const increment = (timestamp: number) => {
      if (!startTime) startTime = timestamp;
      const progress = Math.min((timestamp - startTime) / duration, 1); // Calculate progress
      setCount(Math.floor(progress * (end - start) + start)); // Update count

      if (progress < 1) {
        requestAnimationFrame(increment); // Continue animation
      }
    };

    requestAnimationFrame(increment); // Start animation

    // Cleanup function to stop the animation if the component is unmounted
    return () => setCount(0); // Reset count on unmount
  }, [targetNumber, duration]);

  return <div className={className}>{count}</div>;
};

export default NumberCounter;
