import LinkRenderer from "./LinkRenderer";

interface TextRendererProps {
  longText: string;
  maxWidth?: string;
  onClick?: () => void;
  showAll?: boolean;
  hasLink?: boolean;
}

function CustomLongTextRenderer({
  longText,
  maxWidth,
  hasLink,
  onClick,
  showAll = false,
}: TextRendererProps) {
  const isExpanded = showAll;

  return (
    <div title={longText} className="flex items-center space-x-1">
      <span
        className={`${
          isExpanded
            ? `${maxWidth || "max-w-xl"}`
            : `${maxWidth || "max-w-xl"} truncate`
        } ${onClick ? "hover:underline cursor-pointer" : ""}`}
        onClick={hasLink ? () => null : onClick && onClick}
      >
        {hasLink ? (
          <LinkRenderer showAll={isExpanded} link={longText} />
        ) : (
          longText
        )}
      </span>{" "}
    </div>
  );
}

export default CustomLongTextRenderer;
