import * as React from "react";
import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  // getSortedRowModel,
  // getFacetedRowModel,
  // getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "components/ui/table";

import { DataTablePagination } from "./data-table-pagination";
import { ScrollArea, ScrollBar } from "components/ui/scroll-area";
import { OverviewPost } from "types";

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  setChartsData: React.Dispatch<
    React.SetStateAction<{ id: number; date: string; clickThruRate: number }[]>
  >;
  data: TData[];
  isLoading: boolean;
}

export function DataTable<TData, TValue>({
  isLoading,
  columns,
  data,
  setChartsData,
}: DataTableProps<TData, TValue>) {
  const [rowSelection, setRowSelection] = React.useState({});
  const [columnVisibility, setColumnVisibility] =
    React.useState<VisibilityState>({});
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );

  const sortedData = React.useMemo(() => {
    if (!sorting.length) return data;

    return [...data].sort((a, b) => {
      for (const sort of sorting) {
        const { id, desc } = sort;

        let aValue = a[id as keyof TData];
        let bValue = b[id as keyof TData];

        if (aValue > bValue) return desc ? -1 : 1;
        if (aValue < bValue) return desc ? 1 : -1;
      }
      return 0;
    });
  }, [data, sorting]);

  const table = useReactTable({
    data: sortedData, // Pass the manually sorted data here
    columns,
    state: {
      sorting,
      rowSelection,
      columnFilters,
      columnVisibility,
    },
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    // Remove getSortedRowModel to disable internal sorting
  });

  React.useEffect(() => {
    const filteredData: OverviewPost[] | TData[] = table
      .getRowModel()
      .flatRows.map((r) => r.original);

    setChartsData(
      filteredData.map((row: any) => ({
        date: new Date(row.date_posted).toISOString().split("T")[0],
        clickThruRate: row.click_thru_rate,
        id: row.post_id,
      }))
    );

    // eslint-disable-next-line
  }, [table.getRowModel()]);

  return (
    <div className="space-y-4 rounded-lg border shadow">
      <div className="rounded-lg">
        <ScrollArea className="h-[400px]">
          <Table>
            <TableHeader>
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <TableHead key={header.id} colSpan={header.colSpan}>
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                      </TableHead>
                    );
                  })}
                </TableRow>
              ))}
            </TableHeader>
            <TableBody>
              {table.getRowModel().rows?.length ? (
                table.getRowModel().rows.map((row) => (
                  <TableRow
                    key={row.id}
                    data-state={row.getIsSelected() && "selected"}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <TableCell key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={columns.length}
                    className="h-24 text-center"
                  >
                    {isLoading ? (
                      <div className="min-h-60 flex items-center justify-center">
                        <div className="flex flex-col justify-center items-center">
                          <div className="size-8 border-2 border-t-2 border-muted border-t-primary rounded-full animate-spin"></div>
                          <span className="text-sm text-center mt-4 text-primary">
                            Loading posts...
                          </span>
                        </div>
                      </div>
                    ) : (
                      "No results."
                    )}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <ScrollBar orientation="horizontal" />
        </ScrollArea>
      </div>
      <div className="p-4">
        <DataTablePagination table={table} />
      </div>
    </div>
  );
}
