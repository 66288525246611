import { useEffect, useState } from "react";
import { Button } from "components/ui/button";

import { useAppState } from "context/AppContext";
import AddSubjectsDialog from "./AddSubjectsDialog";
import { DataTableBlogSubjects } from "./data";
import { BlogSetting, BlogSubject } from "types";
import apiClient from "api";

type Props = {
  selectedBlog: BlogSetting | null;
};

const BlogSubjects = ({ selectedBlog }: Props) => {
  const { state } = useAppState();
  const { account } = state.admin;

  const [loading, setLoading] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);

  const [data, setData] = useState<BlogSubject[]>([]);

  useEffect(() => {
    setData([]);
    if (!account || !selectedBlog) return;
    const timeout = setTimeout(fetchBlogSubjects, 200);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line
  }, [account, selectedBlog]);

  const fetchBlogSubjects = async () => {
    setLoading(true);
    try {
      const { data } = await apiClient.get(
        "/admin_creative_get_blog_subjects",
        {
          headers: {
            request: JSON.stringify({
              blog_post_id: selectedBlog?.blog_post_settings_id,
            }),
          },
        }
      );
      setData(data.message || []);
    } catch (error) {
      console.log("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="mt-8">
        <div className="flex justify-between items-center">
          <h3 className="scroll-m-20 text-2xl font-bold tracking-tight">
            Subjects
          </h3>
          <Button size="sm" onClick={() => setOpenAdd(true)}>
            Add subject
          </Button>
        </div>
      </div>

      <DataTableBlogSubjects
        data={data}
        loading={loading}
        fetchData={fetchBlogSubjects}
        selectedBlog={selectedBlog}
      />

      <AddSubjectsDialog
        open={openAdd}
        selectedBlog={selectedBlog}
        fetchData={fetchBlogSubjects}
        onOpenChange={setOpenAdd}
      />
    </div>
  );
};

export default BlogSubjects;
