import { Table } from "@tanstack/react-table";
import { Button } from "components/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "components/ui/select";
import { useState, useEffect } from "react";

interface DataTablePaginationProps<TData> {
  table: Table<TData>;
  showSelectedCount?: boolean;
}

export function AdminTablePagination<TData>({
  table,
  showSelectedCount,
}: DataTablePaginationProps<TData>) {
  const totalRows = table.getFilteredRowModel().rows.length;

  // Set initial state for page size; default to "All" (show all rows).
  const [selectedPageSize, setSelectedPageSize] = useState<string>("All");

  useEffect(() => {
    // Ensure the table starts with all rows displayed when "All" is selected.
    if (selectedPageSize === "All") {
      table.setPageSize(totalRows);
    }
  }, [selectedPageSize, totalRows, table]);

  const handlePageSizeChange = (value: string) => {
    if (value === "All") {
      table.setPageSize(totalRows); // Show all rows
      table.setPageIndex(0); // Reset to first page
    } else {
      table.setPageSize(Number(value));
    }
    setSelectedPageSize(value); // Persist the selected value
  };

  return (
    <div className="flex items-center justify-end space-x-2 py-4">
      {showSelectedCount &&
      table.getFilteredSelectedRowModel().rows.length > 0 ? (
        <div className="flex-1 text-sm text-muted-foreground">
          {table.getFilteredSelectedRowModel().rows.length} row(s) selected
        </div>
      ) : (
        <div className="flex-1 text-sm text-muted-foreground">
          {selectedPageSize === "All"
            ? `Showing all ${totalRows} row(s)`
            : `Total ${totalRows} row(s)`}
        </div>
      )}

      <div className="flex items-center space-x-6 lg:space-x-8">
        <div className="flex items-center space-x-2">
          <p className="hidden md:inline text-sm font-medium">Rows per page</p>
          <Select value={selectedPageSize} onValueChange={handlePageSizeChange}>
            <SelectTrigger className="h-8 w-[90px]">
              <SelectValue placeholder={selectedPageSize} />
            </SelectTrigger>
            <SelectContent side="top">
              {[10, 20, 30, 40, 50, 100].map((pageSize) => (
                <SelectItem key={pageSize} value={`${pageSize}`}>
                  {pageSize}
                </SelectItem>
              ))}
              <SelectItem value="All">All</SelectItem>
            </SelectContent>
          </Select>
        </div>
        {selectedPageSize !== "All" && (
          <div className="flex w-[100px] items-center justify-center text-sm font-medium">
            Page {table.getState().pagination.pageIndex + 1} of{" "}
            {table.getPageCount()}
          </div>
        )}
        <div className="space-x-2">
          <Button
            variant="outline"
            size="sm"
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            Previous
          </Button>
          <Button
            variant="outline"
            size="sm"
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            Next
          </Button>
        </div>
      </div>
    </div>
  );
}
