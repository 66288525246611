import { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { Card, CardContent, CardHeader, CardTitle } from "components/ui/card";
import NumberCounter from "components/Shared/NumberCounter";
import { Skeleton } from "components/ui/skeleton";

import { useAppState } from "context/AppContext";
import { Demographic } from "./Demographic";
import { PortalVisitors } from "./PortalVisitors";
import { VisitorsSource } from "./VisitorsSource";
import { TrafficSource } from "./TrafficSource";
import { Visitor } from "types";
import apiClient from "api";

const cardsData = [
  {
    title: "Wordpress",
    route: "/overview/website",
    icon: (
      <svg
        className="w-5 h-5 text-gray-800 dark:text-white"
        aria-hidden="true"
        fill="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
        <g
          id="SVGRepo_tracerCarrier"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></g>
        <g id="SVGRepo_iconCarrier">
          <path d="M19.891 7.788a8.966 8.966 0 0 1 1.099 4.313 8.986 8.986 0 0 1-4.47 7.771l2.746-7.939c.513-1.282.684-2.309.684-3.219a7.165 7.165 0 0 0-.059-.926m-6.651.087a14.14 14.14 0 0 0 1.026-.088c.485-.063.428-.775-.056-.749 0 0-1.463.112-2.4.112-.887 0-2.375-.125-2.375-.125-.487-.024-.55.713-.061.738 0 0 .449.052.938.075l1.399 3.838-1.975 5.899-3.274-9.724a17.006 17.006 0 0 0 1.028-.083c.487-.063.43-.775-.055-.747 0 0-1.455.115-2.395.115-.167 0-.365-.007-.575-.013C6.093 4.726 8.862 3.113 12 3.113c2.341 0 4.471.894 6.071 2.36-.038-.002-.076-.008-.117-.008-.883 0-1.51.77-1.51 1.596 0 .741.427 1.369.883 2.108.343.601.742 1.37.742 2.481 0 .763-.295 1.662-.685 2.899l-.896 2.987-3.25-9.675.002.014zM12 21.087a8.983 8.983 0 0 1-2.54-.364l2.697-7.838 2.763 7.572c.021.044.042.085.065.124a9.016 9.016 0 0 1-2.985.508m-8.99-8.988a8.94 8.94 0 0 1 .778-3.658l4.287 11.749a8.993 8.993 0 0 1-5.065-8.091m8.99-10c-5.513 0-10 4.487-10 10s4.487 10 10 10 10-4.487 10-10-4.487-10-10-10"></path>
        </g>
      </svg>
    ),
    post_count: 0,
  },
  {
    title: "LinkedIn",
    route: "/overview/linkedin",
    icon: (
      <svg
        className="w-6 h-6 text-gray-800 dark:text-white"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="currentColor"
        viewBox="0 0 24 24"
      >
        <path
          fillRule="evenodd"
          d="M12.51 8.796v1.697a3.738 3.738 0 0 1 3.288-1.684c3.455 0 4.202 2.16 4.202 4.97V19.5h-3.2v-5.072c0-1.21-.244-2.766-2.128-2.766-1.827 0-2.139 1.317-2.139 2.676V19.5h-3.19V8.796h3.168ZM7.2 6.106a1.61 1.61 0 0 1-.988 1.483 1.595 1.595 0 0 1-1.743-.348A1.607 1.607 0 0 1 5.6 4.5a1.601 1.601 0 0 1 1.6 1.606Z"
          clipRule="evenodd"
        />
        <path d="M7.2 8.809H4V19.5h3.2V8.809Z" />
      </svg>
    ),
    post_count: 0,
  },
  {
    title: "Facebook",
    route: "/overview/facebook",
    icon: (
      <svg
        className="w-6 h-6 text-gray-800 dark:text-white"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="currentColor"
        viewBox="0 0 24 24"
      >
        <path
          fillRule="evenodd"
          d="M13.135 6H15V3h-1.865a4.147 4.147 0 0 0-4.142 4.142V9H7v3h2v9.938h3V12h2.021l.592-3H12V6.591A.6.6 0 0 1 12.592 6h.543Z"
          clipRule="evenodd"
        />
      </svg>
    ),
    post_count: 0,
  },
  {
    title: "X (Twitter)",
    route: "/overview/twitter",
    icon: (
      <svg
        className="w-5 h-5 text-gray-800 dark:text-white"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="currentColor"
        viewBox="0 0 24 24"
      >
        <path d="M13.795 10.533 20.68 2h-3.073l-5.255 6.517L7.69 2H1l7.806 10.91L1.47 22h3.074l5.705-7.07L15.31 22H22l-8.205-11.467Zm-2.38 2.95L9.97 11.464 4.36 3.627h2.31l4.528 6.317 1.443 2.02 6.018 8.409h-2.31l-4.934-6.89Z" />
      </svg>
    ),
    post_count: 0,
  },
];

const Overview = () => {
  const { state } = useAppState();
  const { agency, account, client } = state.user;

  const [loading, setLoading] = useState(false);
  const [loadingStats, setLoadingStats] = useState(false);
  const [newsPortalVisitors, setNewsPortalVisitors] = useState<Visitor[]>([]);
  const [clientSiteVisitors, setClientSiteVisitors] = useState<Visitor[]>([]);

  const volumeController = useRef<AbortController | null>(null);
  const statsController = useRef<AbortController | null>(null);

  const debounceTimer = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      if (debounceTimer.current) clearTimeout(debounceTimer.current);

      debounceTimer.current = setTimeout(async () => {
        setLoading(true);
        setLoadingStats(true);

        const request = { id: 0, level: 1 };
        if (agency) {
          request["id"] = agency.agency_id;
          request["level"] = 2;
        }
        if (client) {
          request["id"] = client.customer_id;
          request["level"] = 3;
        }
        if (account) {
          request["id"] = account.account_id;
          request["level"] = 4;
        }

        // Cancel previous volume requests
        if (volumeController.current) volumeController.current.abort();
        volumeController.current = new AbortController();

        try {
          // Fetch volumes
          const { data } = await apiClient.get("/creative_get_volumes", {
            signal: volumeController.current.signal,
            headers: {
              request: JSON.stringify(request),
            },
          });

          const allVisitors: Visitor[] = data?.message ?? [];
          const newsVisitor = allVisitors.filter(
            (visitor) => visitor.volume_type === 0
          );
          const siteVisitor = allVisitors.filter(
            (visitor) => visitor.volume_type === 1
          );
          setNewsPortalVisitors(newsVisitor);
          setClientSiteVisitors(siteVisitor);
        } catch (error) {
          if (error instanceof Error) {
            console.log("Volume request error:", error.message);
          } else {
            console.log("Unknown error during volume request:", error);
          }
        } finally {
          setLoading(false);
        }

        // Cancel previous stats requests
        if (statsController.current) statsController.current.abort();
        statsController.current = new AbortController();

        try {
          // Fetch stats
          const { data: stats } = await apiClient.get(
            "creative_get_post_stats",
            {
              signal: statsController.current.signal,
              headers: {
                request: JSON.stringify(request),
              },
            }
          );

          cardsData[0].post_count = stats.message[0]?.post_quantity || 0;
          cardsData[1].post_count = stats.message[0]?.linkedin_quantity || 0;
          cardsData[2].post_count = stats.message[0]?.facebook_quantity || 0;
          cardsData[3].post_count = stats.message[0]?.x_quantity || 0;
        } catch (error) {
          if (error instanceof Error) {
            console.log("Stats request error:", error.message);
          } else {
            console.log("Unknown error during stats request:", error);
          }
        } finally {
          setLoadingStats(false);
        }
      }, 300); // Debounce delay of 300ms
    };

    fetchData();

    // Cleanup: Abort requests on component unmount
    return () => {
      if (volumeController.current) volumeController.current.abort();
      if (statsController.current) statsController.current.abort();
      if (debounceTimer.current) clearTimeout(debounceTimer.current);
    };
  }, [agency, account, client]);
  return (
    <div className="pb-4">
      <div className="mb-4">
        <div className="bg-background">
          <div className="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mb-2">
            {cardsData.map((_card) => (
              <Link to={_card.route} key={_card.title}>
                <Card className="rounded-md group h-full">
                  <CardHeader className="flex flex-row justify-between space-y-0 pb-2">
                    <CardTitle className="text-sm font-medium group-hover:underline">
                      {_card.title} posts
                    </CardTitle>
                    <span>{_card.icon}</span>
                  </CardHeader>
                  <CardContent>
                    {loadingStats ? (
                      <Skeleton className="h-8 w-[100px]" />
                    ) : (
                      <NumberCounter
                        className="text-2xl font-bold"
                        targetNumber={_card.post_count}
                      />
                    )}
                  </CardContent>
                </Card>
              </Link>
            ))}
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <Demographic loading={loading} data={newsPortalVisitors} />
        <PortalVisitors loading={loading} data={clientSiteVisitors} />
        <VisitorsSource />
        <TrafficSource />
      </div>
    </div>
  );
};

export default Overview;
