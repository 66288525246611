import { useEffect, useState } from "react";
import { Button } from "components/ui/button";
import { useAppState } from "context/AppContext";
import AddBlogSettingDialog from "./AddBlogSettingDialog";
import Breadcrumbs from "components/Shared/Breadcrumbs";
import { DataTableBlogSettings } from "./data";
import AccountSelect from "../AccountSelect";
import BlogSubjects from "./BlogSubjects";
import { BlogSetting } from "types";
import apiClient from "api";

const BlogPosts = () => {
  const { state } = useAppState();
  const { agency, client, account } = state.admin;

  const [loading, setLoading] = useState(false);
  const [openAddSetting, setOpenAddSetting] = useState(false);

  const [data, setData] = useState<BlogSetting[]>([]);
  const [selectedBlog, setSelectedBlog] = useState<BlogSetting | null>(null);

  useEffect(() => {
    setData([]);
    if (!account) return;
    const timeout = setTimeout(fetchBlogSettings, 200);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line
  }, [account]);

  const fetchBlogSettings = async () => {
    setLoading(true);
    try {
      const { data } = await apiClient.get(
        "/admin_creative_get_blog_post_settings",
        {
          headers: {
            request: JSON.stringify({ creative_id: account?.account_id }),
          },
        }
      );
      setData(data.message || []);
    } catch (error) {
      console.log("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      {/* <Breadcrumb className="mb-4">
        <BreadcrumbList>
          <BreadcrumbItem>
            <Link to="/">Home</Link>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <Link to="/admin/agencies">{agency?.agency_name}</Link>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <Link to="/admin/clients">{client?.customer_name}</Link>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <Link to="/admin/accounts">{account?.account_name}</Link>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbPage>Long form</BreadcrumbPage>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb> */}

      <Breadcrumbs
        // icon={
        //   <Button size="icon" variant="secondary" className="mr-2">
        //     <PanelRightClose className="size-4" />
        //   </Button>
        // }
        routes={[
          { title: agency?.agency_name || "", path: "/admin/agencies" },
          { title: client?.customer_name || "", path: "/admin/clients" },
          { title: account?.account_name || "", path: "/admin/accounts" },
          { title: "Long form", path: "" },
        ]}
      />

      <div className="space-y-2 mt-8">
        <div className="flex justify-between items-center">
          <h3 className="scroll-m-20 text-2xl font-bold tracking-tight">
            Long form
          </h3>
          <AccountSelect />
        </div>
      </div>

      <div className="mt-8">
        <div className="flex justify-end">
          <Button size="sm" onClick={() => setOpenAddSetting(true)}>
            Add long form
          </Button>
        </div>
      </div>

      <DataTableBlogSettings
        data={data}
        loading={loading}
        fetchData={fetchBlogSettings}
        setSelectedBlog={setSelectedBlog}
      />

      <BlogSubjects selectedBlog={selectedBlog} />

      <AddBlogSettingDialog
        fetchData={fetchBlogSettings}
        open={openAddSetting}
        onOpenChange={setOpenAddSetting}
      />
    </div>
  );
};

export default BlogPosts;
