

export const set = async (key: string, data: any) => {
    try {
        localStorage.setItem(key, JSON.stringify(data))
    } catch (error) {
        console.log(error)
    }
}

export const get = (key: string) => {

    const data = localStorage.getItem(key)
    if (!data) return null

    return JSON.parse(data)


}