import { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import Papa from "papaparse";
import { RefreshCcw, UploadCloud } from "lucide-react";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "components/ui/alert-dialog";
import { Label } from "components/ui/label";
import { Switch } from "components/ui/switch";
import { Button } from "components/ui/button";
import AccountSelect from "../AccountSelect";
import { useAppState } from "context/AppContext";
import { useToast } from "components/ui/use-toast";
import Breadcrumbs from "components/Shared/Breadcrumbs";

import { sanitizeString } from "utils/helper-methods";
import AddGroupDialog from "./AddGroupDialog";
import GroupAnswerers from "../GroupAnswerers";
import GroupSchedules from "../GroupSchedules";
import { DataTableGroup } from "./data";
import { Group } from "types";
import apiClient from "api";

const allowedProperties = [
  "channel",
  "group_url",
  "group_name",
  "internal_group",
  "description",
];

const Groups = () => {
  const { toast } = useToast();
  const { state } = useAppState();
  const { agency, client, account } = state.admin;

  const [data, setData] = useState<Group[]>([]);
  const [loading, setLoading] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [deleteMode, setDeleteMode] = useState(false);
  const [resetRows, setResetRows] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [fileToUpload, setFileToUpload] = useState<File>();
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [showConfirmUpload, setShowConfirmUpload] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState<Group | null>(null);

  useEffect(() => {
    if (!deleteMode) {
      setResetRows(true);
      setSelectedIds([]);
    }
    // eslint-disable-next-line
  }, [deleteMode]);

  useEffect(() => {
    setData([]);
    if (!account) return;
    const timeout = setTimeout(fetchGroups, 200);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line
  }, [account]);

  const fetchGroups = async () => {
    setLoading(true);
    try {
      const { data } = await apiClient.get("admin_creative_get_groups", {
        headers: {
          request: JSON.stringify({ creative_id: account?.account_id }),
        },
      });
      setData(data.message || []);
    } catch (error) {
      console.log("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleFileUpload = (file: File | undefined) => {
    if (!file) return;

    if (isUploading) {
      toast({ description: "There is already a file being uploaded." });
      return;
    }
    const reader = new FileReader();

    if (file.type === "text/csv") {
      // Handle CSV File
      reader.onload = (e) => {
        const text = e.target?.result as string;
        Papa.parse(text, {
          header: true,
          complete: (result) => {
            validateAndUploadData(result.data);
          },
        });
      };
      reader.readAsText(file);
    } else if (
      file.type ===
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      // Handle XLSX File
      reader.onload = (e) => {
        const data = new Uint8Array(e.target?.result as ArrayBuffer);
        const workbook = XLSX.read(data, { type: "array" });
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        // Ensuring that empty cells are retained in the output
        const jsonData = XLSX.utils.sheet_to_json(worksheet, {
          header: 1,
          defval: "",
        });

        // Convert jsonData from an array of arrays to an array of objects, preserving all headers and columns.
        const headers: string[] = jsonData[0] as string[];
        const rows = jsonData.slice(1) as (string | undefined)[][];

        const formattedData = rows.map((row) => {
          const obj: Record<string, string> = {};
          headers.forEach((header: string, index: number) => {
            obj[header] = row[index] ? String(row[index]) : "";
          });
          return obj;
        });

        validateAndUploadData(formattedData);
      };
      reader.readAsArrayBuffer(file);
    } else {
      toast({ description: "Unsupported file type", variant: "destructive" });
    }
  };

  const validateAndUploadData = (fileData: any[]) => {
    // Filter only allowed properties from each row
    const filteredData = fileData.map((row) => {
      const filteredRow: Record<string, any> = {};
      allowedProperties.forEach((property) => {
        if (property in row) {
          filteredRow[property] = row[property];
        }
      });
      return filteredRow;
    });

    // Check if the file has the required columns
    const hasRequiredColumns = filteredData.every((row) =>
      allowedProperties.every((property) => property in row)
    );

    // console.log(filteredData);

    if (!hasRequiredColumns) {
      toast({
        description: "Invalid file format. Missing required columns.",
        variant: "destructive",
      });
      return;
    }

    // Proceed with all filtered rows, but filter out completely empty rows
    const validRows = filteredData.filter((row) =>
      allowedProperties.some((property) => row[property])
    );

    if (validRows.length === 0) {
      toast({
        description: "All rows have missing fields. No valid data to upload.",
        variant: "destructive",
      });
      return;
    }

    // If validation passes, proceed with uploading data
    uploadData(validRows);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(false);
    if (e.dataTransfer.files.length) {
      setShowConfirmUpload(true);
      setFileToUpload(e.dataTransfer.files[0]);
    }
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.length) {
      handleFileUpload(e.target.files[0]);
    }
  };

  const uploadData = async (fileData: Group[] | any[]) => {
    setIsUploading(true);
    try {
      // Sequentially upload each row using a for...of loop with await
      for (const row of fileData) {
        await apiClient.post(
          "/admin_creative_add_group",
          {},
          {
            headers: {
              request: JSON.stringify({
                creative_id: account?.account_id,
                group_url: row.group_url,
                group_name: sanitizeString(row.group_name),
                group_description: sanitizeString(row.description),
                channel_id:
                  row.channel && typeof row.channel === "string"
                    ? row.channel.toLowerCase() === "x" ||
                      row.channel === "twitter"
                      ? 1
                      : row.channel.toLowerCase() === "facebook"
                      ? 2
                      : row.channel.toLowerCase() === "linkedin"
                      ? 3
                      : 4
                    : row.channel,
                internal_group: row.internal_group === "yes" ? 1 : 0,
              }),
            },
          }
        );
      }

      toast({ description: "All rows uploaded successfully" });
      fetchGroups();
      if (fileToUpload) setFileToUpload(undefined);
    } catch (error) {
      toast({
        description: "An error occurred while uploading data.",
        variant: "destructive",
      });
    } finally {
      setIsUploading(false);
    }
  };

  const deleteData = async () => {
    if (selectedIds.length === 0) return;
    setIsDeleting(true);
    try {
      for (const id of selectedIds) {
        await apiClient.delete("/admin_creative_delete_group", {
          headers: {
            request: JSON.stringify({
              group_id: id,
            }),
          },
        });
      }

      toast({ description: "All selected items deleted successfully" });
      fetchGroups();
      setSelectedIds([]);
      setResetRows(true);
    } catch (error) {
      toast({
        description: "An error occurred while deleting data.",
        variant: "destructive",
      });
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <div>
      <Breadcrumbs
        routes={[
          { title: agency?.agency_name || "", path: "/admin/agencies" },
          { title: client?.customer_name || "", path: "/admin/clients" },
          { title: account?.account_name || "", path: "/admin/accounts" },
          { title: "Groups", path: "" },
        ]}
      />
      <div className="space-y-2 mt-8">
        <div className="flex justify-between items-center">
          <h3 className="scroll-m-20 text-2xl font-bold tracking-tight">
            Groups
          </h3>
          <AccountSelect />
        </div>
      </div>

      <div className="mt-8">
        <div className="flex justify-between">
          <div className="flex items-center space-x-4">
            <Switch onCheckedChange={setDeleteMode} id="delete-mode" />
            <Label htmlFor="delete-mode" className="text-primary">
              Multiple select
            </Label>
          </div>
          <div className="flex space-x-4 items-center">
            <Button
              size="sm"
              disabled={isUploading}
              onClick={() => document.getElementById("file-input")?.click()}
            >
              {isUploading ? (
                <>
                  <RefreshCcw className="size-3 mr-2 animate-spin" />
                  Uploading...
                </>
              ) : (
                "Upload CSV"
              )}
            </Button>{" "}
            <input
              type="file"
              id="file-input"
              accept=".csv, .xlsx"
              className="hidden"
              onChange={handleFileInputChange}
            />
            <Button size="sm" onClick={() => setOpenAdd(true)}>
              Add group
            </Button>
            {selectedIds.length > 0 && (
              <AlertDialog>
                <AlertDialogTrigger asChild>
                  <Button disabled={isDeleting} variant="destructive" size="sm">
                    {isDeleting ? (
                      <>
                        <RefreshCcw className="size-3 mr-2 animate-spin" />
                        Deleting...
                      </>
                    ) : (
                      `Delete (${selectedIds.length}) row(s)`
                    )}
                  </Button>
                </AlertDialogTrigger>
                <AlertDialogContent>
                  <AlertDialogHeader>
                    <AlertDialogTitle>
                      Are you absolutely sure?
                    </AlertDialogTitle>
                    <AlertDialogDescription>
                      This action cannot be undone. This will permanently delete
                      your selected rows.
                    </AlertDialogDescription>
                  </AlertDialogHeader>
                  <AlertDialogFooter>
                    <AlertDialogCancel>Cancel</AlertDialogCancel>
                    <AlertDialogAction onClick={deleteData}>
                      Continue
                    </AlertDialogAction>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialog>
            )}
          </div>
        </div>
      </div>
      <div
        className="relative w-full"
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
      >
        {isDragging && (
          <div className="absolute inset-0 flex flex-col items-center justify-center border-2 border-dashed border-secondary bg-gray-400 bg-opacity-75 pointer-events-none z-50">
            <UploadCloud className="w-12 h-12 text-secondary mb-2" />
            <p className="text-lg font-semibold text-secondary">
              Drop file here
            </p>
          </div>
        )}
        <DataTableGroup
          data={data}
          loading={loading}
          resetRows={resetRows}
          deleteMode={deleteMode}
          fetchData={fetchGroups}
          setResetRows={setResetRows}
          setSelectedIds={setSelectedIds}
          setSelectedGroup={setSelectedGroup}
        />
      </div>
      {!deleteMode && (
        <>
          <GroupSchedules selectedGroup={selectedGroup} />
          <GroupAnswerers selectedGroup={selectedGroup} />
        </>
      )}
      <AddGroupDialog
        open={openAdd}
        fetchData={fetchGroups}
        onOpenChange={setOpenAdd}
      />

      <AlertDialog open={showConfirmUpload} onOpenChange={setShowConfirmUpload}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Confirm upload file</AlertDialogTitle>
            <AlertDialogDescription>
              The file [{fileToUpload?.name}] you have dropped is ready to be
              uploaded. Please confirm to proceed.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel onClick={() => setFileToUpload(undefined)}>
              Cancel
            </AlertDialogCancel>
            <AlertDialogAction
              onClick={() => {
                handleFileUpload(fileToUpload);
              }}
            >
              Continue
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
};

export default Groups;
