import React, { useEffect, useState } from "react";
import { RefreshCw } from "lucide-react";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "components/ui/dialog";
import { Textarea } from "components/ui/textarea";
import { Label } from "components/ui/label";
import { Button } from "components/ui/button";
import { useToast } from "components/ui/use-toast";
import { Input } from "components/ui/input";

import { EmbeddedMessage } from "types";
import { useAppState } from "context/AppContext";
import { sanitizeString } from "utils/helper-methods";
import apiClient from "api";

type Props = {
  open: boolean;
  editData?: EmbeddedMessage | null;
  fetchData: () => void;
  onOpenChange: (value: boolean) => void;
};

const AddEmbeddedMessageDialog = ({
  open,
  editData,
  fetchData,
  onOpenChange,
}: Props) => {
  const { state } = useAppState();
  const { account } = state.admin;
  const { toast } = useToast();
  const [submitting, setSubmitting] = useState(false);
  const [embeddedMessage, setEmbeddedMessage] = useState("");
  const [primaryUrl, setPrimaryUrl] = useState("");

  useEffect(() => {
    if (!editData) return;
    setEmbeddedMessage(editData.embedded_message);
    setPrimaryUrl(editData.primary_url);
  }, [editData]);
  const addEmbeddedMessage = async (evt: React.FormEvent) => {
    evt.preventDefault();
    setSubmitting(true);
    if (Boolean(editData)) {
      try {
        await apiClient.post(
          "/admin_creative_update_embedded_message",
          {},
          {
            headers: {
              request: JSON.stringify({
                embedded_message_id: editData?.embedded_message_id,
                creative_id: account?.account_id,
                embedded_message: sanitizeString(embeddedMessage),
                primary_url: primaryUrl,
              }),
            },
          }
        );
        onOpenChange(false);
        fetchData();
      } catch (error) {
        console.log("Error:", error);
      } finally {
        setSubmitting(false);
      }
    } else {
      try {
        const { data } = await apiClient.post(
          "/admin_creative_add_embedded_message",
          {},
          {
            headers: {
              request: JSON.stringify({
                creative_id: account?.account_id,
                embedded_message: sanitizeString(embeddedMessage),
                primary_url: primaryUrl,
              }),
            },
          }
        );

        if (data?.message[0].result === -1) {
          toast({
            title: "Duplicate content",
            description: "Embedded message already exists.",
          });
        } else {
          onOpenChange(false);
          fetchData();
        }
      } catch (error) {
        console.log("Error:", error);
      } finally {
        setSubmitting(false);
      }
    }
  };

  return (
    <>
      <Dialog open={open} onOpenChange={onOpenChange}>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>
              {!!editData ? "Edit" : "Add"} embedded message
            </DialogTitle>
          </DialogHeader>

          <form className="grid gap-4" onSubmit={addEmbeddedMessage}>
            <div className="grid gap-2">
              <Label htmlFor="embedded-message">Embedded message</Label>
              <div className="relative">
                <Textarea
                  id="embedded-message"
                  required
                  value={embeddedMessage}
                  onChange={(evt) => {
                    const val = evt.target.value;
                    setEmbeddedMessage(val);
                  }}
                  rows={3}
                  className="resize-none"
                />
              </div>
            </div>

            <div className="grid gap-2">
              <Label htmlFor="primary-url">Primary URL</Label>
              <div className="relative">
                <Input
                  id="primary-url"
                  type="url"
                  required
                  value={primaryUrl}
                  onChange={(evt) => {
                    const val = evt.target.value;
                    setPrimaryUrl(val);
                  }}
                />
              </div>
            </div>

            <DialogFooter>
              <DialogClose asChild>
                <Button
                  disabled={submitting}
                  type="button"
                  size="sm"
                  variant="outline"
                >
                  Cancel
                </Button>
              </DialogClose>
              <Button disabled={submitting} type="submit" size="sm">
                {submitting && (
                  <RefreshCw className="size-4 animate-spin mr-2" />
                )}{" "}
                Save
              </Button>
            </DialogFooter>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddEmbeddedMessageDialog;
