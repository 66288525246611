import React from "react";
import {
  Table as UITable,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "components/ui/table";
import { ColumnDef, flexRender, RowData, Table } from "@tanstack/react-table";
import { AdminTablePagination } from "./AdminTablePagination";

type Props<TData extends RowData> = {
  name: string;
  data: TData[];
  loading: boolean;
  table: Table<TData>;
  className?: string;
  showAllRows?: boolean;
  showSelectedCount?: boolean;
  columns: ColumnDef<TData>[];
  searchComponent?: React.ReactNode;
};

const CommonTable = <TData extends RowData>({
  data,
  name,
  table,
  columns,
  loading,
  className,
  showAllRows,
  searchComponent,
  showSelectedCount,
}: Props<TData>) => {
  return (
    <div className="w-full overflow-x-auto">
      {searchComponent}
      <div
        className={`rounded-md border min-w-full ${
          searchComponent ? "" : "mt-4"
        }`}
      >
        <div className="relative max-h-[50vh] overflow-auto custom-scrollbar">
          <div className="overflow-x-auto custom-scrollbar">
            <UITable className="min-w-full">
              <TableHeader>
                {table.getHeaderGroups().map((headerGroup) => (
                  <TableRow key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <TableHead
                        key={header.id}
                        className={
                          header.id === "actions"
                            ? "sticky right-0 bg-primary-foreground z-10 py-1 top-0"
                            : "py-1"
                        }
                      >
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                      </TableHead>
                    ))}
                  </TableRow>
                ))}
              </TableHeader>
              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell
                      colSpan={columns.length}
                      className="h-24 text-center"
                    >
                      <div className="min-h-[50vh] flex items-center justify-center">
                        <div className="flex flex-col justify-center items-center">
                          <div className="size-8 border-2 border-t-2 border-muted border-t-primary rounded-full animate-spin"></div>
                          <span className="text-sm text-center mt-4 text-primary">
                            Loading {name.toLowerCase()}...
                          </span>
                        </div>
                      </div>
                    </TableCell>
                  </TableRow>
                ) : data.length > 0 ? (
                  table.getRowModel().rows.map((row) => (
                    <TableRow
                      key={row.id}
                      data-state={row.getIsSelected() && "selected"}
                    >
                      {row.getVisibleCells().map((cell) => (
                        <TableCell
                          key={cell.id}
                          className={
                            cell.id.includes("actions")
                              ? "sticky bg-primary-foreground right-0 z-10 py-0.5 text-xs"
                              : `py-0.5 text-xs ${className}`
                          }
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={columns.length}
                      className="h-24 text-center"
                    >
                      No results.
                    </TableCell>
                  </TableRow>
                )}
                {/* {table.getRowModel().rows?.length ? (
                  table.getRowModel().rows.map((row) => (
                    <TableRow
                      key={row.id}
                      data-state={row.getIsSelected() && "selected"}
                    >
                      {row.getVisibleCells().map((cell) => (
                        <TableCell
                          key={cell.id}
                          className={
                            cell.id.includes("actions")
                              ? "sticky bg-primary-foreground right-0 z-10 py-0.5 text-xs"
                              : `py-0.5 text-xs ${className}`
                          }
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={columns.length}
                      className="h-24 text-center"
                    >
                      {loading ? (
                        <div className="min-h-20 flex items-center justify-center">
                          <div className="flex flex-col justify-center items-center">
                            <div className="size-8 border-2 border-t-2 border-muted border-t-primary rounded-full animate-spin"></div>
                            <span className="text-sm text-center mt-4 text-primary">
                              Loading {name.toLowerCase()}...
                            </span>
                          </div>
                        </div>
                      ) : (
                        data.length === 0 && "No results."
                      )}
                    </TableCell>
                  </TableRow>
                )} */}
              </TableBody>
            </UITable>
          </div>
        </div>
      </div>

      <AdminTablePagination
        table={table}
        showSelectedCount={showSelectedCount}
      />
    </div>
  );
};

export default CommonTable;
