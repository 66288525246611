import {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "components/ui/pagination";

const CustomPagiantion = ({
  totalPages,
  currentPage,
  setCurrentPage,
}: {
  totalPages: number;
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const createPageNumbers = () => {
    const pages = [];
    // Always show the first page
    pages.push(1);

    if (currentPage > 3) {
      // Add ellipsis if the current page is greater than 3
      pages.push("...");
    }

    // Determine the range of pages to show around the current page
    const startPage = Math.max(2, currentPage - 1);
    const endPage = Math.min(totalPages - 1, currentPage + 1);

    // Add pages in the range
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }

    if (currentPage < totalPages - 2) {
      // Add ellipsis if the current page is less than total pages - 2
      pages.push("...");
    }

    // Always show the last page if it's not the first page
    if (totalPages > 1) {
      pages.push(totalPages);
    }

    return pages;
  };

  const pages = createPageNumbers();

  return (
    <Pagination className="w-auto ml-auto">
      <PaginationContent>
        <PaginationItem>
          <PaginationPrevious
            href="#"
            onClick={(e) => {
              e.preventDefault();
              setCurrentPage(
                currentPage - 1 > 0 ? currentPage - 1 : currentPage
              );
            }}
          />
        </PaginationItem>
        {pages.map((page, index) => (
          <PaginationItem key={index}>
            {page === "..." ? (
              <PaginationEllipsis />
            ) : (
              <PaginationLink
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  setCurrentPage(parseInt(String(page)));
                }}
                isActive={currentPage === page}
              >
                {String(page)}
              </PaginationLink>
            )}
          </PaginationItem>
        ))}
        <PaginationItem>
          <PaginationNext
            href="#"
            onClick={(e) => {
              e.preventDefault();
              setCurrentPage(
                currentPage + 1 <= totalPages ? currentPage + 1 : currentPage
              );
            }}
          />
        </PaginationItem>
      </PaginationContent>
    </Pagination>
  );
};

export default CustomPagiantion;
