import { useEffect, useState } from "react";
import Breadcrumbs from "components/Shared/Breadcrumbs";
import { useAppState } from "context/AppContext";
import AccountSelect from "../AccountSelect";
import { Connection } from "types";
import { DataTableConnections } from "./data";
import apiClient from "api";

const Connections = () => {
  const { state } = useAppState();
  const { agency, client, account, account_token } = state.admin;
  const [connections, setConnections] = useState<Connection[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const timeout = setTimeout(fetchConnections, 100);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!account_token) return;
    const timeout = setTimeout(fetchConnections, 100);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line
  }, [account_token]);

  const fetchConnections = async () => {
    try {
      const { data } = await apiClient.get("/get_social_dashboard", {
        headers: {
          token: account_token,
        },
      });

      const connections: Connection[] = data?.message || [];
      setConnections(connections);
    } catch (error) {
      console.error("Error fetching connections:", error);
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line
  };

  return (
    <div>
      <Breadcrumbs
        // icon={
        //   <Button size="icon" variant="secondary" className="mr-2">
        //     <PanelRightClose className="size-4" />
        //   </Button>
        // }
        routes={[
          { title: agency?.agency_name || "", path: "/admin/agencies" },
          { title: client?.customer_name || "", path: "/admin/clients" },
          { title: account?.account_name || "", path: "/admin/accounts" },
          { title: "Connections", path: "" },
        ]}
      />
      <div className="">
        <div className="flex justify-between items-center">
          <h3 className="scroll-m-20 text-2xl font-bold tracking-tight">
            Connections
          </h3>
          <AccountSelect />
        </div>
        <p className="text-base text-muted-foreground">
          Manage your connections.
        </p>
      </div>

      <div>
        <DataTableConnections
          data={connections}
          loading={loading}
          fetchData={fetchConnections}
        />
      </div>
    </div>
  );
};

export default Connections;
