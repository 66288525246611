import { useEffect, useState } from "react";
import { RefreshCw } from "lucide-react";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "components/ui/dialog";
import { Button } from "components/ui/button";
import { Textarea } from "components/ui/textarea";
import { Label } from "components/ui/label";
import { Input } from "components/ui/input";

import { sanitizeString } from "utils/helper-methods";
import { BlogSetting, BlogSubject } from "types";
import apiClient from "api";

type Props = {
  open: boolean;
  editData?: BlogSubject | null;
  selectedBlog: BlogSetting | null;
  fetchData: () => void;
  onOpenChange: (value: boolean) => void;
};

const AddSubjectsDialog = ({
  open,
  editData,
  selectedBlog,
  onOpenChange,
  fetchData,
}: Props) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [subject, setSubject] = useState("");
  const [maxWords, setMaxWords] = useState(0);

  useEffect(() => {
    if (editData) {
      setSubject(editData.subject);
      setMaxWords(editData.max_words);
    }
    // eslint-disable-next-line
  }, [editData]);

  const addSubject = async () => {
    setIsSubmitting(true);
    if (Boolean(editData)) {
      try {
        await apiClient.post(
          "/admin_creative_update_blog_subject",
          {},
          {
            headers: {
              request: JSON.stringify({
                subject: sanitizeString(subject),
                max_words: maxWords,
                blog_subject_id: editData?.blog_subject_id,
              }),
            },
          }
        );
        fetchData();
        onOpenChange(false);
      } catch (error) {
        console.log("Error:", error);
      } finally {
        setIsSubmitting(false);
      }
    } else {
      try {
        await apiClient.post(
          "/admin_creative_add_blog_subject",
          {},
          {
            headers: {
              request: JSON.stringify({
                subject: sanitizeString(subject),
                max_words: maxWords,
                blog_post_id: selectedBlog?.blog_post_settings_id,
              }),
            },
          }
        );
        fetchData();
        onOpenChange(false);
      } catch (error) {
        console.log("Error:", error);
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  return (
    <>
      <Dialog open={open} onOpenChange={onOpenChange}>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>{editData ? "Edit" : "Add"} subjects</DialogTitle>
          </DialogHeader>

          <div className="grid gap-2">
            <Label htmlFor="subject">Subject</Label>
            <div className="relative">
              <Textarea
                id="subject"
                value={subject}
                onChange={(evt) => {
                  if (subject.length >= 500) return;
                  setSubject(evt.target.value);
                }}
                rows={6}
                className="resize-none"
              />
              {/* <span className="absolute font-thin text-xs bottom-2 right-2">
                    {subject.length}/500
                  </span> */}
            </div>
          </div>

          <div className="grid gap-2">
            <Label htmlFor="hours">Max words</Label>
            <Input
              type="number"
              id="hours"
              value={maxWords}
              onChange={(evt) => {
                const val = evt.target.value;
                if (parseInt(val) < 0) return;
                setMaxWords(parseInt(val));
              }}
            />
          </div>

          <DialogFooter>
            <DialogClose asChild>
              <Button
                disabled={isSubmitting}
                type="button"
                size="sm"
                variant="outline"
              >
                Close
              </Button>
            </DialogClose>
            <Button onClick={addSubject} disabled={isSubmitting} size="sm">
              {isSubmitting && (
                <RefreshCw className="size-4 animate-spin mr-2" />
              )}{" "}
              Save subject
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddSubjectsDialog;
