import * as React from "react";
import {
  ColumnDef,
  ColumnFiltersState,
  Row,
  SortingState,
  VisibilityState,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { MoreHorizontal } from "lucide-react";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "components/ui/alert-dialog";
import { Button } from "components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "components/ui/dropdown-menu";
import { Input } from "components/ui/input";
import { useNavigate } from "react-router-dom";
import { useAppState } from "context/AppContext";
import { useToast } from "components/ui/use-toast";
import { DataTableColumnHeader } from "components/Shared/AdminTableHeader";
import CommonTable from "components/Shared/CommonTable";
import AddClientDialog from "./AddClientDialog";
import { Client } from "types";
import apiClient from "api";

type Props = {
  data: Client[];
  loading: boolean;
  fetchData: () => void;
  setOpenAdd: React.Dispatch<React.SetStateAction<boolean>>;
};

export function DataTableClients({
  data,
  loading,
  fetchData,
  setOpenAdd,
}: Props) {
  const navigate = useNavigate();
  const { state, dispatch } = useAppState();
  const { account } = state.admin;

  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );
  const [columnVisibility, setColumnVisibility] =
    React.useState<VisibilityState>({});
  const [rowSelection, setRowSelection] = React.useState({});

  const columns: ColumnDef<Client>[] = [
    {
      accessorKey: "customer_id",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="ID" />
      ),
      cell: ({ row }) => <div>{row.getValue("customer_id")}</div>,
    },
    {
      accessorKey: "customer_name",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Client name" />
      ),
      cell: ({ row }) => (
        <div
          className="hover:underline underline-offset-1 cursor-pointer"
          onClick={() => {
            onSelect(row.original);
            navigate("/admin/accounts");
          }}
        >
          {row.getValue("customer_name")}
        </div>
      ),
    },
    {
      id: "actions",
      enableHiding: false,
      cell: ({ row }) => <RowAction row={row} fetchData={fetchData} />,
    },
  ];

  const table = useReactTable({
    data,
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
    },
  });

  const onSelect = (rowData: Client) => {
    dispatch({
      type: "SET_ADMIN_CLIENT",
      payload: rowData,
    });

    if (account) {
      dispatch({
        type: "SET_ADMIN_ACCOUNT",
        payload: undefined,
      });
    }
  };

  const tableHead = (
    <div className="flex items-center py-4 pl-1">
      <Input
        placeholder="Filter clients..."
        value={
          (table.getColumn("customer_name")?.getFilterValue() as string) ?? ""
        }
        onChange={(event) =>
          table.getColumn("customer_name")?.setFilterValue(event.target.value)
        }
        className="max-w-sm h-8"
      />
      <Button className="ml-auto" size="sm" onClick={() => setOpenAdd(true)}>
        Add client
      </Button>
    </div>
  );

  return (
    <CommonTable
      searchComponent={tableHead}
      table={table}
      name="Clients"
      columns={columns}
      data={data}
      loading={loading}
    />
  );
}

interface RowProps<TData> {
  row: Row<TData>;
  fetchData: () => void;
}

const RowAction = ({ row, fetchData }: RowProps<Client>) => {
  const { toast } = useToast();
  const [openAlert, setOpenAlert] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [isDeleting, setIsDeleting] = React.useState(false);

  const data = row.original;

  const deleteClient = async () => {
    setIsDeleting(true);
    toast({
      description: "Deleting client...",
      variant: "destructive",
    });

    try {
      await apiClient.delete("/admin_creative_delete_client", {
        headers: {
          request: JSON.stringify({
            client_id: data.customer_id,
          }),
        },
      });
      fetchData();
      setIsDeleting(false);
      toast({
        description: "Client deleted successfully.",
        variant: "destructive",
      });
    } catch (error) {
      setIsDeleting(false);
      toast({
        description: "Client deletion failed.",
        variant: "destructive",
      });
    }
  };

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" className="h-6 w-6 p-0">
            <span className="sr-only">Open menu</span>
            <MoreHorizontal className="h-3 w-3" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <DropdownMenuLabel>Actions</DropdownMenuLabel>
          <DropdownMenuItem
            onClick={() => {
              setOpenEdit(true);
            }}
          >
            Edit
          </DropdownMenuItem>
          <DropdownMenuSeparator />
          <DropdownMenuItem
            disabled={isDeleting}
            onClick={() => setOpenAlert(true)}
            className="text-red-600"
          >
            Delete
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>

      <AlertDialog open={openAlert} onOpenChange={setOpenAlert}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Are you sure?</AlertDialogTitle>
            <AlertDialogDescription>
              This action cannot be undone. This will permanently delete your
              client.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction onClick={deleteClient}>
              Confirm
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      <AddClientDialog
        open={openEdit}
        editData={data}
        onOpenChange={setOpenEdit}
        fetchData={fetchData}
      />
    </>
  );
};
