import { useEffect, useState } from "react";
import Breadcrumbs from "components/Shared/Breadcrumbs";
import AddProfileDialog from "./AddProfileDialog";
import { DataTableProfiles } from "./data";
import { Profile } from "types";
import apiClient from "api";

const Profiles = () => {
  const [data, setData] = useState<Profile[]>([]);
  const [loading, setLoading] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);

  useEffect(() => {
    setData([]);
    const timeout = setTimeout(fetchProfiles, 200);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line
  }, []);

  const fetchProfiles = async () => {
    setLoading(true);
    try {
      const { data } = await apiClient.get("/admin_creative_get_profiles");
      setData(data.message || []);
    } catch (error) {
      console.log("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Breadcrumbs
        // icon={
        //   <Button size="icon" variant="secondary" className="mr-2">
        //     <PanelRightClose className="size-4" />
        //   </Button>
        // }
        routes={[{ title: "Profiles", path: "" }]}
      />

      <div className="space-y-2 mt-8">
        <h3 className="scroll-m-20 text-2xl font-bold tracking-tight">
          Profiles
        </h3>
        <p className="text-base text-muted-foreground">
          Add edit/update profiles.
        </p>
      </div>

      <DataTableProfiles
        data={data}
        loading={loading}
        setOpenAdd={setOpenAdd}
        fetchData={fetchProfiles}
      />

      <AddProfileDialog
        open={openAdd}
        fetchData={fetchProfiles}
        onOpenChange={setOpenAdd}
      />
    </div>
  );
};

export default Profiles;
