import { useEffect, useState } from "react";
import { CaretSortIcon } from "@radix-ui/react-icons";
import { Check } from "lucide-react";
import { Button } from "components/ui/button";
import { Popover, PopoverContent, PopoverTrigger } from "components/ui/popover";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "components/ui/command";
import { Account } from "types";
import { useAppState } from "context/AppContext";
import { cn } from "lib/utils";
import apiClient from "api";

const AccountSelect = () => {
  const { state, dispatch } = useAppState();
  const { account: selectAccount, client, refresh_accounts } = state.admin;

  const [open, setOpen] = useState(false);
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!client) return;
    const timeout = setTimeout(() => {
      getAccounts();
    }, 200);

    return () => clearTimeout(timeout);
    // eslint-disable-next-line
  }, [client]);

  useEffect(() => {
    if (!client || !refresh_accounts) return;
    getAccounts();
    // eslint-disable-next-line
  }, [refresh_accounts]);

  const getAccounts = async () => {
    try {
      const { data } = await apiClient.get("/admin_creative_get_accounts", {
        headers: {
          request: JSON.stringify({ client_id: client?.customer_id }),
        },
      });
      const accounts = data?.message || [];
      setAccounts(accounts);
      setLoading(false);

      if (selectAccount) {
        const newAccount = accounts.find(
          (a: Account) => a.account_id === selectAccount.account_id
        );
        if (JSON.stringify(selectAccount) !== JSON.stringify(newAccount)) {
          dispatch({
            type: "SET_ADMIN_ACCOUNT",
            payload: newAccount,
          });
        }
      }
      if (refresh_accounts) {
        dispatch({ type: "SET_ADMIN_ACCOUNT_REFRESH", payload: false });
      }
    } catch (error) {
      setLoading(false);
      console.log("Error", error);
    }
  };

  return (
    <div className="flex items-center space-x-4">
      <p className="text-sm hidden md:inline-block text-secondary-foreground font-bold">
        Account
      </p>
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild disabled={loading}>
          <Button
            variant="outline"
            size="sm"
            className="w-[150px] justify-start"
          >
            <span className="truncate">{selectAccount?.account_name}</span>

            <CaretSortIcon className="mr-2 h-4 w-4 ml-auto" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="p-0" side="right" align="start">
          <Command>
            <CommandInput placeholder="Search account..." />
            <CommandList>
              <CommandEmpty>No results found.</CommandEmpty>
              <CommandGroup>
                {accounts.map((account) => (
                  <CommandItem
                    key={account.account_id}
                    value={String(account.account_name)}
                    onSelect={() => {
                      dispatch({
                        type: "SET_ADMIN_ACCOUNT",
                        payload: account,
                      });
                      setOpen(false);
                    }}
                  >
                    <Check
                      className={cn(
                        "mr-2 h-4 w-4",
                        account.account_id === selectAccount?.account_id
                          ? "opacity-100"
                          : "opacity-0"
                      )}
                    />
                    <span>{account.account_name}</span>
                  </CommandItem>
                ))}
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    </div>
  );
};

export default AccountSelect;
