import { useState } from "react";
import { ThumbsDown, ThumbsUp } from "lucide-react";
import { AspectRatio } from "components/ui/aspect-ratio";
import {
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  Sheet,
} from "components/ui/sheet";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "components/ui/dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "components/ui/form";
import { RadioGroup, RadioGroupItem } from "components/ui/radio-group";
import { Button } from "components/ui/button";
import { ScrollArea } from "components/ui/scroll-area";
import { useToast } from "components/ui/use-toast";
import { Article } from "types";
import { cn } from "lib/utils";
import { useForm } from "react-hook-form";
import apiClient from "api";
import { Textarea } from "components/ui/textarea";
import { useAppState } from "context/AppContext";

type FormValues = {
  type: string;
};

const ArticleItem = ({
  article,
  isSelected,
  campaignId,
  refetch,
  onSelect,
}: {
  article: Article;
  campaignId: number;
  isSelected: boolean;
  refetch: () => void;
  onSelect: (article: Article) => void;
}) => {
  const { state } = useAppState();
  const { account_token } = state.user;
  const { toast } = useToast();
  const form = useForm<FormValues>();
  const [reason, setReason] = useState("");
  const [openArticle, setOpenArticle] = useState(false);
  const [openFeedbackModal, setOpenFeedbackModal] = useState(false);

  const onSubmit = (data: FormValues) => {
    const value = data.type === "other" ? reason : data.type;
    submitFeedback(value);
  };

  const submitFeedback = async (notes: string) => {
    toast({
      description: "Submitting feedback...",
    });
    try {
      await apiClient.post(
        "/article_thumbs_down",
        {},
        {
          headers: {
            token: account_token,
            request: JSON.stringify({
              campaign_id: campaignId,
              article_id: article.article_id,
              notes,
            }),
          },
        }
      );
      toast({
        description: "Feedback submitted.",
      });
      refetch();
    } catch (error) {}
  };

  const handleThumbsUp = async () => {
    if (article.ranking === 1) {
      toast({
        description: "You have already liked this article.",
      });

      return;
    }
    toast({
      description: "Submitting feedback...",
    });
    try {
      await apiClient.post(
        "/article_thumbs_up",
        {},
        {
          headers: {
            token: account_token,
            request: JSON.stringify({
              campaign_id: campaignId,
              article_id: article.article_id,
            }),
          },
        }
      );

      toast({
        description: "Feedback submitted.",
      });
      refetch();
    } catch (error) {}
  };

  return (
    <article
      className={cn(
        isSelected
          ? "bg-primary dark:bg-secondary-foreground"
          : "bg-white dark:bg-primary-foreground",
        "shadow transition-all border border-gray-200 dark:border-neutral-600 rounded-lg p-2"
      )}
    >
      <div className="cursor-pointer" onClick={() => onSelect(article)}>
        <AspectRatio ratio={16 / 10} className="bg-muted rounded-md">
          <img
            src={article.image_url}
            className="rounded-md object-cover h-full w-full"
            alt={article.title}
            loading="lazy"
          />
        </AspectRatio>

        <h2
          title={article.title}
          className={cn(
            isSelected ? "text-white dark:text-black" : "",
            "my-2 text-base truncate font-bold leading-normal"
          )}
        >
          {article.title}
        </h2>
        <div className="min-h-24">
          <p
            className={cn(
              isSelected && "text-gray-400 dark:text-gray-700",
              `mb-4 text-xs md:text-sm leading-tight`
            )}
          >
            {article.article.substring(0, 100)}...
          </p>
        </div>
      </div>
      <div className="flex justify-between items-center">
        <span
          onClick={(e) => {
            e.stopPropagation();
            setOpenArticle(true);
          }}
          className={cn(
            isSelected && "text-gray-100 dark:text-black",
            "cursor-pointer inline-flex items-center text-sm hover:underline"
          )}
        >
          Read more
          <svg
            className="ml-2 w-4 h-4"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </span>
        <div className="flex gap-1">
          <Button
            size="icon"
            onClick={handleThumbsUp}
            variant={isSelected ? "default" : "ghost"}
          >
            <ThumbsUp className="size-4" />
          </Button>
          <Button
            size="icon"
            variant={isSelected ? "default" : "ghost"}
            onClick={() => setOpenFeedbackModal(true)}
          >
            <ThumbsDown className="size-4" />
          </Button>
        </div>
      </div>

      <Dialog open={openFeedbackModal} onOpenChange={setOpenFeedbackModal}>
        <DialogContent className="sm:max-w-xl">
          <DialogHeader>
            <DialogTitle>Dislike article</DialogTitle>
          </DialogHeader>
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(onSubmit)}
              className="w-full space-y-6"
            >
              <FormField
                control={form.control}
                name="type"
                render={({ field }) => (
                  <FormItem className="space-y-3">
                    <FormLabel>Tell us how can we improve?</FormLabel>
                    <FormControl>
                      <RadioGroup
                        onValueChange={field.onChange}
                        defaultValue={field.value}
                        className="grid sm:grid-cols-2"
                      >
                        <>
                          <FormItem className="flex items-center space-x-3 space-y-0">
                            <FormControl>
                              <RadioGroupItem value="too long" />
                            </FormControl>
                            <FormLabel className="font-normal">
                              Too long.
                            </FormLabel>
                          </FormItem>
                          <FormItem className="flex items-center space-x-3 space-y-0">
                            <FormControl>
                              <RadioGroupItem value="incorrect country" />
                            </FormControl>
                            <FormLabel className="font-normal">
                              Incorrect country.
                            </FormLabel>
                          </FormItem>
                          <FormItem className="flex items-center space-x-3 space-y-0">
                            <FormControl>
                              <RadioGroupItem value="inappropriate content for subject" />
                            </FormControl>
                            <FormLabel className="font-normal">
                              Inappropriate content for subject.
                            </FormLabel>
                          </FormItem>
                          <FormItem className="flex items-center space-x-3 space-y-0">
                            <FormControl>
                              <RadioGroupItem value="too short" />
                            </FormControl>
                            <FormLabel className="font-normal">
                              Too short.
                            </FormLabel>
                          </FormItem>
                        </>
                        <>
                          <FormItem className="flex items-center space-x-3 space-y-0">
                            <FormControl>
                              <RadioGroupItem value="not enough detail" />
                            </FormControl>
                            <FormLabel className="font-normal">
                              Not enough detail.
                            </FormLabel>
                          </FormItem>
                          <FormItem className="flex items-center space-x-3 space-y-0">
                            <FormControl>
                              <RadioGroupItem value="incorrect style" />
                            </FormControl>
                            <FormLabel className="font-normal">
                              Incorrect style (language).
                            </FormLabel>
                          </FormItem>
                          <FormItem className="flex items-center space-x-3 space-y-0">
                            <FormControl>
                              <RadioGroupItem value="out of date" />
                            </FormControl>
                            <FormLabel className="font-normal">
                              Out of date.
                            </FormLabel>
                          </FormItem>
                          <FormItem className="flex items-center space-x-3 space-y-0">
                            <FormControl>
                              <RadioGroupItem value="too similar to other article/Duplicate" />
                            </FormControl>
                            <FormLabel className="font-normal">
                              Too similar to other article/Duplicate.
                            </FormLabel>
                          </FormItem>
                          <FormItem className="flex items-center space-x-3 space-y-0">
                            <FormControl>
                              <RadioGroupItem value="other" />
                            </FormControl>
                            <FormLabel className="font-normal">
                              Other.
                            </FormLabel>
                          </FormItem>
                        </>
                      </RadioGroup>
                    </FormControl>
                    <FormMessage />
                    {field.value === "other" && (
                      <FormItem>
                        {/* <FormLabel>Describe your reason here.</FormLabel> */}
                        <FormControl>
                          <Textarea
                            placeholder="Describe your reason here."
                            className="resize-none"
                            value={reason}
                            onChange={(evt) => setReason(evt.target.value)}
                          />
                        </FormControl>

                        <FormMessage />
                      </FormItem>
                    )}
                  </FormItem>
                )}
              />
              <DialogClose asChild>
                <Button type="submit" className="w-full sm:w-auto">
                  Submit
                </Button>
              </DialogClose>
            </form>
          </Form>
          {/* <DialogFooter className="sm:justify-start">
            <DialogClose asChild>
              <Button type="submit">Submit</Button>
            </DialogClose>
          </DialogFooter> */}
        </DialogContent>
      </Dialog>

      <Sheet open={openArticle} onOpenChange={setOpenArticle}>
        <SheetContent className="sm:max-w-screen-md">
          <SheetHeader>
            <SheetTitle>{article.title}</SheetTitle>
            <SheetDescription>
              <ScrollArea className="h-[80vh] sm:h-[85vh]">
                <AspectRatio className="rounded-md bg-muted" ratio={16 / 10}>
                  <img
                    alt={article.title}
                    src={article.image_url}
                    className="rounded-md object-cover h-full w-full"
                  />
                </AspectRatio>
                <p className="whitespace-pre-line mt-10">{article.article}</p>
              </ScrollArea>
            </SheetDescription>
          </SheetHeader>
        </SheetContent>
      </Sheet>
    </article>
  );
};

export default ArticleItem;
