import { useEffect, useState } from "react";
import AnimatedLoading from "components/Helpers/AnimatedLoading";
import { Button } from "components/ui/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "components/ui/dialog";
import { Input } from "components/ui/input";
import { Label } from "components/ui/label";
import { useAppState } from "context/AppContext";
import { useConnection } from "context/ConnectionContext";
import { getSuccessEndpoint, toStartCase } from "utils/helper-methods";
import { ScrollArea } from "components/ui/scroll-area";
import { Checkbox } from "components/ui/checkbox";
import { FBPage } from "types";
import apiClient from "api";

const redirect_uri =
  process.env.REACT_APP_REDIRECT_URI || window.location.origin;

export function ConnectionStatusDialog() {
  const { state, dispatch } = useAppState();
  const { account_token } = state.user;
  const {
    provider,
    isSaving,
    isSuccess,
    dataToSend,
    isCreating,
    isCancelled,
    isRetreiving,
    connectionData,
    isReconnecting,
    openStatusModal,
    isAuthenticated,
    isAuthenticating,
    setIsOpen,
    setIsSaving,
    setProvider,
    setIsSuccess,
    setDataToSend,
    setIsRetreiving,
    setIsReconnecting,
    setConnectionData,
    setOpenStatusModal,
    setConnectionState,
  } = useConnection();

  const [name, setName] = useState("");
  const [pages, setPages] = useState<FBPage[]>([]);
  const [showInput, setShowInput] = useState(false);
  const [showFbPages, setShowFbDialog] = useState(false);
  const [selectedPages, setSelectedPages] = useState<number[]>([]);

  useEffect(() => {
    if (!connectionData) return;
    setPages(connectionData?.pages);
  }, [connectionData]);

  useEffect(() => {
    if (!openStatusModal) {
      onDialogClose();
      return;
    } else {
      setName("");
      setPages([]);
      setShowInput(false);
      setShowFbDialog(false);
      setSelectedPages([]);
    }
    retreiveInfo();

    return () => {
      setShowInput(false);
      setName("");
    };
    // eslint-disable-next-line
  }, [openStatusModal]);

  const linkedinRetrieve = async () => {
    setIsRetreiving(true);
    try {
      await apiClient.get("/auth/linkedin/retrieve?" + dataToSend, {
        headers: {
          token: account_token,
          request: JSON.stringify({ uri: redirect_uri }),
        },
      });
      if (isReconnecting) {
        saveInfo(null);
      }
    } catch (error) {
      console.error("error", error);
    }
  };

  const facebookRetrieve = async () => {
    const endpoint = getSuccessEndpoint("facebook");
    try {
      await apiClient.post(
        endpoint,
        {},
        {
          headers: {
            token: account_token,
            request: JSON.stringify(dataToSend),
          },
        }
      );
      if (isReconnecting) {
        saveInfo(null);
      }
    } catch (error) {
      console.error("Error", error);
    }
  };

  const twitterRetrieve = async () => {
    try {
      const {
        data: { message: response },
      } = await apiClient.get("/auth/twitter/retrieve", {
        headers: {
          token: account_token,
          request: JSON.stringify(dataToSend),
        },
      });
      if (response === "failed") {
        return false;
      } else {
        if (isReconnecting) {
          saveInfo(null);
        }
        return true;
      }
    } catch (error) {
      console.log("Error", error);
    }
  };

  const retreiveInfo = async () => {
    setIsRetreiving(true);

    if (provider.toLowerCase() === "linkedin") {
      await linkedinRetrieve();
    }
    if (provider.toLowerCase() === "facebook") {
      await facebookRetrieve();
    }
    if (provider.toLowerCase() === "twitter") {
      await twitterRetrieve();
    }

    setTimeout(() => {
      setShowInput(true);
      setDataToSend("");
      setIsRetreiving(false);
    }, 300);
  };

  const addConnectionDetails = async (name: string) => {
    try {
      const { data } = await apiClient.post(
        "/add_connection_details",
        {},
        {
          headers: {
            token: account_token,
            request: JSON.stringify({
              connection_name: name,
              id: connectionData?.id,
              source_id: 0,
              posts: 0,
            }),
          },
        }
      );

      setConnectionData(data?.message);
    } catch (error) {
      console.error("error", error);
    }
  };

  const saveInfo = async (evt: React.FormEvent | null) => {
    if (evt) evt.preventDefault();

    setIsSaving(true);
    await addConnectionDetails(
      isReconnecting ? connectionData?.connection_name || "" : name
    );
    setShowInput(false);
    setIsSaving(false);
    setIsSuccess(true);

    if (provider.toLowerCase() === "facebook") {
      setShowFbDialog(true);
    }
  };

  // Pages selection from here...........

  const savePagesGroups = async (selectedData: any) => {
    if (!account_token) return;
    try {
      const { data } = await apiClient.post(
        "/select_facebook_object",
        {},
        {
          headers: {
            request: JSON.stringify(selectedData),
            token: account_token,
          },
        }
      );

      // connectionData && setConnectionData(data?.message);
      setPages(data?.message?.pages);
    } catch (error) {
      console.error("Error", error);
    }
  };

  const unsavePagesGroups = async (selectedData: any) => {
    if (!account_token) return;
    try {
      const { data } = await apiClient.post(
        "/unselect_facebook_object",
        {},
        {
          headers: {
            request: JSON.stringify(selectedData),
            token: account_token,
          },
        }
      );
      // connectionData && setConnectionData(data?.message);
      setPages(data?.message?.pages);
    } catch (error) {
      console.error("Error", error);
    }
  };

  const handleSave = async (checked: boolean, pageId: number) => {
    const selectedData = {
      connection_id: connectionData?.id,
      object_id: pageId,
    };

    if (checked) {
      await savePagesGroups(selectedData);
    } else {
      await unsavePagesGroups(selectedData);
    }
  };

  const handleChange = (checked: boolean, pageId: number) => {
    handleSave(checked, pageId);
    setSelectedPages((state) => {
      if (checked) {
        return [...state, pageId];
      } else {
        return state.filter((id) => id !== pageId);
      }
    });

    // eslint-disable-next-line
  };
  const onDialogClose = () => {
    dispatch({type:'SET_REFRESH',payload:'connections'});
    setConnectionData(null);
    setConnectionState("");
    setIsSuccess(false);
    setShowInput(false);
    setProvider("");
    setIsOpen(false);
    setName("");

    if (isReconnecting) {
      setIsReconnecting(false);
    }
  };

  return (
    <Dialog open={openStatusModal} onOpenChange={setOpenStatusModal}>
      <DialogContent className="sm:max-w-[524px]">
        <DialogHeader>
          {showFbPages ? (
            <DialogTitle>Select facebook pages</DialogTitle>
          ) : (
            <DialogTitle>{toStartCase(provider)} connection setup</DialogTitle>
          )}
        </DialogHeader>
        {isReconnecting ? (
          <>
            <div className="py-4 w-full">
              {isAuthenticated && (
                <div className="text-center my-4 text-sm animate-pulse">
                  Authenticated successfully
                </div>
              )}
              <div className="w-full flex justify-center items-center">
                <AnimatedLoading
                  isLoading={
                    isAuthenticating || isSaving || isCreating || isRetreiving
                  }
                  isSuccess={isAuthenticated}
                  isFailed={isCancelled}
                />
              </div>

              <div className="flex flex-col gap-2 items-center">
                {isAuthenticating && (
                  <div className="text-center mt-4 text-sm">
                    Authenticating...
                  </div>
                )}
                {isSaving && (
                  <div className="text-center mt-4 text-sm">Saving...</div>
                )}

                {isRetreiving && (
                  <div className="text-center mt-4 text-sm">
                    Retreiving data...
                  </div>
                )}
                {isCancelled && (
                  <div className="text-center mt-4 text-sm">
                    Connection failed
                  </div>
                )}
                {isSuccess && (
                  <div className="text-center mt-4 text-sm">
                    Connection has been updated successfully.
                  </div>
                )}
              </div>
            </div>
            <DialogFooter>
              <DialogClose asChild>
                <Button
                  type="button"
                  disabled={
                    isAuthenticating || isSaving || isCreating || isRetreiving
                  }
                >
                  Okay
                </Button>
              </DialogClose>
            </DialogFooter>
          </>
        ) : (
          <form onSubmit={saveInfo}>
            {showInput ? (
              <div className="py-4 grid w-full items-center gap-2">
                <Label htmlFor="connection-name">Connection name</Label>
                <Input
                  required
                  value={name}
                  onChange={(evt) => setName(evt.target.value)}
                  type="text"
                  id="connection-name"
                  placeholder={provider + " technology posts"}
                />
              </div>
            ) : showFbPages ? (
              <ScrollArea className="p-2.5 border rounded-lg h-60 mb-4">
                <div className="flex flex-col gap-2.5">
                  {pages?.map((page) => (
                    <div
                      key={page.id}
                      className="items-top rounded-md flex space-x-2 bg-muted hover:bg-opacity-80 p-2.5"
                    >
                      <Checkbox
                        checked={selectedPages.includes(page.id)}
                        onCheckedChange={(checked) =>
                          handleChange(Boolean(checked), page.id)
                        }
                        id={`checkbox-${page.facebook_name}`}
                      />
                      <div className="grid gap-1.5 leading-none">
                        <label
                          htmlFor="terms1"
                          className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                        >
                          {page.facebook_name}
                        </label>
                      </div>
                    </div>
                  ))}
                </div>
              </ScrollArea>
            ) : (
              <div className="py-4 w-full">
                {isAuthenticated && (
                  <div className="text-center my-4 text-sm animate-pulse">
                    Authenticated successfully
                  </div>
                )}
                <div className="w-full flex justify-center items-center">
                  <AnimatedLoading
                    isLoading={
                      isAuthenticating || isSaving || isCreating || isRetreiving
                    }
                    isSuccess={isAuthenticated}
                    isFailed={isCancelled}
                  />
                </div>

                <div className="flex flex-col gap-2 items-center">
                  {isAuthenticating && (
                    <div className="text-center mt-4 text-sm">
                      Authenticating...
                    </div>
                  )}
                  {isSaving && (
                    <div className="text-center mt-4 text-sm">Saving...</div>
                  )}

                  {isRetreiving && (
                    <div className="text-center mt-4 text-sm">
                      Retreiving data...
                    </div>
                  )}
                  {isCancelled && (
                    <div className="text-center mt-4 text-sm">
                      Connection failed
                    </div>
                  )}
                  {isSuccess && (
                    <div className="text-center mt-4 text-sm">
                      Connection has been created successfully.
                    </div>
                  )}
                </div>
              </div>
            )}
            <DialogFooter>
              {isSuccess ? (
                <DialogClose asChild>
                  <Button type="button">Finish</Button>
                </DialogClose>
              ) : (
                <Button
                  type="submit"
                  disabled={
                    isAuthenticating || isSaving || isCreating || isRetreiving
                  }
                >
                  Save
                </Button>
              )}
            </DialogFooter>
          </form>
        )}
      </DialogContent>
    </Dialog>
  );
}
