import { useEffect, useState } from "react";
import { Button } from "components/ui/button";

import { useAppState } from "context/AppContext";
import { DataTableGroupSchedules } from "./data";
import AddGroupScheduleDialog from "./AddGroupScheduleDialog";
import { Group, GroupSchedule } from "types";
import apiClient from "api";

type Props = {
  selectedGroup: Group | null;
};

const GroupSchedules = ({ selectedGroup }: Props) => {
  const { state } = useAppState();
  const { account } = state.admin;

  const [data, setData] = useState<GroupSchedule[]>([]);
  const [loading, setLoading] = useState(false);
  const [openAddSetting, setOpenAddSetting] = useState(false);

  useEffect(() => {
    setData([]);
    if (!account || !selectedGroup) return;
    const timeout = setTimeout(fetchGroupSchedules, 200);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line
  }, [account, selectedGroup]);

  const fetchGroupSchedules = async () => {
    setLoading(true);
    try {
      const { data } = await apiClient.get(
        "/admin_creative_get_group_schedules",
        {
          headers: {
            request: JSON.stringify({
              creative_id: account?.account_id,
              entity_id: selectedGroup?.group_id,
            }),
          },
        }
      );
      setData(data.message || []);
    } catch (error) {
      console.log("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="mt-8">
        <div className="flex justify-between items-center">
          <h3 className="scroll-m-20 text-2xl font-bold tracking-tight">
            Group schedules
          </h3>
          <Button
            size="sm"
            disabled={!selectedGroup}
            onClick={() => setOpenAddSetting(true)}
          >
            Add group schedule
          </Button>
        </div>
      </div>

      <DataTableGroupSchedules
        data={data}
        loading={loading}
        selectedGroup={selectedGroup}
        fetchData={fetchGroupSchedules}
      />

      <AddGroupScheduleDialog
        open={openAddSetting}
        selectedGroup={selectedGroup}
        fetchData={fetchGroupSchedules}
        onOpenChange={setOpenAddSetting}
      />
    </div>
  );
};

export default GroupSchedules;
