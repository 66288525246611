import { useEffect, useState } from "react";
import AddAgencyDialog from "./AddAgencyDialog";
import { DataTableAgencies } from "./data";
import { Agency } from "types";
import apiClient from "api";
import Breadcrumbs from "components/Shared/Breadcrumbs";

const Agencies = () => {
  const [loading, setLoading] = useState(true);
  const [openAdd, setOpenAdd] = useState(false);
  const [agencies, setAgencies] = useState<Agency[]>([]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      fetchAgencies();
    }, 200);

    return () => clearTimeout(timeout);
  }, []);

  const fetchAgencies = async () => {
    try {
      const { data } = await apiClient.get("/creative_get_agencies");
      setAgencies(data?.message || []);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("Error", error);
    }
  };

  return (
    <div>
      <Breadcrumbs
        // icon={
        //   <Button size="icon" variant="secondary" className="mr-2">
        //     <PanelRightClose className="size-4" />
        //   </Button>
        // }
        routes={[{ title: "Agencies", path: "" }]}
      />

      <div className="space-y-2 mt-8">
        <h3 className="scroll-m-20 text-2xl font-bold tracking-tight">
          Agencies
        </h3>
        <p className="text-base text-muted-foreground">
          Add edit/update agencies.
        </p>
      </div>

      <DataTableAgencies
        data={agencies}
        loading={loading}
        fetchData={fetchAgencies}
        setOpenAdd={setOpenAdd}
      />

      <AddAgencyDialog
        open={openAdd}
        onOpenChange={setOpenAdd}
        fetchData={fetchAgencies}
      />
    </div>
  );
};

export default Agencies;
