import React, { useState, useRef } from "react";
import ReactCrop, {
  Crop,
  PixelCrop,
  centerCrop,
  makeAspectCrop,
} from "react-image-crop";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "components/ui/dialog";
import { Slider } from "components/ui/slider";
import { Button } from "components/ui/button";
import { canvasPreview } from "./canvasPreview";

import "react-image-crop/dist/ReactCrop.css";
import { Label } from "components/ui/label";

function centerAspectCrop(
  mediaWidth: number,
  mediaHeight: number,
  aspect: number
): Crop {
  return centerCrop(
    makeAspectCrop({ unit: "%", width: 90 }, aspect, mediaWidth, mediaHeight),
    mediaWidth,
    mediaHeight
  );
}

type Props = {
  imgSrc: string | null;
  onClose: () => void;
  onSave: (url: Blob) => void;
};

export default function ImageCropDialog({ imgSrc, onClose, onSave }: Props) {
  const imgRef = useRef<HTMLImageElement | null>(null);
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const [scale, setScale] = useState<number>(1);
  const [crop, setCrop] = useState<Crop>();
  const [rotate, setRotate] = useState<number>(0);
  const [aspect, setAspect] = useState<number | undefined>(1);

  const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  };

  const handleSave = async () => {
    if (!imgRef.current || !completedCrop) return;

    const imgDataUrl: any = await canvasPreview(
      imgRef.current,
      completedCrop,
      scale,
      rotate,
      false
    );
    onSave(imgDataUrl);
  };

  return (
    <Dialog open={!!imgSrc} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-screen-md">
        <DialogHeader>
          <DialogTitle>Edit Image</DialogTitle>
        </DialogHeader>
        <div className="flex flex-col items-center space-y-2">
          {!!imgSrc && (
            <ReactCrop
              crop={crop}
              onChange={(_, percentCrop) => {
                setAspect(undefined);
                setCrop(percentCrop);
              }}
              onComplete={(c) => setCompletedCrop(c)}
              aspect={1}
              className="w-[80%] rounded-lg overflow-hidden bg-neutral-200 dark:bg-neutral-700"
            >
              <img
                ref={imgRef}
                alt="Crop"
                src={imgSrc}
                style={{
                  transform: `scale(${scale}) rotate(${rotate}deg)`,
                  display: "block",
                  maxWidth: "100%",
                  maxHeight: "100%",
                  objectFit: "contain",
                  margin: "auto",
                }}
                onLoad={onImageLoad}
              />
            </ReactCrop>
          )}
          <div className="w-80 mx-auto space-y-4">
            <div className="space-y-2">
              <Label htmlFor="scale">Scale: {scale}</Label>
              <Slider
                id="scale"
                min={1}
                max={10}
                step={0.1}
                value={[scale]}
                onValueChange={(value) => setScale(value[0])}
              />
            </div>
            <div className="space-y-2">
              <Label htmlFor="rotate">Rotate: {rotate}°</Label>
              <Slider
                id="rotate"
                min={-180}
                max={180}
                value={[rotate]}
                onValueChange={(value) => setRotate(value[0])}
              />
            </div>
          </div>
        </div>
        <DialogFooter>
          <Button onClick={handleSave}>Save Crop</Button>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
