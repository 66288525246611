import * as React from "react";
import {
  ColumnDef,
  SortingState,
  useReactTable,
  VisibilityState,
  getCoreRowModel,
  getSortedRowModel,
  ColumnFiltersState,
  getFilteredRowModel,
  getPaginationRowModel,
} from "@tanstack/react-table";
import CommonTable from "components/Shared/CommonTable";
import { DataTableColumnHeader } from "components/Shared/AdminTableHeader";
import { Checkbox } from "components/ui/checkbox";
import { Podcast } from "types";
import apiClient from "api";

type Campaign = {
  campaign_id: number;
  key_phrases: string;
  selected: number;
};

type Props = {
  data: Campaign[];
  loading: boolean;
  podcast: Podcast | null;
};

export function DataTableCampaigns({ data, loading, podcast }: Props) {
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );
  const [isSaving, setIsSaving] = React.useState(false);
  const [columnVisibility, setColumnVisibility] =
    React.useState<VisibilityState>({});
  const [rowSelection, setRowSelection] = React.useState({});
  const [selectedCamapigns, setSelectedCampaigns] = React.useState<number[]>(
    []
  );

  React.useEffect(() => {
    setSelectedCampaigns(
      data.filter((d) => d.selected === 1).map((d) => d.campaign_id)
    );
  }, [data]);

  const saveCampaignSet = async (set: number[]) => {
    setIsSaving(true);
    try {
      await apiClient.post(
        "/admin_creative_update_podcast_campaign_set",
        {},
        {
          headers: {
            request: JSON.stringify({
              podcast_id: podcast?.podcast_id,
              podcast_campaign_set: set,
            }),
          },
        }
      );
      setIsSaving(false);
    } catch (error) {
      setIsSaving(false);
    }
  };

  const columns: ColumnDef<Campaign>[] = [
    {
      id: "select",
      header: () => null,
      cell: ({ row }) => (
        <Checkbox
          disabled={isSaving}
          checked={selectedCamapigns.includes(row.original.campaign_id)}
          onCheckedChange={() => {
            const cId = row.original.campaign_id;
            let newSelection = selectedCamapigns;
            if (selectedCamapigns.includes(cId)) {
              newSelection = newSelection.filter((id) => id !== cId);
            } else {
              newSelection = [...newSelection, cId];
            }
            setSelectedCampaigns(newSelection);
            saveCampaignSet(newSelection);
          }}
          aria-label="Select row"
        />
      ),
      enableSorting: false,
      enableHiding: false,
    },
    {
      accessorKey: "campaign_id",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="ID" />
      ),
      cell: ({ row }) => (
        <div className="w-10">{row.getValue("campaign_id")}</div>
      ),
    },
    {
      accessorKey: "key_phrases",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Key phrases" />
      ),
      cell: ({ row }) => (
        <div className="w-full">{row.getValue("key_phrases")}</div>
      ),
    },

    // {
    //   accessorKey: "campaign_name",
    //   header: ({ column }) => (
    //     <DataTableColumnHeader column={column} title="Campaign name" />
    //   ),
    //   cell: ({ row }) => <div>{row.original.campaign_name}</div>,
    // },
    // {
    //   accessorKey: "article_count",
    //   header: ({ column }) => (
    //     <DataTableColumnHeader column={column} title="Article count" />
    //   ),
    //   cell: ({ row }) => <div>{row.original.article_count}</div>,
    // },
  ];

  const table = useReactTable({
    data,
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
    },
  });

  return (
    <CommonTable
      table={table}
      name="Campaigns"
      columns={columns}
      data={data}
      loading={loading}
    />
  );
}
