import { useEffect, useState } from "react";
import { useNavigate, useBlocker, useLocation } from "react-router-dom";
import { ArrowLeft, ChevronLeft, ChevronRight } from "lucide-react";
import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from "components/ui/resizable";
import { Button } from "components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "components/ui/dialog";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "components/ui/alert-dialog";
import { Label } from "components/ui/label";
import { Input } from "components/ui/input";
import SchedulesSet from "./SchedulesSet";
import { ScheduleSet } from "types";
import useMediaQuery from "hooks/useMediaQuery";
import { Connections, Channels } from "components/Setup";
import { useAppState } from "context/AppContext";
import { useToast } from "components/ui/use-toast";
import { DialogClose } from "@radix-ui/react-dialog";
import { ToastAction } from "components/ui/toast";
import api from "api";

export interface ConnectionType {
  channel: string;
  channel_name: string;
  connection_name: string;
  connection_status: number;
  content_connection_status: number;
  id: number;
}

export interface Timing {
  time: string;
  timezone: string;
  weekDay: string;
  frequency: string;
  monthlyDate: string;
  oneTimeDate: Date | undefined;
}

const SetupSchedule = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const width = useMediaQuery();
  const { state } = useAppState();
  const { account_token } = state.user;
  const { toast } = useToast();
  // const history = useNavigation();

  const [name, setName] = useState("");
  const [mobileStep, setMobileStep] = useState(1);
  const [openAlert, setOpenAlert] = useState(false);
  const [schedulesSet, setSchedulesSet] = useState<ScheduleSet[]>([]);
  const [selectedData, setSelectedData] = useState<ScheduleSet>({
    selectedChannel: null,
    selectedConnection: [],
    timing: null,
  });

  const blocker = useBlocker(({ currentLocation, nextLocation }) => {
    return (
      schedulesSet.length > 0 &&
      currentLocation.pathname !== nextLocation.pathname
    );
  });

  const goBackOrRedirect = () => {
    if (location.key === "default") {
      // If there's no history, redirect to the fallback route
      navigate("/posts", { replace: true });
    } else {
      // Otherwise, go back to the previous page
      navigate(-1);
    }
  };

  useEffect(() => {
    if (blocker.state === "blocked") {
      setOpenAlert(true);
    }
  }, [blocker]);

  const nextStep = () => setMobileStep((ps) => ps + 1);
  const prevStep = () => setMobileStep((ps) => ps - 1);

  const disableNext = (): boolean => {
    if (mobileStep === 1 && !selectedData.selectedChannel) return true;
    if (mobileStep === 2 && !selectedData.selectedConnection) return true;
    if (mobileStep === 3 && schedulesSet.length === 0) return true;

    return false;
  };

  const handleSave = async () => {
    toast({
      title: "Processing schedules...",
      description: "Please wait while we save your schedules.",
    });

    let allSuccessful = true;

    for (const item of schedulesSet) {
      try {
        const {
          data: { message: data },
        } = await api.post(
          "/add_scheduled_linkage",
          {},
          {
            headers: {
              request: JSON.stringify({
                content_source: 1,
                schedule_name: name,
                channel_id: item.selectedConnection[0].id,
                content_id: item.selectedChannel?.campaign_id,
              }),
              token: account_token,
            },
          }
        );

        const id = data[0]?.schedule_id;

        if (data[0] && id) {
          await api.post(
            "/add_social_schedule",
            {},
            {
              headers: {
                request: JSON.stringify({
                  id,
                  time: item.timing.time,
                  tz: item.timing.timezone,
                }),
                token: account_token,
              },
            }
          );
        }
      } catch (error) {
        console.error("Error processing schedule data:", error);
        allSuccessful = false;
      }
    }

    if (allSuccessful) {
      toast({
        description: "Schedule(s) created successfully",
        action: (
          <ToastAction
            altText="View schedule"
            onClick={() => navigate("/schedules")}
          >
            View schedule
          </ToastAction>
        ),
      });
    } else {
      toast({
        description: "Some schedules failed to create. Please try again.",
        variant: "destructive",
      });
    }

    setSchedulesSet([]);
  };

  return (
    <div className="overflow-hidden h-screen">
      <div className="border-b sticky top-0 backdrop-blur-sm bg-white/30 dark:bg-black/30 z-10">
        <div className="flex items-center p-2">
          <div className="flex items-center gap-2">
            <Button onClick={goBackOrRedirect} size="sm" variant="ghost">
              <ArrowLeft size={20} className="mr-2" />
              Back
            </Button>
          </div>

          <div className="ml-auto flex items-center space-x-4">
            <Dialog>
              <DialogTrigger asChild>
                <Button
                  disabled={schedulesSet?.length === 0}
                  size="sm"
                  variant="ghost"
                >
                  Save schedule
                </Button>
              </DialogTrigger>
              <DialogContent className="sm:max-w-[425px]">
                <DialogHeader>
                  <DialogTitle>Save schedule</DialogTitle>
                  <DialogDescription>
                    Enter name of you schedule set.
                  </DialogDescription>
                </DialogHeader>
                <div className="grid gap-4 py-4">
                  <div className="grid grid-cols-4 items-center gap-4">
                    <Label htmlFor="name" className="text-right">
                      Name
                    </Label>
                    <Input
                      id="name"
                      value={name}
                      onChange={(evt) => setName(evt.target.value)}
                      className="col-span-3"
                    />
                  </div>
                </div>
                <DialogFooter>
                  <DialogClose asChild>
                    <Button type="button" onClick={handleSave}>
                      Save
                    </Button>
                  </DialogClose>
                </DialogFooter>
              </DialogContent>
            </Dialog>
          </div>
        </div>
      </div>

      <ResizablePanelGroup
        direction="horizontal"
        onLayout={(sizes: number[]) => {
          document.cookie = `react-resizable-panels:layout=${JSON.stringify(
            sizes
          )}`;
        }}
        style={{
          display: width < 1024 ? "none" : "flex",
        }}
        className="h-full lg:flex hidden items-stretch"
      >
        <ResizablePanel minSize={5} maxSize={25}>
          <Channels
            selectedData={selectedData}
            setSelectedData={setSelectedData}
          />
        </ResizablePanel>
        <ResizableHandle withHandle />
        <ResizablePanel
          defaultSize={50}
          minSize={width < 1024 ? 0 : 10}
          maxSize={50}
        >
          <SchedulesSet
            selectedData={selectedData}
            schedulesSet={schedulesSet}
            setSchedulesSet={setSchedulesSet}
          />
        </ResizablePanel>
        <ResizableHandle withHandle />
        <ResizablePanel minSize={5} maxSize={25}>
          <Connections
            selectedData={selectedData}
            setSelectedData={setSelectedData}
          />
        </ResizablePanel>
      </ResizablePanelGroup>

      {/* Responsive ui for smaller screens */}
      <div className="lg:hidden h-full">
        {mobileStep === 1 && (
          <Channels
            selectedData={selectedData}
            setSelectedData={setSelectedData}
          />
        )}
        {mobileStep === 2 && (
          <Connections
            selectedData={selectedData}
            setSelectedData={setSelectedData}
          />
        )}
        {mobileStep === 3 && (
          <SchedulesSet
            selectedData={selectedData}
            schedulesSet={schedulesSet}
            setSchedulesSet={setSchedulesSet}
          />
        )}
      </div>

      <div className="lg:hidden border-t sticky bottom-0 backdrop-blur-sm bg-white/30 dark:bg-black/30 z-10">
        <div className="flex items-center p-2">
          <div className="flex items-center gap-2">
            <Button
              onClick={prevStep}
              size="sm"
              variant="ghost"
              disabled={mobileStep === 1}
            >
              <ChevronLeft size={16} className="mr-2" />
              Previous
            </Button>
            {/* <span className="text-sm font-medium">Channels</span> */}
          </div>

          <div className="ml-auto flex items-center space-x-4">
            <div className="flex items-center gap-2">
              {/* <span className="text-sm font-medium">Connections</span> */}

              <Button
                onClick={nextStep}
                size="sm"
                variant="ghost"
                disabled={disableNext() || mobileStep === 3}
              >
                Next <ChevronRight size={16} className="ml-2" />
              </Button>
            </div>
          </div>
        </div>
      </div>

      <AlertDialog open={openAlert} onOpenChange={setOpenAlert}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
            <AlertDialogDescription>
              All unsaved changes will be lost. Do you wish to leave this page?
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction
              onClick={() => {
                if (blocker && blocker.proceed) {
                  blocker.proceed();
                }
              }}
            >
              Continue
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
};

export default SetupSchedule;
