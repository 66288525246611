import React, { useEffect, useState } from "react";
import { RefreshCw } from "lucide-react";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "components/ui/dialog";
import { Label } from "components/ui/label";
import { Button } from "components/ui/button";
import { Input } from "components/ui/input";
import { useAppState } from "context/AppContext";
import { Account } from "types";
import apiClient from "api";

type Props = {
  open: boolean;
  editData?: Account | null;
  fetchData: () => void;
  onOpenChange: (value: boolean) => void;
};

const AddAccountDialog = ({
  open,
  editData,
  fetchData,
  onOpenChange,
}: Props) => {
  const { state } = useAppState();
  const { client } = state.admin;
  const [submitting, setSubmitting] = useState(false);
  const [accountName, setAccountName] = useState("");
  const [linkedinCompanyId, setLinkedinCompanyId] = useState("");
  const [linkedinCompanyName, setLinkedinCompanyName] = useState("");

  useEffect(() => {
    if (!editData) return;
    setAccountName(editData.account_name || "");
    setLinkedinCompanyId(editData.linkedin_company_id || "");
    setLinkedinCompanyName(editData.linkedin_company_name || "");
  }, [editData]);

  const addAccount = async (evt: React.FormEvent) => {
    evt.preventDefault();
    setSubmitting(true);
    if (Boolean(editData)) {
      try {
        await apiClient.post(
          "/admin_creative_update_account",
          {},
          {
            headers: {
              request: JSON.stringify({
                ...editData,
                account_name: accountName,
                client_id: client?.customer_id,
              }),
            },
          }
        );
        onOpenChange(false);
        fetchData();
      } catch (error) {
        console.log("Error:", error);
      } finally {
        setSubmitting(false);
      }
    } else {
      try {
        await apiClient.post(
          "/admin_creative_add_account",
          {},
          {
            headers: {
              request: JSON.stringify({
                account_name: accountName,
                client_id: client?.customer_id,
                linkedin_company_id: linkedinCompanyId,
                linkedin_company_name: linkedinCompanyName,
              }),
            },
          }
        );

        onOpenChange(false);
        fetchData();
      } catch (error) {
        console.log("Error:", error);
      } finally {
        setSubmitting(false);
      }
    }
  };

  return (
    <>
      <Dialog open={open} onOpenChange={onOpenChange}>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>{!!editData ? "Edit" : "Add"} account</DialogTitle>
          </DialogHeader>

          <form className="grid gap-4" onSubmit={addAccount}>
            <div className="grid gap-2">
              <Label htmlFor="account-name">Account name</Label>
              <div className="relative">
                <Input
                  id="account-name"
                  required
                  value={accountName}
                  onChange={(evt) => {
                    const val = evt.target.value;
                    setAccountName(val);
                  }}
                />
              </div>
            </div>

            {Boolean(editData) && (
              <div className="grid gap-2">
                <Label htmlFor="linkedin-company-name">
                  LinkedIn company name
                </Label>
                <div className="relative">
                  <Input
                    id="linkedin-company-name"
                    value={linkedinCompanyName}
                    onChange={(evt) => {
                      const val = evt.target.value;
                      setLinkedinCompanyName(val);
                    }}
                  />
                </div>
              </div>
            )}
            {Boolean(editData) && (
              <div className="grid gap-2">
                <Label htmlFor="linkedin-company-id">LinkedIn company id</Label>
                <div className="relative">
                  <Input
                    id="linkedin-company-id"
                    value={linkedinCompanyId}
                    onChange={(evt) => {
                      const val = evt.target.value;
                      setLinkedinCompanyId(val);
                    }}
                  />
                </div>
              </div>
            )}

            <DialogFooter>
              <DialogClose asChild>
                <Button
                  disabled={submitting}
                  type="button"
                  size="sm"
                  variant="outline"
                >
                  Cancel
                </Button>
              </DialogClose>
              <Button disabled={submitting} type="submit" size="sm">
                {submitting && (
                  <RefreshCw className="size-4 animate-spin mr-2" />
                )}{" "}
                Save
              </Button>
            </DialogFooter>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddAccountDialog;
