import { useEffect, useState } from "react";
import { ChevronLeft, ChevronRight, GalleryVerticalEnd } from "lucide-react";
import { Separator } from "components/ui/separator";
import { ScrollArea } from "components/ui/scroll-area";
import CustomPagiantion from "components/Shared/Pagination";

import { Article, ScheduleSet } from "types";
import ArticleItem from "./ArticleItem";
import { useAppState } from "context/AppContext";
import { cn } from "lib/utils";
import apiClient from "api";

type Props = {
  selectedData: ScheduleSet;
  smallScreen?: boolean;
  setSelectedData: React.Dispatch<React.SetStateAction<ScheduleSet>>;
};

const ArticlesView = ({
  smallScreen = false,
  selectedData,
  setSelectedData,
}: Props) => {
  const { state } = useAppState();
  const { account_token } = state.user;
  const [loading, setLoading] = useState(false);
  const [articleList, setArticleList] = useState<Article[]>([]);
  const [selected, setSelected] = useState<Article | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    if (!account_token || !selectedData.selectedChannel) return;
    setLoading(true);
    const timeout = setTimeout(getContentList, 200);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line
  }, [selectedData.selectedChannel, currentPage]);

  const onSelect = (article: Article) => {
    const newSelected = selected
      ? article.article_id === selected.article_id
        ? null
        : article
      : article;

    setSelected(newSelected);
    setSelectedData((ps) => ({ ...ps, article: newSelected }));
  };

  const getContentList = async () => {
    try {
      const {
        data: { message },
      } = await apiClient.get("/get_content_list_public", {
        headers: {
          token: account_token,
          request: JSON.stringify({
            content_id: selectedData.selectedChannel?.campaign_id,
            content_source: 1,
            page_size: 25,
            page_start: currentPage,
          }),
        },
      });
      const articles: Article[] = message.articles;
      setArticleList(articles);
      setTotalPages(Math.floor(message.newsletter.article_count / 25));
      setLoading(false);
    } catch (error) {
      console.log("Error", error);
      setLoading(false);
    }
  };

  return (
    <>
      <div className="flex flex-col sm:flex-row gap-2 lg:h-[52px] items-center justify-between px-4 py-2">
        <div className="flex-1 flex items-center justify-start">
          <GalleryVerticalEnd size={18} className={cn("text-primary me-2")} />
          <h1 className="inline-flex items-center font-bold">
            Articles to post
          </h1>
        </div>
        <div className="ml-auto">
          {selectedData?.selectedConnection &&
            selectedData?.selectedChannel && (
              <CustomPagiantion
                totalPages={totalPages}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            )}
        </div>
      </div>
      <Separator />
      <ScrollArea className="h-[calc(100vh-182px)] md:h-[calc(100vh-148px)] lg:h-[calc(100vh-112px)] p-4">
        {!selectedData?.selectedConnection || !selectedData?.selectedChannel ? (
          <div>
            {!selectedData?.selectedChannel ? (
              <div className="h-[500px] text-center flex items-center justify-between">
                <h1 className="flex items-center">
                  <span className="text-5xl animate-pulse">
                    <ChevronLeft size={42} />
                  </span>
                </h1>
                <div className="flex flex-col items-center max-w-sm">
                  <h1 className="text-xl font-bold flex items-center">
                    Select channel
                  </h1>
                  <p className="text-sm text-muted-foreground">
                    Select channel to view the list of latest articles.
                  </p>
                </div>
                <div></div>
              </div>
            ) : !selectedData?.selectedConnection ? (
              <div className="h-[500px] text-center flex items-center justify-between">
                <div></div>
                <div className="flex flex-col items-center max-w-sm">
                  <h1 className="text-xl font-bold flex items-center">
                    Select connection
                  </h1>
                  <p className="text-sm text-muted-foreground">
                    Select where you would like to post.
                  </p>
                </div>
                <h1 className="text-2xl font-medium flex items-center">
                  <span className="text-5xl animate-pulse">
                    <ChevronRight size={42} />
                  </span>
                </h1>
              </div>
            ) : (
              ""
            )}
          </div>
        ) : (
          <div
            className={cn(
              "grid gap-4",
              smallScreen
                ? "grid-cols-1 sm:grid-cols-2 md:grid-cols-3"
                : "lg:grid-cols-2 xl:grid-cols-3"
            )}
          >
            {loading ? (
              <div className="mt-12 col-span-3 flex items-center justify-center">
                <div className="flex flex-col justify-center items-center">
                  <div className="size-8 border-2 border-t-2 border-muted border-t-primary rounded-full animate-spin"></div>
                  <span className="text-sm text-center mt-4 text-primary">
                    Please wait, Loading...
                  </span>
                </div>
              </div>
            ) : articleList.length > 0 ? (
              articleList.map((article) => (
                <ArticleItem
                  key={article.article_id}
                  refetch={getContentList}
                  article={article}
                  onSelect={onSelect}
                  campaignId={selectedData.selectedChannel!.campaign_id}
                  isSelected={article.article_id === selected?.article_id}
                />
              ))
            ) : (
              <div className="mt-12 col-span-3 flex items-center justify-center">
                <div className="flex flex-col justify-center items-center">
                  <svg
                    className="w-20 h-20 mx-auto my-8 text-gray-600 dark:text-gray-200"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M7 3a1 1 0 000 2h6a1 1 0 100-2H7zM4 7a1 1 0 011-1h10a1 1 0 110 2H5a1 1 0 01-1-1zM2 11a2 2 0 012-2h12a2 2 0 012 2v4a2 2 0 01-2 2H4a2 2 0 01-2-2v-4z"></path>
                  </svg>
                  <span className="text-2xl lg:text-4xl font-medium text-center mt-4 text-primary">
                    No articles available.
                  </span>
                </div>
              </div>
            )}
          </div>
        )}
      </ScrollArea>
    </>
  );
};

export default ArticlesView;
