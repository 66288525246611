import { CheckCircledIcon, CircleIcon } from "@radix-ui/react-icons";

export const channel = [
  {
    value: "facebook",
    label: "Facebook",
  },
  {
    value: "twitter",
    label: "Twitter",
  },
  {
    value: "linkedin",
    label: "LinkedIn",
  },
];

export const statuses = [
  {
    value: "1",
    label: "Connected",
    icon: CheckCircledIcon,
  },
  {
    value: "0",
    label: "Not Connected",
    icon: CircleIcon,
  },
];
