import { useEffect, useState } from "react";
import { Button } from "components/ui/button";

import { useAppState } from "context/AppContext";
import AddHintScheduleDialog from "./AddHintScheduleDialog";
import { DataTableHintSchedules } from "./data";
import { HintSchedule, HintSubject } from "types";
import apiClient from "api";

type Props = {
  selectedSubject: HintSubject | null;
};

const HintSchedules = ({ selectedSubject }: Props) => {
  const { state } = useAppState();
  const { account } = state.admin;

  const [data, setData] = useState<HintSchedule[]>([]);
  const [loading, setLoading] = useState(false);
  const [openAddSetting, setOpenAddSetting] = useState(false);

  useEffect(() => {
    setData([]);
    if (!account || !selectedSubject) return;

    const timeout = setTimeout(fetchHintSchedules, 200);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line
  }, [account, selectedSubject]);

  const fetchHintSchedules = async () => {
    setLoading(true);
    try {
      const { data } = await apiClient.get(
        "/admin_creative_get_hint_schedules",
        {
          headers: {
            request: JSON.stringify({
              creative_id: account?.account_id,
              hint_subject_id: selectedSubject?.hint_subject_id,
            }),
          },
        }
      );

      setData(data.message || []);
    } catch (error) {
      console.log("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="mt-8">
        <div className="flex justify-between items-center">
          <h3 className="scroll-m-20 text-2xl font-bold tracking-tight">
            Hint schedules
          </h3>
          <Button
            size="sm"
            onClick={() => setOpenAddSetting(true)}
            disabled={!Boolean(selectedSubject)}
          >
            Add hint schedule
          </Button>
        </div>
      </div>

      <DataTableHintSchedules
        data={data}
        loading={loading}
        selectedSubject={selectedSubject}
        fetchData={fetchHintSchedules}
      />

      <AddHintScheduleDialog
        open={openAddSetting}
        selectedSubject={selectedSubject}
        fetchData={fetchHintSchedules}
        onOpenChange={setOpenAddSetting}
      />
    </div>
  );
};

export default HintSchedules;
