import React, { memo, useCallback, useEffect } from "react";
import { IResolveParams, objectType } from "reactjs-social-login";

interface Props {
  state?: string;
  scope?: string;
  client_id: string;
  className?: string;
  redirect_uri: string;
  client_secret: string;
  response_type?: string;
  isOnlyGetCode?: boolean;
  isOnlyGetToken?: boolean;
  children?: React.ReactNode;
  onLoginStart?: () => void;
  onReject: (reject: string | objectType) => void;
  onResolve: ({ provider, data }: IResolveParams) => void;
}

const LINKEDIN_URL: string = "https://www.linkedin.com/oauth/v2";

export const LoginSocialLinkedin = ({
  state = "",
  scope = "r_liteprofile",
  client_id,
  client_secret,
  className = "",
  redirect_uri,
  response_type = "code",
  isOnlyGetCode = false,
  isOnlyGetToken = false,
  children,
  onLoginStart,
  onReject,
  onResolve,
}: Props) => {
  useEffect(() => {
    const popupWindowURL = new URL(window.location.href);
    const code = popupWindowURL.searchParams.get("code");
    const state = popupWindowURL.searchParams.get("state");
    if (state?.includes("_linkedin") && code) {
      localStorage.setItem("linkedin", code);
      window.close();
    }
  }, []);

  const getAccessToken = useCallback(
    (code: string) => {
      if (isOnlyGetCode) onResolve({ provider: "linkedin", data: { code } });
    },
    [onResolve, isOnlyGetCode]
  );

  const handlePostMessage = useCallback(
    async ({ type, code, provider }: objectType) =>
      type === "code" &&
      provider === "linkedin" &&
      code &&
      getAccessToken(code),
    [getAccessToken]
  );

  const onChangeLocalStorage = useCallback(() => {
    window.removeEventListener("storage", onChangeLocalStorage, false);
    const code = localStorage.getItem("linkedin");
    if (code) {
      handlePostMessage({ provider: "linkedin", type: "code", code });
      localStorage.removeItem("linkedin");
    }
  }, [handlePostMessage]);

  const onLogin = useCallback(() => {
    onLoginStart && onLoginStart();
    window.addEventListener("storage", onChangeLocalStorage, false);
    const oauthUrl = `${LINKEDIN_URL}/authorization?response_type=${response_type}&client_id=${client_id}&scope=${scope}&state=${
      state + "_linkedin"
    }&redirect_uri=${redirect_uri}&prompt=login`;
    const width = 450;
    const height = 730;
    const left = window.screen.width / 2 - width / 2;
    const top = window.screen.height / 2 - height / 2;
    window.open(
      oauthUrl,
      "Linkedin",
      "menubar=no,location=no,resizable=no,scrollbars=no,status=no, width=" +
        width +
        ", height=" +
        height +
        ", top=" +
        top +
        ", left=" +
        left
    );
  }, [
    onLoginStart,
    onChangeLocalStorage,
    response_type,
    client_id,
    scope,
    state,
    redirect_uri,
  ]);

  return (
    <div className={className} onClick={onLogin}>
      {children}
    </div>
  );
};

export default memo(LoginSocialLinkedin);
