import React, { createContext, useContext, useEffect, useState } from "react";
import { Connection } from "types";

const ConnectionContext = createContext<{
  isOpen: boolean;
  provider: string;
  isSaving: boolean;
  isRefresh: boolean;
  dataToSend: string;
  isSuccess: boolean;
  isCreating: boolean;
  isDeleting: boolean;
  isCancelled: boolean;
  isRetreiving: boolean;
  isReconnecting: boolean;
  connectionState: string;
  isAuthenticated: boolean;
  openStatusModal: boolean;
  isAuthenticating: boolean;
  refreshConnection: boolean;
  connectionData: Connection | null;
  setDataToSend: React.Dispatch<React.SetStateAction<any>>;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setProvider: React.Dispatch<React.SetStateAction<string>>;
  setIsSaving: React.Dispatch<React.SetStateAction<boolean>>;
  setIsRefresh: React.Dispatch<React.SetStateAction<boolean>>;
  setIsSuccess: React.Dispatch<React.SetStateAction<boolean>>;
  setIsCreating: React.Dispatch<React.SetStateAction<boolean>>;
  setIsDeleting: React.Dispatch<React.SetStateAction<boolean>>;
  setIsCancelled: React.Dispatch<React.SetStateAction<boolean>>;
  setIsRetreiving: React.Dispatch<React.SetStateAction<boolean>>;
  setIsReconnecting: React.Dispatch<React.SetStateAction<boolean>>;
  setConnectionState: React.Dispatch<React.SetStateAction<string>>;
  setOpenStatusModal: React.Dispatch<React.SetStateAction<boolean>>;
  setIsAuthenticated: React.Dispatch<React.SetStateAction<boolean>>;
  setIsAuthenticating: React.Dispatch<React.SetStateAction<boolean>>;
  setRefreshConnection: React.Dispatch<React.SetStateAction<boolean>>;
  setConnectionData: React.Dispatch<React.SetStateAction<Connection | null>>;
}>({
  provider: "",
  isOpen: false,
  dataToSend: "",
  isSaving: false,
  isRefresh: false,
  isSuccess: false,
  isCreating: false,
  isDeleting: false,
  isCancelled: false,
  isRetreiving: false,
  connectionData: null,
  connectionState: "",
  isReconnecting: false,
  openStatusModal: false,
  isAuthenticated: false,
  isAuthenticating: false,
  refreshConnection: false,
  setDataToSend: Object || String,
  setIsOpen: Boolean,
  setProvider: String,
  setIsSaving: Boolean,
  setIsRefresh: Boolean,
  setIsSuccess: Boolean,
  setIsDeleting: Boolean,
  setIsCreating: Boolean,
  setIsCancelled: Boolean,
  setIsRetreiving: Boolean,
  setConnectionData: Object,
  setIsReconnecting: Boolean,
  setConnectionState: String,
  setIsAuthenticated: Boolean,
  setOpenStatusModal: Boolean,
  setIsAuthenticating: Boolean,
  setRefreshConnection: Boolean,
});

type Props = {
  children: React.ReactNode;
};

const ConnectionProvider = ({ children }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [provider, setProvider] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [isRefresh, setIsRefresh] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [dataToSend, setDataToSend] = useState<any>("");
  const [isCancelled, setIsCancelled] = useState(false);
  const [isRetreiving, setIsRetreiving] = useState(false);
  const [connectionState, setConnectionState] = useState("");
  const [isReconnecting, setIsReconnecting] = useState(false);
  const [openStatusModal, setOpenStatusModal] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const [refreshConnection, setRefreshConnection] = useState(false);
  const [connectionData, setConnectionData] = useState<Connection | null>(null);

  useEffect(() => {
    if (!isOpen) {
      setProvider("");
      setIsRetreiving(false);
      setIsReconnecting(false);
      setIsAuthenticated(false);
      setIsAuthenticating(false);
      setConnectionData(null);
      setConnectionState("");
    }
  }, [isOpen]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.size === 0) return;
    const code = params.get("code");
    const error = params.get("error");
    // const state = params.get("state");

    if (Boolean(error || code)) {
      setIsOpen(true);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <ConnectionContext.Provider
      value={{
        isOpen,
        isSaving,
        provider,
        isSuccess,
        isRefresh,
        dataToSend,
        isCreating,
        isDeleting,
        isCancelled,
        isRetreiving,
        connectionData,
        isReconnecting,
        openStatusModal,
        connectionState,
        isAuthenticated,
        isAuthenticating,
        refreshConnection,
        setIsOpen,
        setProvider,
        setIsSaving,
        setIsSuccess,
        setIsRefresh,
        setIsCreating,
        setDataToSend,
        setIsDeleting,
        setIsCancelled,
        setIsRetreiving,
        setIsReconnecting,
        setConnectionData,
        setOpenStatusModal,
        setIsAuthenticated,
        setConnectionState,
        setIsAuthenticating,
        setRefreshConnection,
      }}
    >
      {children}
    </ConnectionContext.Provider>
  );
};

export default ConnectionProvider;

export const useConnection = () => useContext(ConnectionContext);
