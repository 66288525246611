import * as React from "react";
import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  VisibilityState,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { CopyIcon } from "lucide-react";

import { DataTableColumnHeader } from "components/Shared/AdminTableHeader";
import CommonTable from "components/Shared/CommonTable";
import { useToast } from "components/ui/use-toast";
import { CreativeURL } from "types";

type Props = {
  data: CreativeURL[];
  loading: boolean;
};

export function DataTableURLs({ data, loading }: Props) {
  const { toast } = useToast();
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );
  const [columnVisibility, setColumnVisibility] =
    React.useState<VisibilityState>({});
  const [rowSelection, setRowSelection] = React.useState({});

  const columns: ColumnDef<CreativeURL>[] = [
    // {
    //   id: "select",
    //   header: ({ table }) => (
    //     <Checkbox
    //       checked={
    //         table.getIsAllPageRowsSelected() ||
    //         (table.getIsSomePageRowsSelected() && "indeterminate")
    //       }
    //       onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
    //       aria-label="Select all"
    //     />
    //   ),
    //   cell: ({ row }) => (
    //     <Checkbox
    //       checked={row.getIsSelected()}
    //       onCheckedChange={(value) => row.toggleSelected(!!value)}
    //       aria-label="Select row"
    //     />
    //   ),
    //   enableSorting: false,
    //   enableHiding: false,
    // },
    {
      accessorKey: "creative_account_id",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Account ID" />
      ),
      cell: ({ row }) => (
        <div className="w-14">{row.original.creative_account_id}</div>
      ),
    },
    {
      accessorKey: "target_url",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Target URL" />
      ),
      cell: ({ row }) => (
        <div
          className="max-w-80 inline-flex justify-between items-center space-x-2"
          title={row.original.target_url}
        >
          <span className="truncate">{row.original.target_url}</span>
          <span
            className="ml-auto"
            onClick={() => {
              navigator.clipboard.writeText(row.original.target_url);
              toast({
                title: "URL copied",
              });
            }}
          >
            <CopyIcon className="size-3.5" />
          </span>
        </div>
      ),
    },
    {
      accessorKey: "modified_url",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Modified URL" />
      ),
      cell: ({ row }) => (
        <div
          className="max-w-80 inline-flex justify-between items-center space-x-2"
          title={row.original.modified_url}
        >
          <span className="truncate">{row.original.modified_url}</span>
          <span
            className="ml-auto"
            onClick={() => {
              navigator.clipboard.writeText(row.original.modified_url);
              toast({
                title: "URL copied",
              });
            }}
          >
            <CopyIcon className="size-3.5" />
          </span>
        </div>
      ),
    },
  ];

  const table = useReactTable({
    data,
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
    },
  });

  return (
    <CommonTable
      table={table}
      name="URLs"
      columns={columns}
      data={data}
      loading={loading}
    />
  );
}
