import { useEffect, useState } from "react";
import { RefreshCwIcon } from "lucide-react";
import { Button } from "components/ui/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "components/ui/dialog";
import { Input } from "components/ui/input";
import { Label } from "components/ui/label";
import { useAppState } from "context/AppContext";
import { WordpressConnection } from "types";
import { cn } from "lib/utils";
import apiClient from "api";

type Props = {
  open: boolean;
  isAdmin?: boolean;
  wp_data?: WordpressConnection | null;
  fetchData?: () => void;
  onOpenChange: (value: boolean) => void;
};

const WordpressAddDialog: React.FunctionComponent<Props> = ({
  open,
  wp_data,
  isAdmin = false,
  fetchData,
  onOpenChange,
}) => {
  const { state, dispatch } = useAppState();
  const { account } = state.user;
  const { account: adminAccount } = state.admin;
  const [url, setUrl] = useState("");
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (!wp_data) return;

    setUrl(wp_data.site_url);
    setName(wp_data.connection_name);
    setUsername(wp_data.wp_username);
    setPassword(wp_data.app_password);
  }, [wp_data]);

  const handleSubmit = (evt: React.FormEvent) => {
    evt.preventDefault();

    if (wp_data) {
      updateConnection();
    } else {
      createConnection();
    }
  };

  const createConnection = async () => {
    setIsSubmitting(true);
    try {
      await apiClient.post(
        "/creative_add_wordpress_connection",
        {},
        {
          headers: {
            request: JSON.stringify({
              creative_id: isAdmin
                ? adminAccount?.account_id
                : account?.account_id,
              connection_name: name,
              wp_username: username,
              app_password: password,
              site_url: url,
            }),
          },
        }
      );

      if (isAdmin && fetchData) fetchData();
      if (!isAdmin) dispatch({ type: "SET_REFRESH", payload: "wordpress" });
      setIsSubmitting(false);
      onOpenChange(false);
    } catch (error) {
      setIsSubmitting(false);
      console.log("Error:", error);
    }
  };

  const updateConnection = async () => {
    setIsSubmitting(true);
    try {
      await apiClient.post(
        "/creative_update_wordpress_connection",
        {},
        {
          headers: {
            request: JSON.stringify({
              wordpress_connection_id: wp_data?.wp_connection_id,
              creative_id: isAdmin
                ? adminAccount?.account_id
                : account?.account_id,
              connection_name: name,
              wp_username: username,
              app_password: password,
              site_url: url,
            }),
          },
        }
      );
      if (isAdmin && fetchData) fetchData();
      if (!isAdmin) dispatch({ type: "SET_REFRESH", payload: "wordpress" });
      setIsSubmitting(false);
      onOpenChange(false);
    } catch (error) {
      setIsSubmitting(false);
      console.log("Error:", error);
    }
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[425px]">
        <form onSubmit={handleSubmit} className={cn("grid items-start gap-4")}>
          <DialogHeader>
            <DialogTitle>
              {wp_data ? "Edit" : "Add"} wordpress connection
            </DialogTitle>
            <DialogDescription>
              Make sure to enter correct username and password for the given
              site url.
            </DialogDescription>
          </DialogHeader>

          <div className="grid gap-2">
            <Label htmlFor="name">Connection name</Label>
            <Input
              id="name"
              placeholder="My wordpress connection"
              value={name}
              onChange={(evt) => setName(evt.target.value)}
            />
          </div>

          <div className="grid gap-2">
            <Label htmlFor="site-url">Site URL</Label>
            <Input
              id="site-url"
              placeholder="https://wordpress-site.com"
              value={url}
              onChange={(evt) => setUrl(evt.target.value)}
            />
          </div>
          <div className="grid gap-2">
            <Label htmlFor="username">Username</Label>
            <Input
              id="username"
              placeholder="jane@doe"
              value={username}
              onChange={(evt) => setUsername(evt.target.value)}
            />
          </div>
          <div className="grid gap-2">
            <Label htmlFor="password">Password</Label>
            <Input
              id="password"
              placeholder="••••••••••"
              value={password}
              onChange={(evt) => setPassword(evt.target.value)}
            />
          </div>

          <DialogFooter>
            <DialogClose asChild>
              <Button variant="outline" disabled={isSubmitting} type="button">
                Cancel
              </Button>
            </DialogClose>
            <Button type="submit" disabled={isSubmitting}>
              {isSubmitting && (
                <RefreshCwIcon className="mr-2 size-4 animate-spin" />
              )}
              Save
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default WordpressAddDialog;
