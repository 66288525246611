import { useState } from "react";
import { Cross2Icon } from "@radix-ui/react-icons";
import { Table } from "@tanstack/react-table";

import { Button } from "components/ui/button";
import { Input } from "components/ui/input";
import { DataTableViewOptions } from "./data-table-view-options";

import { channel, type } from "./data";
import { DataTableFacetedFilter } from "./data-table-faceted-filter";

interface DataTableToolbarProps<TData> {
  table: Table<TData>;
}

export function DataTableToolbar<TData>({
  table,
}: DataTableToolbarProps<TData>) {
  const isFiltered = table.getState().columnFilters.length > 0;
  const [searchTerm, setSearchTerm] = useState<string>("");

  // Function to set filter value for all searchable columns
  const setGlobalFilterValue = (value: string) => {
    const columns = table.getAllColumns();
    columns.forEach((column) => {
      if (column.id === "title" || column.id === "connection_name") {
        column.setFilterValue(value.toLowerCase());
      }
    });
  };

  return (
    <div className="flex items-center justify-between">
      <div className="flex flex-col w-full sm:flex-row sm:flex-1 sm:items-center space-y-2 sm:space-y-0 sm:space-x-2">
        <Input
          placeholder="Filter posts..."
          value={searchTerm}
          onChange={(event) => {
            setSearchTerm(event.target.value);
            setGlobalFilterValue(event.target.value);
          }}
          className="h-8 w-full sm:w-[250px]"
        />
        <div className="flex space-x-2">
          <DataTableFacetedFilter
            column={table.getColumn("channel_name")}
            title="Channel"
            options={channel}
          />

          {table.getColumn("post_type") && (
            <DataTableFacetedFilter
              column={table.getColumn("post_type")}
              title="Type"
              options={type}
            />
          )}
          {isFiltered && (
            <Button
              variant="ghost"
              onClick={() => {
                table.resetColumnFilters();
                setSearchTerm("");
              }}
              className="h-8 sm:px-2 lg:px-3"
            >
              Reset
              <Cross2Icon className="ml-2 h-4 w-4" />
            </Button>
          )}
        </div>
      </div>
      <DataTableViewOptions table={table} />
    </div>
  );
}
