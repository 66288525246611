import { ColumnDef, Row } from "@tanstack/react-table";
// import { Checkbox } from "components/ui/checkbox";
import { DataTableColumnHeader } from "./data-table-column-header";
import { DataTableRowActions } from "./data-table-row-actions";
import { WordpressConnection } from "types";
import { replaceWithBullet } from "utils/helper-methods";
import { EyeClosedIcon, EyeOpenIcon } from "@radix-ui/react-icons";
import { useState } from "react";

export const columns: ColumnDef<WordpressConnection>[] = [
  // {
  //   id: "select",
  //   header: ({ table }) => (
  //     <Checkbox
  //       checked={
  //         table.getIsAllPageRowsSelected() ||
  //         (table.getIsSomePageRowsSelected() && "indeterminate")
  //       }
  //       onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
  //       aria-label="Select all"
  //       className="translate-y-[2px]"
  //     />
  //   ),
  //   cell: ({ row }) => (
  //     <Checkbox
  //       checked={row.getIsSelected()}
  //       onCheckedChange={(value) => row.toggleSelected(!!value)}
  //       aria-label="Select row"
  //       className="translate-y-[2px]"
  //     />
  //   ),
  //   enableSorting: false,
  //   enableHiding: false,
  // },
  {
    accessorKey: "wp_connection_id",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="ID" />
    ),
    cell: ({ row }) => (
      <div className="w-[80px]">{row.getValue("wp_connection_id")}</div>
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "connection_name",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Connection name" />
    ),
    cell: ({ row }) => {
      return (
        <div className="flex space-x-2">
          <span className="max-w-[300px] truncate font-medium">
            {row.original.connection_name}
          </span>
        </div>
      );
    },
  },
  {
    accessorKey: "wp_username",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Username" />
    ),
    cell: ({ row }) => {
      return <div className="flex space-x-2">{row.original.wp_username}</div>;
    },
  },
  {
    accessorKey: "site_url",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Site URL" />
    ),
    cell: ({ row }) => {
      return <div className="flex space-x-2">{row.original.site_url}</div>;
    },
  },
  {
    accessorKey: "app_password",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="App password" />
    ),
    cell: ({ row }) => <PasswordCell row={row} />,
  },

  {
    id: "actions",
    cell: ({ row }) => <DataTableRowActions row={row} />,
  },
];

interface Props<TData> {
  row: Row<TData>;
}

const PasswordCell = ({ row }: Props<WordpressConnection>) => {
  const [show, setShow] = useState(false);
  return (
    <div className="flex">
      {show ? (
        <span className="w-36 text-wrap">{row.original.app_password}</span>
      ) : (
        <span className="w-28 truncate">
          {replaceWithBullet(row.original.app_password)}
        </span>
      )}

      <span onClick={() => setShow(!show)}>
        {show ? <EyeOpenIcon /> : <EyeClosedIcon />}
      </span>
    </div>
  );
};
