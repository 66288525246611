import { useEffect, useState } from "react";
import Breadcrumbs from "components/Shared/Breadcrumbs";

import { Button } from "components/ui/button";
import { useAppState } from "context/AppContext";
import AccountSelect from "../AccountSelect";

import AddSitemapTypeDialog from "./AddSitemapTypeDialog";
import { DataTableSitemapType } from "./data";
import { SitemapType } from "types";
import apiClient from "api";

const SitemapTypes = () => {
  const { state } = useAppState();
  const { agency, client, account } = state.admin;

  const [data, setData] = useState<SitemapType[]>([]);
  const [loading, setLoading] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);

  useEffect(() => {
    setData([]);
    if (!account) return;
    const timeout = setTimeout(fetchSitemapType, 200);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line
  }, [account]);

  const fetchSitemapType = async () => {
    setLoading(true);
    try {
      const { data } = await apiClient.get("/admin_creative_get_sitemap_types");
      setData(data.message || []);
    } catch (error) {
      console.log("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Breadcrumbs
        // icon={
        //   <Button size="icon" variant="secondary" className="mr-2">
        //     <PanelRightClose className="size-4" />
        //   </Button>
        // }
        routes={[
          { title: agency?.agency_name || "", path: "/admin/agencies" },
          { title: client?.customer_name || "", path: "/admin/clients" },
          { title: account?.account_name || "", path: "/admin/accounts" },
          { title: "Sitemap types", path: "" },
        ]}
      />
      <div className="space-y-2 mt-8">
        <div className="flex justify-between items-center">
          <h3 className="scroll-m-20 text-2xl font-bold tracking-tight">
            Sitemap types
          </h3>
          <AccountSelect />
        </div>
      </div>

      <div className="mt-8">
        <div className="flex justify-end">
          <Button size="sm" onClick={() => setOpenAdd(true)}>
            Add sitemap type
          </Button>
        </div>
      </div>

      <DataTableSitemapType
        data={data}
        loading={loading}
        fetchData={fetchSitemapType}
      />

      <AddSitemapTypeDialog
        fetchData={fetchSitemapType}
        open={openAdd}
        onOpenChange={setOpenAdd}
      />
    </div>
  );
};

export default SitemapTypes;
