import { useEffect, useState } from "react";
import { Cross2Icon } from "@radix-ui/react-icons";
import { Table } from "@tanstack/react-table";

import { Button } from "components/ui/button";
import { Input } from "components/ui/input";
import { useToast } from "components/ui/use-toast";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "components/ui/alert-dialog";

import { channel, frequency } from "./data";
import { useAppState } from "context/AppContext";
import { DataTableViewOptions } from "./data-table-view-options";
import { DataTableFacetedFilter } from "./data-table-faceted-filter";
import apiClient from "api";

interface DataTableToolbarProps<TData> {
  table: Table<TData>;
  setGlobalFilter: (value: string) => void;
}

export function DataTableToolbar<TData>({
  table,
  setGlobalFilter,
}: DataTableToolbarProps<TData>) {
  const { toast } = useToast();
  const { state, dispatch } = useAppState();
  const { account_token } = state.user;
  const isFiltered = table.getState().columnFilters.length > 0;
  const [searchTerm, setSearchTerm] = useState<string>("");

  const [selectedRows, setSelectedRows] = useState<number[]>([]);

  useEffect(() => {
    setSelectedRows(
      table
        .getSelectedRowModel()
        .flatRows.map((r: any) => r.original?.schedule_id)
    );
    // eslint-disable-next-line
  }, [table.getSelectedRowModel().flatRows]);

  const deleteSchedules = async () => {
    toast({ description: "Deleting schedules..." });
    try {
      for (const id of selectedRows) {
        await apiClient.delete("/delete_scheduled_linkage", {
          headers: {
            token: account_token,
            request: JSON.stringify({
              schedule_id: id,
            }),
          },
        });
      }

      toast({ description: "All selected items deleted successfully" });
      dispatch({ type: "SET_REFRESH", payload: "schedules" });
      table.resetRowSelection(true);
      setSelectedRows([]);
    } catch (error) {
      toast({
        description: "An error occurred while deleting data.",
        variant: "destructive",
      });
    }
  };

  return (
    <div className="flex items-center justify-between">
      <div className="flex flex-col w-full sm:flex-row sm:flex-1 sm:items-center space-y-2 sm:space-y-0 sm:space-x-2">
        <Input
          placeholder="Filter schedules..."
          value={searchTerm}
          onChange={(event) => {
            const value = event.target.value;
            setSearchTerm(value);
            setGlobalFilter(value);
          }}
          className="h-8 w-full sm:w-[250px]"
        />
        <div className="flex space-x-2">
          <DataTableFacetedFilter
            column={table.getColumn("frequency")}
            title="Frequency"
            options={frequency}
          />

          <DataTableFacetedFilter
            column={table.getColumn("channel")}
            title="Channel"
            options={channel}
          />

          {isFiltered && (
            <Button
              variant="ghost"
              size="sm"
              onClick={() => {
                table.resetColumnFilters();
                setSearchTerm("");
                setGlobalFilter(""); // Reset the global filter
              }}
              className="h-8 sm:px-2 lg:px-3"
            >
              Reset
              <Cross2Icon className="ml-2 h-4 w-4" />
            </Button>
          )}

          {selectedRows.length > 0 && (
            <AlertDialog>
              <AlertDialogTrigger asChild>
                <Button
                  size="sm"
                  variant="destructive"
                  onClick={() => {}}
                  className="h-8 sm:px-2 lg:px-3"
                >
                  Delete ({selectedRows.length})
                </Button>
              </AlertDialogTrigger>
              <AlertDialogContent>
                <AlertDialogHeader>
                  <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
                  <AlertDialogDescription>
                    This action cannot be undone. This will permanently delete
                    your schedules and remove your data from our servers.
                  </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                  <AlertDialogCancel>Cancel</AlertDialogCancel>
                  <AlertDialogAction onClick={deleteSchedules}>
                    Continue
                  </AlertDialogAction>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialog>
          )}
        </div>
      </div>
      <DataTableViewOptions table={table} />
    </div>
  );
}
