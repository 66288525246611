import { memo, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { columns } from "components/Tables/schedules/columns";
import { DataTable } from "components/Tables/schedules/data-table";
// import { CalendarDateRangePicker } from "components/Tables/schedules/date-range-picker";
import { useAppState } from "context/AppContext";
import ScreenWithTitles from "components/Shared/ScreenWithTitles";
import { Schedule } from "types";
import apiClient from "api";

const Schedules = () => {
  const { state, dispatch } = useAppState();
  const navigate = useNavigate();
  const { account_token, refresh } = state.user;

  const [loading, setLoading] = useState(false);
  const [schedules, setSchedules] = useState<Schedule[]>([]);

  useEffect(() => {
    if (!account_token) return;
    const timeout = setTimeout(fetchSchedules, 100);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line
  }, [refresh, account_token]);

  const fetchSchedules = async () => {
    setLoading(true);
    try {
      const { data }: { data: { message: Schedule[] } } = await apiClient.get(
        "/get_scheduled_linkage_dashboard",
        {
          headers: {
            token: account_token,
          },
        }
      );
      const allSchedules = data?.message || [];
      const activeSchedules =
        allSchedules.length > 0
          ? allSchedules.filter((s) => !s.deleted || s.deleted === 0)
          : [];

      setSchedules(activeSchedules);
      if (refresh === "schedules")
        dispatch({ type: "SET_REFRESH", payload: "" });
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="flex flex-col space-y-8 md:py-4">
      <ScreenWithTitles
        title="Schedules"
        btnTitle="Add schedule"
        handleClick={() => navigate("/schedules/setup")}
        description={"Here's a list of your schedules for this month."}
      />
      <DataTable data={schedules} loading={loading} columns={columns} />
    </div>
  );
};

export default memo(Schedules);
