import { RouteObject } from "react-router-dom";

import Posts from "./private/Posts";
import Container from "Layout/Container";
import Overview from "./private/Overview";
import Schedules from "./private/Schedules";
import ProtectedRoute from "Layout/RootLayout";
import Connections from "./private/Connections";
import Wordpress from "./private/Wordpress";
import {
  Post,
  Login,
  Agencies,
  Clients,
  Profiles,
  Accounts,
  SetupPost,
  PostView,
  SetupSchedule,
  AdminDashbaord,
  SetupConnection,
  AccountSummary,
  AccountCampaigns,
  AccountConnections,
  AccountSocialChannels,
  AccountPosts,
  AccountProfiles,
  AccountWordpress,
  AccountClickfarm,
  AccountBlogPosts,
  AccountAvatars,
  AccountContentTypes,
  AccountPosterTypes,
  AccountGroups,
  AccountPages,
  AccountWebsites,
  AccountTwitterAPIs,
  AccountPostStatuses,
  WordpressOverview,
  FacebookOverview,
  LinkedinOverview,
  TwitterOverview,
  AccountContentSources,
  AccountHintSubjects,
  AccountQuestionSubjects,
  AccountTargetTypes,
  AccountRedirectBuilder,
  AccountSitemapTypes,
  AccountSitemaps,
  AccountEmbeddedMessages,
  AccountPodcastVoices,
  AccountPodcasts,
  UserAgents,
  AccountLinkedIn,
  AccountManualNews,
  AccountClientSocials,
  AccountNotificationSources,
  Notifications,
  TwitterAccountCreator,
} from "routes";

// Define and export the router configuration
export const routes: RouteObject[] = [
  {
    path: "/",
    element: <ProtectedRoute />,
    children: [
      {
        path: "/",
        element: (
          <Container>
            <Overview />
          </Container>
        ),
      },
      {
        path: "/notifications",
        element: (
          <Container navigation={false} container={true}>
            <Notifications />
          </Container>
        ),
      },
      {
        path: "/overview",
        element: (
          <Container>
            <Overview />
          </Container>
        ),
      },
      {
        path: "/overview/website",
        element: (
          <Container>
            <WordpressOverview />
          </Container>
        ),
      },
      {
        path: "/overview/facebook",
        element: (
          <Container>
            <FacebookOverview />
          </Container>
        ),
      },
      {
        path: "/overview/linkedin",
        element: (
          <Container>
            <LinkedinOverview />
          </Container>
        ),
      },
      {
        path: "/overview/twitter",
        element: (
          <Container>
            <TwitterOverview />
          </Container>
        ),
      },
      {
        path: "/overview/user-agents",
        element: (
          <Container>
            <UserAgents />
          </Container>
        ),
      },
      {
        path: "/overview/:social/post/:id",
        element: (
          <Container>
            <PostView />
          </Container>
        ),
      },
      {
        path: "/schedules",
        element: (
          <Container container={true}>
            <Schedules />
          </Container>
        ),
      },
      {
        path: "/schedules/setup",
        element: (
          <Container navigation={false} header={false} fullScreen>
            <SetupSchedule />
          </Container>
        ),
      },
      {
        path: "/connections",
        element: (
          <Container container={true}>
            <Connections />
          </Container>
        ),
      },
      {
        path: "/websites",
        element: (
          <Container container={true}>
            <Wordpress />
          </Container>
        ),
      },
      {
        path: "/connections/setup",
        element: (
          <Container navigation={false} header={false} fullScreen>
            <SetupConnection />
          </Container>
        ),
      },
      {
        path: "/posts",
        element: (
          <Container container={true}>
            <Posts />
          </Container>
        ),
      },
      {
        path: "/posts/setup",
        element: (
          <Container navigation={false} header={false} fullScreen>
            <SetupPost />
          </Container>
        ),
      },
      {
        path: "/posts/:id",
        element: (
          <Container navigation={false} header={true}>
            <Post />
          </Container>
        ),
      },
      {
        path: "/admin",
        element: (
          <Container navigation={false} header={true} fullScreen>
            <AdminDashbaord />
          </Container>
        ),
        children: [
          {
            path: "profiles",
            element: <Profiles />,
          },
          {
            path: "agencies",
            element: <Agencies />,
          },
          {
            path: "clients",
            element: <Clients />,
          },
          {
            path: "accounts",
            element: <Accounts />,
          },
          {
            path: "account/summary",
            element: <AccountSummary />,
          },
          {
            path: "account/profiles",
            element: <AccountProfiles />,
          },
          {
            path: "account/redirect-builder",
            element: <AccountRedirectBuilder />,
          },
          {
            path: "account/campaigns",
            element: <AccountCampaigns />,
          },
          {
            path: "account/primary-linkedin",
            element: <AccountLinkedIn />,
          },
          {
            path: "account/connections",
            element: <AccountConnections />,
          },
          {
            path: "account/social-channels",
            element: <AccountSocialChannels />,
          },
          {
            path: "account/newsportal",
            element: <AccountWordpress />,
          },
          {
            path: "account/long-form",
            element: <AccountBlogPosts />,
          },
          {
            path: "account/notification-sources",
            element: <AccountNotificationSources />,
          },
          {
            path: "account/websites",
            element: <AccountWebsites />,
          },
          {
            path: "account/avatars",
            element: <AccountAvatars />,
          },
          {
            path: "account/groups",
            element: <AccountGroups />,
          },
          {
            path: "account/pages",
            element: <AccountPages />,
          },
          {
            path: "account/podcast-voices",
            element: <AccountPodcastVoices />,
          },
          {
            path: "account/embedded-messages",
            element: <AccountEmbeddedMessages />,
          },
          {
            path: "account/sitemaps",
            element: <AccountSitemaps />,
          },
          {
            path: "account/content-types",
            element: <AccountContentTypes />,
          },
          {
            path: "account/target-types",
            element: <AccountTargetTypes />,
          },
          {
            path: "account/content-sources",
            element: <AccountContentSources />,
          },
          {
            path: "account/poster-types",
            element: <AccountPosterTypes />,
          },
          {
            path: "account/sitemap-types",
            element: <AccountSitemapTypes />,
          },
          {
            path: "account/manual-news",
            element: <AccountManualNews />,
          },
          {
            path: "account/client-socials",
            element: <AccountClientSocials />,
          },
          // {
          //   path: "account/wordpress-schedules",
          //   element: <AccountWordpressSchedules />,
          // },
          // {
          //   path: "account/avatar-schedules",
          //   element: <AccountAvatarSchedules />,
          // },
          // {
          //   path: "account/group-schedules",
          //   element: <AccountGroupSchedules />,
          // },
          {
            path: "account/posts",
            element: <AccountPosts />,
          },
          {
            path: "account/podcasts",
            element: <AccountPodcasts />,
          },
          {
            path: "account/twitter-apis",
            element: <AccountTwitterAPIs />,
          },
          {
            path: "account/post-statuses",
            element: <AccountPostStatuses />,
          },
          {
            path: "account/hint-subjects",
            element: <AccountHintSubjects />,
          },
          {
            path: "account/question-subjects",
            element: <AccountQuestionSubjects />,
          },
          {
            path: "account/clickfarm",
            element: <AccountClickfarm />,
          },
        ],
      },
      // Add other child routes here
    ],
  },
  { path: "/login", element: <Login /> },
  { path: "/twitter-account-creator", element: <TwitterAccountCreator /> },
];
