import { Button } from "components/ui/button";
import { useToast } from "components/ui/use-toast";
import { Copy } from "lucide-react";

interface TextRendererProps {
  link: string;
  showAll?: boolean;
}

function LinkRenderer({ link, showAll }: TextRendererProps) {
  const { toast } = useToast();
  return (
    <div
      title={link}
      className={`flex items-center space-x-1 max-w-60${
        showAll ? "" : " truncate"
      }`}
    >
      <a
        target="_blank"
        rel="noreferrer"
        href={link}
        className={`hover:underline cursor-pointer ${
          showAll ? "whitespace-normal break-all" : "truncate"
        }`}
        style={{ wordBreak: "break-word" }}
      >
        {link}
      </a>
      <Button
        onClick={() => {
          navigator.clipboard.writeText(link);
          toast({
            description: "Link copied",
          });
        }}
        variant="ghost"
        size="sm"
        className="h-6 w-6 p-0"
      >
        <Copy className="size-3" />
      </Button>
    </div>
  );
}

export default LinkRenderer;
